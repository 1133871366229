import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';

import { OpenInNewOutlined } from '@material-ui/icons';

import { validateDataCy } from '../utils/data-cy';

type Props = {
  className?: string;
  label: string;
  onClick?: () => void;
  to: string;
  size: 'sm' | 'lg';
  'data-cy'?: string;
};

export default function TextLink(props: Props) {
  const classes = {
    sm: 'type-small-link',
    lg: 'type-link',
  };

  const linkProps: ComponentProps<typeof Link> = {
    onClick: props.onClick,
    to: props.to,
  };

  const isExternalLink = props.to.includes('://');
  if (isExternalLink) {
    linkProps.target = '_blank';
    linkProps.rel = 'noreferrer';
  }

  validateDataCy(props['data-cy'], 'text-link');

  return (
    <Link
      className={`inline-flex items-center gap-1 ${classes[props.size]} ${props.className}`}
      {...linkProps}
      data-cy={props['data-cy']}
    >
      {props.label}
      {isExternalLink && <ExternalLinkIcon />}
    </Link>
  );
}

TextLink.defaultProps = {
  size: 'lg',
} as const;

function ExternalLinkIcon() {
  return (
    <div className="flex items-center icon-sm">
      <OpenInNewOutlined fontSize="inherit" />
    </div>
  );
}
