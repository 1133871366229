import { gql, itemLinkFields, scheduleImpactFields } from '../../../api/graphqlFragments';

const categoryLinkFields = gql`
  fragment categoryLinkFields on CategoryLink {
    id
    name
    number
    categorization {
      id
      name
    }
  }
`;

const costTypeFields = gql`
  fragment costTypeFields on Cost {
    ... on CostRange {
      min
      max
    }
    ... on CostScalar {
      value
    }
  }
`;

const meetingLinkFields = gql`
  fragment meetingLinkFields on MeetingLink {
    id
    name
  }
`;

const milestoneLinkFields = gql`
  fragment milestoneLinkFields on MilestoneLink {
    id
    name
  }
`;

export const userLinkFields = gql`
  fragment userLinkFields on UserLink {
    id
    name
    picture
    pictureAsset {
      thumbnailURL
    }
    status
  }
`;

export const userFields = gql`
  fragment userFields on UserLink {
    id
    name
    email
  }
`;

export const userLinkFieldsWithoutPicture = gql`
  fragment userLinkFieldsWithoutPicture on UserLink {
    id
    name
    status
  }
`;

const itemsListItemFields = gql`
  fragment itemsListItemFields on ItemsListItem {
    activityIDs
    assigneeID
    assetCount
    availableStates
    categories {
      ...categoryLinkFields
    }
    commentCount
    createdAt
    creator {
      ...userLinkFieldsWithoutPicture
    }
    dueDate
    cost {
      ...costTypeFields
    }
    currentCost {
      ...costTypeFields
    }
    currentMilestone {
      ...milestoneLinkFields
    }
    currentStatus
    id
    itemType
    meeting {
      ...meetingLinkFields
    }
    milestone {
      ...milestoneLinkFields
    }
    name
    number
    options {
      ...itemLinkFields
    }
    parent {
      ...itemLinkFields
    }
    parentID
    remoteAssetCount
    scheduleImpact {
      ...scheduleImpactFields
    }
    status
    visibility
    updateTime
    hasEstimate
  }
  ${categoryLinkFields}
  ${costTypeFields}
  ${itemLinkFields}
  ${meetingLinkFields}
  ${milestoneLinkFields}
  ${scheduleImpactFields}
  ${userLinkFieldsWithoutPicture}
`;

export const itemsListQuery = gql`
  query itemsList(
    $milestoneID: UUID
    $activityID: UUID
    $projectID: UUID!
    $showOptions: Boolean!
    $integrations: [IntegrationFilterType!]!
    $costMode: CostMode!
    $viewFilter: ViewFilter!
    $visibilityView: VisibilityView!
    $loadItemCosts: Boolean
  ) {
    itemsList(
      parameters: {
        milestoneID: $milestoneID
        activityID: $activityID
        projectID: $projectID
        showOptions: $showOptions
        integrations: $integrations
        costMode: $costMode
        viewFilter: $viewFilter
        visibilityView: $visibilityView
        loadItemCosts: $loadItemCosts
      }
    ) {
      items {
        ...itemsListItemFields
      }
    }
  }
  ${itemsListItemFields}
`;

export const itemsListTotalCostQuery = gql`
  query itemsListTotalCost(
    $itemIDs: [UUID!]!
    $milestoneID: UUID
    $projectID: UUID!
    $costMode: CostMode!
    $viewFilter: ViewFilter!
  ) {
    itemsListTotalCost(
      parameters: {
        itemIDs: $itemIDs
        milestoneID: $milestoneID
        projectID: $projectID
        costMode: $costMode
        viewFilter: $viewFilter
      }
    ) {
      cost {
        ...costTypeFields
      }
    }
  }
  ${costTypeFields}
`;

export const itemCostQuery = gql`
  query itemCost(
    $itemID: UUID!
    $milestoneID: UUID
    $projectID: UUID!
    $costMode: CostMode!
    $viewFilter: ViewFilter!
  ) {
    itemCost(
      parameters: {
        itemID: $itemID
        milestoneID: $milestoneID
        projectID: $projectID
        costMode: $costMode
        viewFilter: $viewFilter
      }
    ) {
      cost {
        ...costTypeFields
      }
    }
  }
  ${costTypeFields}
`;

export const itemCurrentCostQuery = gql`
  query itemCurrentCost(
    $itemID: UUID!
    $milestoneID: UUID
    $projectID: UUID!
    $costMode: CostMode!
    $viewFilter: ViewFilter!
  ) {
    itemCurrentCost(
      parameters: {
        itemID: $itemID
        milestoneID: $milestoneID
        projectID: $projectID
        costMode: $costMode
        viewFilter: $viewFilter
      }
    ) {
      currentCost {
        ...costTypeFields
      }
    }
  }
  ${costTypeFields}
`;

export const milestoneContingenciesQuery = gql`
  query milestoneContingencies($projectID: UUID!, $milestoneID: UUID!) {
    milestoneContingencies(projectID: $projectID, milestoneID: $milestoneID) {
      id
      name
      type
      isOwnerCost
    }
  }
`;

export const projectContingenciesQuery = gql`
  query projectContingencies($projectID: UUID!) {
    projectContingencies(projectID: $projectID) {
      id
      name
      type
      isOwnerCost
    }
  }
`;

export const addsDeductsFields = gql`
  fragment addsDeductsFields on AddDeductCost {
    adds
    deducts
  }
`;

const commonCategorizedItemsTreeNodeCostsFields = gql`
  fragment commonCategorizedItemsTreeNodeCostsFields on ItemsTreeBranchNodeCosts {
    pendingAddsDeductsCost {
      ...addsDeductsFields
    }
    rejectedAddsDeductsCost {
      ...addsDeductsFields
    }
    acceptedCost {
      ...costTypeFields
    }
    targetCost {
      ...costTypeFields
    }
    runningTotalCost {
      ...costTypeFields
    }
  }
  ${addsDeductsFields}
  ${costTypeFields}
`;

const commonItemsTreeOptionFields = gql`
  fragment commonItemsTreeOptionFields on ItemsTreeOption {
    itemLikeID
    cost {
      ...costTypeFields
    }
  }
  ${costTypeFields}
`;

const commonItemsTreeItemsFields = gql`
  fragment commonItemsTreeItemsFields on ItemsTreeItem {
    itemLikeID
    cost {
      ...costTypeFields
    }
    options {
      ...commonItemsTreeOptionFields
    }
  }
  ${commonItemsTreeOptionFields}
  ${costTypeFields}
`;

const commonCategorizedItemsTreeBranchFields = gql`
  fragment commonCategorizedItemsTreeBranchFields on ItemsTreeBranch {
    key
    displayName
    displayNameForPrint
    level
    nodeCosts {
      ...commonCategorizedItemsTreeNodeCostsFields
    }
    totalItems
    items {
      ...commonItemsTreeItemsFields
    }
  }
  ${commonCategorizedItemsTreeNodeCostsFields}
  ${commonItemsTreeItemsFields}
`;

export const itemsTreeQuery = gql`
  query itemsTree(
    $itemsSortBy: ItemsSortBy!
    $itemsFilterBy: ItemsFilterBy!
    $itemsGroupBy: String
    $milestoneID: UUID
    $activityID: UUID
    $projectID: UUID!
    $meetingID: UUID
    $costMode: CostMode!
    $viewFilter: ViewFilter!
    $groupByItems: [GroupByItem!]!
  ) {
    itemsTree(
      parameters: {
        itemsSortBy: $itemsSortBy
        itemsFilterBy: $itemsFilterBy
        itemsGroupBy: $itemsGroupBy
        milestoneID: $milestoneID
        activityID: $activityID
        projectID: $projectID
        meetingID: $meetingID
        costMode: $costMode
        viewFilter: $viewFilter
        groupByItems: $groupByItems
      }
    ) {
      branches {
        ...commonCategorizedItemsTreeBranchFields
        branches {
          ...commonCategorizedItemsTreeBranchFields
          branches {
            ...commonCategorizedItemsTreeBranchFields
            branches {
              ...commonCategorizedItemsTreeBranchFields
              branches {
                ...commonCategorizedItemsTreeBranchFields
                branches {
                  ...commonCategorizedItemsTreeBranchFields
                  branches {
                    ...commonCategorizedItemsTreeBranchFields
                    branches {
                      ...commonCategorizedItemsTreeBranchFields
                      branches {
                        ...commonCategorizedItemsTreeBranchFields
                        branches {
                          ...commonCategorizedItemsTreeBranchFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      items {
        ...commonItemsTreeItemsFields
      }
      level
      orderedItemIDs
      totalItems
    }
  }
  ${commonCategorizedItemsTreeBranchFields}
  ${commonItemsTreeItemsFields}
  ${costTypeFields}
`;
