// EXPORT TO EXCEL HELPER FUNCTIONS

import { ToastType } from '../api/gqlEnums';
import { setToast } from '../hooks/useToastParametersLocalQuery';

export const exportDate = new Date().toLocaleDateString().replace(/\//g, '-');
export const makeFilename = (fileNameTokens: string[]) => {
  const tokens = [...fileNameTokens, `${exportDate}.xlsx`];
  return tokens.join(' - ');
};

export const getDownloadingMessage = (fileNameTokens: string[]) =>
  `Exporting: ${makeFilename(fileNameTokens)}`;

export const getDownloadedMessage = (fileNameTokens: string[]) =>
  `Export complete: ${makeFilename(fileNameTokens)}`;

export const showExportToast = (message: string) => {
  setToast({ message }, ToastType.DEFAULT, null);
};
