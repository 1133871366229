import { ComponentProps, HTMLAttributes, ReactNode, forwardRef, useRef } from 'react';
import { mergeProps, useButton, useFocusRing, useId, useLabel } from 'react-aria';
import { useListState, useOverlayTriggerState } from 'react-stately';

import { ArrowDropDown } from '@material-ui/icons';
import composeRefs from '@seznam/compose-react-refs';

import useStack from '../../../hooks/useStack';
import { StrictUnion, isNonNullable } from '../../../utilities/types';
import Chip from '../Chip/Chip';
import ClearButton from '../ClearButton/ClearButton';
import ClearIconButton from '../ClearIconButton/ClearIconButton';
import useHasClearButton from '../hooks/useHasClearButton';
import Popover from '../Popover/Popover';
import ScrollContainer from '../ScrollContainer';
import Tooltip from '../Tooltip/Tooltip';
import { validateDataCy } from '../utils/data-cy';

import defaultEntryFilter from './defaultEntryFilter';
import defaultEntryRenderer from './defaultEntryRenderer';
import EntryList from './EntryList';
import FilterTextInput from './FilterTextInput';
import useEntries from './hooks/useEntries';
import useSearch from './hooks/useSearch';
import TriggerButton from './TriggerButton';
import { MultiSelection, SelectEntry, SelectEntryID } from './types';

type BaseProps = {
  'aria-label'?: string;
  'data-cy'?: string;
  defaultValue?: MultiSelection;
  endAdornment?: ReactNode;
  entries?: SelectEntry[];
  errorMessage?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  label?: string;
  onHoverEntry?: ComponentProps<typeof EntryList>['onHoverEntry'];
  onSearchChange?: (searchString: string) => void;
  onViewChildren?: ComponentProps<typeof EntryList>['onViewChildren'];
  placeholder?: string;
  startAdornment?: ReactNode;
  value?: MultiSelection;
};

type AllSelectableProps = BaseProps & {
  /** @alpha Select-All is alpha - provide feedback please :-) */
  isAllSelectable: true | boolean;
  onChange?: (value: SelectEntryID[]) => void;
};

type NotAllSelectableProps = BaseProps & {
  isAllSelectable?: false;
  onChange?: (value: SelectEntryID[]) => void;
};

type Props = StrictUnion<AllSelectableProps | NotAllSelectableProps>;

export default forwardRef<HTMLButtonElement, Props>(function MultiSelect(props, forwardedRef) {
  const search = useSearch({ onChange: props.onSearchChange });

  // This is a stack of SelectEntryIDs that holds our current position in a tree of entries. This
  // is used when we have multilevel entries such that an entry has child entries, which
  // have child entries, which have child entries, etc.
  //
  // The most common use of this is multilevel categorizations. In a case like that, a stack may
  // look like ['030000', '030600', '030620'] which would have us showing the entries underneath
  // "Schedules for Concrete Reinforcing" - 030620.13 and 030620.16
  const currentRootStack = useStack<SelectEntryID>();

  // Pull the entries we're going to feed into React Aria.
  const { disabledKeys, entries } = useEntries({ entries: props.entries ?? [] });

  const listStateProps: Parameters<typeof useListState<SelectEntry>>[0] = {
    children: defaultEntryRenderer,
    defaultSelectedKeys: props.defaultValue,
    disabledKeys,
    items: entries,
    onSelectionChange: (keys) => {
      /* We are not using the possible 'all' string value for the keys prop, 
      because our select all functionality selects a subset of entries, not 
      every possible entry. This check in to narrow our keys arg to a string[] type
       */
      if (typeof keys === 'string') {
        return;
      }

      const stringKeys: SelectEntryID[] = [...keys.values()].map((v) => `${v}`);
      props.onChange?.(stringKeys);
    },
    selectedKeys: props.value,
    selectionMode: 'multiple',
  };

  const state = useListState<SelectEntry>(listStateProps);

  const ref = useRef<HTMLButtonElement>(null); // This gets passed into RA
  const composedRef = composeRefs(ref, forwardedRef); // This gets applied.

  const inputId = useId();
  const valueId = useId();
  const errorMessageId = useId();

  const { fieldProps, labelProps } = useLabel({
    'aria-label': props['aria-label'],
    'aria-labelledby': valueId,
    'aria-describedby': errorMessageId,
    label: props.label,
    id: inputId,
  });

  const popoverState = useOverlayTriggerState({
    onOpenChange: () => {
      search.onChange('');
      currentRootStack.clear();
    },
  });

  const { buttonProps } = useButton(
    {
      'aria-expanded': popoverState.isOpen,
      'aria-describedby': props.errorMessage ? errorMessageId : undefined,
      'aria-haspopup': 'listbox',
      id: inputId,
      isDisabled: props.isDisabled,
      onKeyDown: (e) => {
        if (e.key === 'ArrowDown') {
          popoverState.open();
        }
      },
      onPress: popoverState.toggle,
    },
    ref
  );
  const { focusProps, isFocusVisible } = useFocusRing();
  const triggerButtonProps = mergeProps(buttonProps, focusProps, fieldProps);

  const { hasTextClearButton, hasInlineClearButton } = useHasClearButton({
    label: props.label,
    isClearable:
      props.isClearable &&
      (state.selectionManager.isSelectAll || state.selectionManager.selectedKeys.size !== 0),
    isDisabled: props.isDisabled,
  });
  const handleClear = () => {
    state.selectionManager.setSelectedKeys([]);
    ref.current?.focus();
  };

  const selectedItems = [...state.selectionManager.selectedKeys]
    .map((key) => state.collection.getItem(key)?.value)
    .filter(isNonNullable);

  validateDataCy(props['data-cy'], 'multi-select');

  return (
    <div className="relative flex w-full flex-col gap-0.5">
      {props.label && (
        <div className="flex">
          <label {...labelProps} className="mr-auto text-type-primary type-label">
            {props.label}
          </label>
          {hasTextClearButton && <ClearButton onClick={handleClear} />}
        </div>
      )}
      <TriggerButton
        buttonProps={triggerButtonProps}
        data-cy={props['data-cy'] ?? 'multi-select'}
        isDisabled={props.isDisabled || !entries.length}
        isFocusVisible={isFocusVisible}
        isInvalid={Boolean(props.errorMessage)}
        triggerRef={composedRef}
        type="default"
      >
        {props.startAdornment}
        <SelectedValues
          entries={props.entries}
          isDisabled={props.isDisabled}
          placeholder={props.placeholder}
          selectedItems={selectedItems}
          valueProps={{ id: valueId }}
        />
        {hasInlineClearButton && <ClearIconButton onClick={handleClear} />}
        {props.endAdornment}
        <div aria-hidden="true">
          <ArrowDropDown />
        </div>
      </TriggerButton>
      {props.errorMessage && (
        <div className="cursor-default text-type-error type-label" id={errorMessageId}>
          {props.errorMessage}
        </div>
      )}
      {/*
      TODO: A hidden select would give us autofill support, but that doesn't seem so critical rn.
      <HiddenSelect
        state={{ ...state, isFocused }}
        {...fieldProps}
        triggerRef={ref}
        label={props.label}
        name={props.name}
      />
      */}
      {popoverState.isOpen && (
        <Popover className="flex flex-col" isWidthOfTrigger state={popoverState} triggerRef={ref}>
          {props.isSearchable && (
            <FilterTextInput
              onChange={search.onChange}
              onEscape={() => popoverState.setOpen(false)}
              value={search.value}
            />
          )}
          <ScrollContainer direction="vertical">
            <EntryList
              isAllSelectable={props.isAllSelectable}
              isMultiSelect
              menuProps={{
                'aria-labelledby': labelProps.id,
                autoFocus: false, // let the FilterTextInput grab focus
              }}
              onFilterEntry={(entry) =>
                defaultEntryFilter(entry, search.value, currentRootStack.top)
              }
              onHoverEntry={props.onHoverEntry}
              // Don't show the option to see children if we're searching for entries.
              // The UX gets confusing otherwise because its unclear whether we should be
              // search all the entries or just the children.
              onViewChildren={search.value ? undefined : currentRootStack.push}
              onViewParent={currentRootStack.pop}
              // Don't show the parent if we're searching for entries
              parentEntryID={search.value ? undefined : currentRootStack.top}
              state={state}
            />
          </ScrollContainer>
        </Popover>
      )}
    </div>
  );
});

const SelectedValues = (props: {
  isDisabled?: boolean;
  selectedItems: SelectEntry[];
  placeholder?: string;
  valueProps: HTMLAttributes<HTMLDivElement>;
  entries?: SelectEntry[];
}) => {
  const ref = useRef<HTMLDivElement>(null);

  let contents: ReactNode = props.placeholder ?? 'Select an option...';
  const labels = props.selectedItems.length
    ? getLabelsFromSelection(props.selectedItems, props.entries)
    : [];
  if (labels.length) {
    contents = labels.map((label) => (
      <Chip
        key={label}
        backgroundColor={props.isDisabled ? 'bg-background-primary' : undefined}
        text={label}
      />
    ));
  }

  // Measure whether we've already overflowed. This is usable for displaying the tooltip
  // but isn't sufficient for controlling visibility of the fade-out gradient because of the
  // order of renders. When a chip is added that pushes us into overflow, we can't wait for
  // the /next/ render pass before rendering the fade-out - we must render it before React
  // can...react. This is why we use a CSS approach. But for the tooltip, we almost always can
  // rely on waiting until the next render pass. To understand better, log out render passes
  // and see what's happening.
  let isOverflowing = false;
  if (ref.current && ref.current.scrollWidth > ref.current.clientWidth) {
    isOverflowing = true;
  }

  return (
    <Tooltip
      content={
        <ul>
          {labels.map((label) => (
            <li key={label}>•&nbsp;{label}</li>
          ))}
        </ul>
      }
      isDisabled={!isOverflowing}
      placement="bottom"
    >
      <div
        {...props.valueProps}
        ref={ref}
        className={[
          'relative mr-auto flex h-full w-full items-center gap-1 overflow-auto truncate',
          props.selectedItems.length ? 'text-type-primary' : 'text-type-inactive',
        ].join(' ')}
      >
        {contents}
        {/* The following is essentially the ScrollShadow we use in a ScrollContainer. 
            Note how in this version we don't have an animation-range - we simply flip between visible and invisible. */}
        <div
          className="pointer-events-none sticky -right-1 -ml-8 h-full w-8 shrink-0 text-right text-background-primary opacity-0"
          style={{
            animation: 'auto linear to-opaque both',
            // @ts-expect-error CSSProperties hasn't been updated with animation-timeline
            animationTimeline: 'scroll(x)',
            animationDirection: 'reverse',

            /* We're being cute with the definition here because we want the gradient to
            move from the background color to transparent. There's no real mechanism for
            getting the current /background/ color, only the text color, so we set this div's
            text color to the themed background color (in the `className` on this div) so that we
            can use it in this gradient definition. As a result, we're theme/dark-mode aware. */
            backgroundImage:
              'linear-gradient(to left, currentColor 0%, currentColor 25%, transparent 100%)',
          }}
        />
      </div>
    </Tooltip>
  );
};

const getLabelsFromSelection = (
  selectedItems: SelectEntry[],
  entries?: SelectEntry[]
): string[] => {
  const sections = entries?.filter((item) => item.isSection);
  if (!sections?.length) {
    return selectedItems.map((item) => item.label);
  }

  const selectedIds = new Set(selectedItems.map((item) => item.id));

  // If all entries in all sections are selected and there's more than one section, show "All"
  if (
    sections.length > 1 &&
    sections.every((section) => section.entries?.every((entry) => selectedIds.has(entry.id)))
  ) {
    return ['All'];
  }

  return sections
    .flatMap((section) => {
      const entries = section.entries || [];
      // Show section label if all entries are selected, otherwise show individual selected entries
      return entries.every((entry) => selectedIds.has(entry.id))
        ? [section.label]
        : entries.filter((entry) => selectedIds.has(entry.id)).map((entry) => entry.label);
    })
    .concat(
      // Add any selected items that aren't in sections
      selectedItems
        .filter(
          (item) =>
            !sections.some((section) => section.entries?.some((entry) => entry.id === item.id))
        )
        .map((item) => item.label)
    );
};
