/* eslint-disable max-classes-per-file */
import { CostReportColumnKey, CostReportColumnType, Status } from '../../../../generated/graphql';
import { UserReportComment } from '../../../ReportsTab/ReportHooks';
import { getUserReportCommentLineID, itemCostToSegmented } from '../../CostReportUtils';

export type Subtotal = {
  categories?: Category[];
  directCostRange: Cost;
  directCostSegmented?: PartialAddDeduct;
  id?: UUID;
  numeric?: Numeric;
  range: Cost;
  segmented?: PartialAddDeduct;
  status?: Status;
};

export type CostReportColumnData = {
  subtotal: Subtotal;
  columnKey: CostReportColumnKey;
  type: CostReportColumnType;
  unitID?: UUID;
  milestoneID?: UUID;
  date?: string;
};

export type NodeData = {
  id?: string;
  name?: string;
  nodeNumber?: number;
  number?: string;
  status?: Status;
  parentId?: string;
  columns: CostReportColumnData[];
  categorization?: Categorization;
  isIncorporated?: boolean;
  categoryIDs?: UUID[];
  isItemEstimateLine?: boolean;
  commentWithCosts?: Pick<UserReportComment, 'comment' | 'costs'>;
};

export const matchNodeCategories =
  (newNodeData: Partial<NodeData>) => (nodeData: Partial<NodeData>) =>
    !!nodeData.categorization &&
    !!newNodeData.categorization &&
    nodeData.categorization.id === newNodeData.categorization.id &&
    nodeData.number === newNodeData.number;

// We skip solo and uncategorized branches because they
// duplicate their parent's values in display. We continue to
// display the same categories at various levels if it has peers.
export const getNextBranches = (
  treeNode: CostReportCategoriesTreeNode
): CostReportCategoriesTreeNode[] => {
  const { branches, data } = treeNode;
  if (data && branches && branches.length > 0) {
    if (branches.length === 1 && matchNodeCategories(data)(branches[0].data)) {
      return getNextBranches(branches[0]);
    }
    return branches.map((branch) => {
      if (matchNodeCategories(data)(branch.data)) {
        branch.setDisplayUncategorized(true);
      }
      return branch;
    });
  }
  return branches;
};

export class CostReportCategoriesTreeNode {
  branches: CostReportCategoriesTreeNode[];

  data: NodeData;

  displayUncategorized?: boolean;

  itemLike?: ItemLike;

  parentItem?: ItemLike;

  childCommentIDs?: Set<UUID>;

  isMarkup: boolean;

  addChildComments = (id?: string, itemContributionLineCommentIDs?: string[]) => {
    // get the commentID of the current node, because
    // we don't need to count the current node
    const currentNodeCommentID = getUserReportCommentLineID(
      this.data.categoryIDs?.map((id) => {
        return { id };
      }) || []
    );

    // default to an empty set if currentNode doesn't have one yet
    if (!!id || !!itemContributionLineCommentIDs) {
      this.childCommentIDs = this.childCommentIDs ?? new Set();
      if (id && id !== currentNodeCommentID) this.childCommentIDs.add(id);
      if (itemContributionLineCommentIDs)
        itemContributionLineCommentIDs.forEach((cID) => this.childCommentIDs?.add(cID));
    }
  };

  getCommentCount() {
    return this.childCommentIDs?.size;
  }

  constructor(data: NodeData, itemLike?: ItemLike, parentItem?: ItemLike, isMarkup = false) {
    this.branches = [];
    this.data = data;
    this.displayUncategorized = false;
    this.itemLike = itemLike; // this helps us with the data type expected in Row
    this.parentItem = parentItem; // this helps us with the data type expected in Row
    this.isMarkup = isMarkup;
  }

  setNumber(count: number) {
    this.data.nodeNumber = count;
  }

  setIsMarkup(value: boolean) {
    this.isMarkup = value;
  }

  setDisplayUncategorized(display: boolean) {
    this.displayUncategorized = display;
  }

  setDataValue(
    columnInput: CostReportColumnInput,
    columnKey: CostReportColumnKey,
    subtotal: Subtotal
  ) {
    const { columns } = this.data;
    columns.push({ ...columnInput, columnKey, subtotal });
  }

  addBranch(node: CostReportCategoriesTreeNode) {
    if (node && this.branches) {
      this.branches.push(node);
    }
  }
}

type CostBreakdownItem = {
  categories: CategoryWithoutLevels[];
  columnKey: CostReportColumnKey;
  range: Cost;
  segmented?: AddDeductCost;
  directCostRange: Cost;
  directCostSegmented?: AddDeductCost;
  numeric?: Numeric;
  id?: UUID;
  itemContributionLines?: MilestoneCostReportColumnReport['categorizedItemCosts'][number]['itemContributionLines'];
  itemLike?: ItemLike;
  name?: string;
  number?: string;
  parentId?: UUID;
  status?: Status;
};

export const addCounts = (node: CostReportCategoriesTreeNode, startCount = 0) => {
  let count = startCount;
  if (node && node.setNumber) {
    node.setNumber(count);
    // to ensure all nodes get a unique number categories will be positive, and markups will be negative
    // that way markups don't need to know how many category lines there are
    if (!node.isMarkup) {
      count += 1;
    } else {
      count -= 1;
    }

    const nextBranches = getNextBranches(node);
    nextBranches.forEach((branch: CostReportCategoriesTreeNode) => {
      count = addCounts(branch, count);
    });
  }
  return count;
};

export const addIsMarkup = (node: CostReportCategoriesTreeNode, isMarkup = false) => {
  if (node && node.setIsMarkup) {
    node.setIsMarkup(isMarkup);

    const nextBranches = getNextBranches(node);
    nextBranches.forEach((branch: CostReportCategoriesTreeNode) => {
      addIsMarkup(branch, isMarkup);
    });
  }
};

export default class CostReportCategoriesTree {
  nodeCount: number;

  nodeMatchComparator: (newNodeData: Partial<NodeData>) => (nodeData: Partial<NodeData>) => boolean;

  root: CostReportCategoriesTreeNode;

  constructor(nodeData?: NodeData, nodeMatchComparator = matchNodeCategories) {
    this.root = new CostReportCategoriesTreeNode(nodeData || { columns: [], categoryIDs: [] });
    this.nodeMatchComparator = nodeMatchComparator;
    this.nodeCount = 0;
  }

  removeChildren() {
    this.root.branches = [];
  }

  insert(
    costBreakdownItem: CostBreakdownItem,
    columnInput: CostReportColumnInput,
    showLines?: boolean,
    userReportComment?: UserReportComment,
    itemContributionLineUserReportComments?: UserReportComment[]
  ) {
    const {
      categories,
      columnKey,
      directCostRange,
      directCostSegmented,
      range,
      segmented,
      numeric,
      id,
      name,
      number,
      parentId,
      itemLike,
      status,
      itemContributionLines,
    } = costBreakdownItem;
    let currentNode = this.root;
    let nextNode: CostReportCategoriesTreeNode | undefined;
    const categoryIDs = categories.map((c) => c.id);
    const comment = userReportComment?.comment;
    const commentWithCosts =
      userReportComment && comment
        ? {
            comment,
            costs: userReportComment.costs,
          }
        : undefined;
    const hasComment = !!commentWithCosts;
    const itemContributionLineCommentIDs = itemContributionLineUserReportComments?.map(
      (c) => c.commentLineID
    );

    // In the code below, we are potentially inserting a comment into the report tree
    // alongside the costBreakdownItem. Any time we set currentNode = nextNode, we're
    // descending another level down into the report tree. When this happens, we want
    // to add to the childCommentIDs of currentNode first, so we can eventually display
    // a count of how many comments appear below that node. When we call setDataValue, we
    // have found the right node to insert our cost into, so we also want to attach the
    // comment to that node with nextNode.data.comment = comment

    // travel down the tree one level at a time, creating a new node if we don't have
    // a branch for the category at that level
    for (let i = 0; i < categories.length; i += 1) {
      // categorization can't be null in CostReportCategoriesTreeNode data, so default to undefined instead
      const categorization = categories[i].categorization ?? undefined;
      nextNode = currentNode.branches.find(({ data }) => {
        return this.nodeMatchComparator({
          ...categories[i],
          categorization,
        })(data);
      });
      if (!nextNode) {
        const nodeCategories = categories.slice(0, i + 1);
        nextNode = new CostReportCategoriesTreeNode({
          ...categories[i],
          categorization,
          columns: [],
          commentWithCosts: itemContributionLineUserReportComments?.find(
            (c) => c.commentLineID === getUserReportCommentLineID(nodeCategories)
          ),

          // the input comment only applies to the lowest level category
          // in the categories array
          // get the IDs for every category before (and including) this one
          categoryIDs: nodeCategories.map((c) => c.id),
        });
        currentNode.addBranch(nextNode);
        nextNode.addChildComments(userReportComment?.commentLineID, itemContributionLineCommentIDs);
        this.nodeCount += 1;
      }
      currentNode.addChildComments(
        userReportComment?.commentLineID,
        itemContributionLineCommentIDs
      );
      currentNode = nextNode;
    }

    // now we're at the bottom of the category tree
    // this logic determines which type of node we're adding below:
    if (!itemLike && id && status && name && number) {
      // "twigs" for IWO, "leaf" for item/option contribution
      if (parentId) {
        nextNode = currentNode.branches.find((b) => b.data.id === parentId);
        if (!nextNode) {
          nextNode = new CostReportCategoriesTreeNode({
            id: parentId,
            name,
            number,
            status,
            columns: [],
            commentWithCosts,
            categoryIDs,
          });
          currentNode.addBranch(nextNode);
          this.nodeCount += 1;
        }
        currentNode.addChildComments(
          userReportComment?.commentLineID,
          itemContributionLineCommentIDs
        );
        currentNode = nextNode;
      }

      nextNode = currentNode.branches.find((b) => b.data.id === id);
      // for item or option...
      if (!nextNode) {
        nextNode = new CostReportCategoriesTreeNode({
          id,
          columns: [],
          name,
          number,
          status,
          parentId,
          categoryIDs,
        }); // if we have the parent, use it for status
        currentNode.addChildComments(
          userReportComment?.commentLineID,
          itemContributionLineCommentIDs
        );
        currentNode.addBranch(nextNode);
        this.nodeCount += 1;
      }
      if (nextNode) {
        if (hasComment) nextNode.data.commentWithCosts = commentWithCosts;
        nextNode.setDataValue(columnInput, columnKey, {
          range,
          segmented: itemCostToSegmented(range),
          directCostRange,
          directCostSegmented: itemCostToSegmented(directCostRange),
          numeric,
          status,
        });
      }

      if (itemContributionLines && showLines && id && status) {
        itemContributionLines.forEach((line, i) => {
          let itemContributionNode: CostReportCategoriesTreeNode | undefined;
          const { range: lineRange, directCostRange: directCostLineRange, categories } = line || {};
          const lineID = line?.lineID || '';
          if (nextNode) {
            // we've already added the comment for this item line itself, so just add the
            // item line comments beneath it now if necessary
            nextNode.addChildComments(undefined, itemContributionLineCommentIDs);
            const isLineAdded = nextNode.branches.find((b) => b.data.id === lineID);
            if (!isLineAdded) {
              // to find any report comments that may be associated with this
              // item estimate line we need to get the commentLineID
              // which is a string concatentation of all category ids plus the estimate line ID
              const commentLineID = getUserReportCommentLineID(categories ?? [], id, lineID);
              const itemContributionComment =
                itemContributionLineUserReportComments?.find((c) =>
                  commentLineID.startsWith(c.commentLineID)
                ) || undefined;

              itemContributionNode = new CostReportCategoriesTreeNode({
                id: lineID,
                columns: [],
                ...line,
                commentWithCosts: itemContributionComment,
                categoryIDs,
                isItemEstimateLine: true,
                parentId: id,
              });
              nextNode.addBranch(itemContributionNode);
              this.nodeCount += 1;
            } else {
              itemContributionNode = nextNode && nextNode.branches[i];
            }
            if (itemContributionNode)
              itemContributionNode.setDataValue(columnInput, columnKey, {
                range: lineRange,
                directCostRange: directCostLineRange,
                numeric,
              });
          }
        });
      }
    } else if (itemLike) {
      // "twigs" for IWO, "leaf" for item/option contribution
      if ('parent' in itemLike) {
        // we know this is an Option
        const { parent } = itemLike;
        nextNode = currentNode.branches.find((b) => b.data.id === parent);
        if (!nextNode) {
          nextNode = new CostReportCategoriesTreeNode({
            id: parent,
            columns: [],
            commentWithCosts,
            categoryIDs,
          });
          currentNode.addBranch(nextNode);
          this.nodeCount += 1;
        }
        currentNode.addChildComments(undefined, itemContributionLineCommentIDs);
        currentNode = nextNode;
      }
      nextNode = currentNode.branches.find((b) => b.data.id === id);
      // for item or option...
      if (!nextNode) {
        const parentItem = currentNode.itemLike;
        nextNode = new CostReportCategoriesTreeNode(
          { id, columns: [], categoryIDs, commentWithCosts },
          itemLike,
          parentItem
        ); // if we have the parent, use it for status
        currentNode.addBranch(nextNode);
        this.nodeCount += 1;
      }
      if (nextNode) {
        if (hasComment) nextNode.data.commentWithCosts = commentWithCosts;
        nextNode.setDataValue(columnInput, columnKey, {
          range,
          segmented: itemCostToSegmented(range),
          directCostRange,
          directCostSegmented: itemCostToSegmented(directCostRange),
          status,
          numeric,
        });
      }
    } else {
      if (hasComment) currentNode.data.commentWithCosts = commentWithCosts;
      currentNode.setDataValue(columnInput, columnKey, {
        range,
        segmented,
        directCostSegmented,
        directCostRange,
        numeric,
      });
    }
  }
}
