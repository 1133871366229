import { toastParametersVar } from '../api/apollo/reactiveVars';
import { ToastType } from '../api/gqlEnums';

export const toastServerError = () => {
  toastParametersVar({
    item: null,
    message:
      'Something went wrong!  Try again, and if this continues, please contact support@join.build',
    show: true,
    type: ToastType.SERVER_ERROR,
  });
};

export const toastScenarioApplied = (scenarioName: string, milestoneName: string) => {
  toastParametersVar({
    message: `${scenarioName} was applied to ${milestoneName}`,
    show: true,
    type: ToastType.SUCCESS,
  });
};
