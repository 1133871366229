import { useRef } from 'react';
import { useButton } from 'react-aria';

import Icon from '../Icon/Icon';

type Props = {
  'data-cy'?: string;
  backgroundColor?: string;
  onPress?: () => void;
};

const CloseButton = (props: Props) => {
  const closeRef = useRef<HTMLButtonElement>(null);
  const { buttonProps: closeButtonProps } = useButton({ onPress: props.onPress }, closeRef);

  return (
    <button
      className="self-normal flex h-4 w-4 cursor-pointer items-center rounded-full text-base focus-visible:outline"
      data-cy={props['data-cy']}
      {...closeButtonProps}
    >
      <Icon name="close" size="sm" />
    </button>
  );
};

export default CloseButton;
