import { gql } from '../../../../api/graphqlFragments';

export const createActivityMutation = gql`
  mutation CreateActivity($parentID: UUID, $parentIndex: Int) {
    createActivity(parentID: $parentID, parentIndex: $parentIndex)
  }
`;

export const createDependencyMutation = gql`
  mutation CreateDependency($fromID: UUID!, $toID: UUID!) {
    createDependency(fromID: $fromID, toID: $toID)
  }
`;

export const loadTimelineQuery = gql`
  query LoadTimeline {
    loadTimeline {
      startDate
      endDate
      activities {
        id
        name
        startDate
        endDate
        duration
        constraintDate
        parentID
      }
      dependencies {
        id
        fromID
        toID
      }
    }
  }
`;

export const deleteActivityMutation = gql`
  mutation DeleteActivity($activityID: UUID!) {
    deleteActivity(activityID: $activityID)
  }
`;

export const deleteDependencyMutation = gql`
  mutation DeleteDependency($dependencyID: UUID!) {
    deleteDependency(dependencyID: $dependencyID)
  }
`;

export const updateActivitiesMutation = gql`
  mutation UpdateActivities($inputs: [UpdateTimelineActivityInput!]!) {
    updateActivities(inputs: $inputs)
  }
`;
