import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    border: {
      border: theme.border.line,
      borderBottom: 'none', // the last row of cells already has a bottom border
    },
    centered: {
      justifyContent: 'center',
    },
    rightAligned: {
      justifyContent: 'right',
      textAlign: 'right',
    },
    disabled: {
      color: theme.palette.disabledGrey,
    },
    iconSortArrow: {
      fill: theme.palette.disabledGrey,
      margin: '0px 6px',
      position: 'relative',
      top: -2,
      width: 16,
    },
    iconSortArrowActive: {
      fill: theme.palette.primaryBlue,
    },
    iconSortArrowFlipped: {
      transform: 'rotate(180deg)',
    },
    isNumber: {
      ...theme.typography.number,
      fontSize: theme.typography.inputs.fontSize,
    },
    listElementContent: {
      display: 'flex',
      width: '100%',
      maxHeight: '100%',
    },
    loading: {
      gridColumn: '1 / -1',
      height: 3,
      position: 'sticky',
      top: 44,

      zIndex: 1,
    },
    rootWithHeader: { gridTemplateRows: 'auto 0' },
    text: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
  });
