import { useMemo } from 'react';

import { makeVar, useReactiveVar } from '@apollo/client';

import {
  getReactiveLocal,
  setReactiveLocal,
  usePreviewSettingsVar,
} from '../../api/apollo/reactiveVars';
import useMemoWrapper from '../../components/useMemoWrapper';
import { NS_OWNER_COSTS_V2 } from '../../features';
import { useHasFeature } from '../../hooks/useHasFeature';
import { UsePermissions } from '../permissions/types';
import usePermissions from '../permissions/usePermissions';
import { useProjectID } from '../routes/params';
import { getProjectIdFromUrl } from '../url';

import {
  DefaultCostMode,
  ProjectsCostModes,
  getAvailableCostModes,
  getCostModePermissions,
} from './costModeLogic';

export const useAvailableCostModes = (projectID?: UUID) => {
  const { canView, loading } = usePermissions({ projectID });
  return useMemoWrapper(getAvailableCostModes, canView, loading);
};

const LOCAL_STORAGE_COST_MODES = 'PROJECTS_COST_MODES';

const fetchLocalStorageCostModes = () =>
  getReactiveLocal<ProjectsCostModes>(LOCAL_STORAGE_COST_MODES, {});

const projectsCostModesVar = makeVar<ProjectsCostModes>(fetchLocalStorageCostModes());

const setNewProjectCostMode = (
  projectID: UUID,
  costMode: CostMode,
  projectsCostModes: ProjectsCostModes
) => {
  const newProjectsCostModes = {
    ...projectsCostModes,
    [projectID]: costMode,
  };
  setReactiveLocal(projectsCostModesVar, LOCAL_STORAGE_COST_MODES, newProjectsCostModes);
};

/** @deprecated Use useCostMode() instead */
export const getCostMode = () => projectsCostModesVar()[getProjectIdFromUrl()] || DefaultCostMode;

export const useCostMode = (customProjectID?: UUID) => {
  const currentProjectID = useProjectID();
  const projectID = customProjectID || currentProjectID;
  const permissions = usePermissions({ projectID });
  return useCostModePermissions(permissions, customProjectID);
};

export const useCostModePermissions = (permissions: UsePermissions, customProjectID?: UUID) => {
  const currentProjectID = useProjectID();
  const projectID = customProjectID || currentProjectID;
  const projectsCostModes = useReactiveVar(projectsCostModesVar);
  const hasOwnerCostFeature = useHasFeature(NS_OWNER_COSTS_V2);
  const preview = usePreviewSettingsVar();

  const { markupMode, costDisplay, includeOwnerCosts } = getCostModePermissions(
    permissions,
    projectsCostModes,
    projectID,
    hasOwnerCostFeature
  );

  // Update LocalStorage if current value is different that what is stored there (and not in preview mode)
  const inPreviewMode = Boolean(preview.userID || preview.roleID);
  if (
    !permissions.loading &&
    !inPreviewMode &&
    projectID &&
    (projectsCostModes[projectID]?.markupMode !== markupMode ||
      projectsCostModes[projectID]?.costDisplay !== costDisplay)
  ) {
    const costMode = { costDisplay, markupMode, includeOwnerCosts };
    setNewProjectCostMode(projectID, costMode, projectsCostModes);
  }

  return useMemo(
    () => ({ markupMode, costDisplay, includeOwnerCosts }),
    [costDisplay, markupMode, includeOwnerCosts]
  );
};

export const updateCostMode = (projectID: UUID, update: Partial<CostMode>) => {
  const projectsCostModes = projectsCostModesVar();
  const prev = projectsCostModes[projectID] ?? DefaultCostMode;
  setNewProjectCostMode(projectID, { ...prev, ...update }, projectsCostModes);
};
