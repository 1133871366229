import { generatePath } from 'react-router-dom';

import paths, { RouteKeys } from '../../routes/paths';

type SharedPathParams = {
  assetID?: string;
  estimateId?: string;
  ideaID?: string;
  itemId?: string;
  milestoneId?: string;
  organizationId?: string;
  programID?: string;
  projectId?: string;
  reportID?: string;
  riskID?: string;
  roleId?: string;
  search?: string;
  setId?: string;
  userId?: string;
};

export const generateSharedPath = (routeKey: RouteKeys, params?: SharedPathParams) => {
  let path: string | undefined;

  if (routeKey) {
    path = paths[routeKey];
    if (path) {
      path = generatePath(path, params);
    }
  }

  if (!path) throw new Error(`Path for "${routeKey}" does not exist.`);
  return path + (params?.search ?? '');
};
