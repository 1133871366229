import { TermKey } from '../../../api/gqlEnums';
import { TOTAL } from '../../../constants';
import { CostReportColumnKey, CostReportColumnType, Status } from '../../../generated/graphql';
import { getCurrencySymbol } from '../../../utilities/currency';
import { CostReportColumnData, Subtotal } from '../CostReportList/CostReportCategoriesTree';
import {
  getBaseUnitText,
  getColumnKey,
  getMetricColumnKey,
  getMilestoneColumnKey,
  getNonCategorizedUnitText,
  getNonUnitColumnKey,
  getUnitText,
  isMetricKey,
  isTotalMetricKey,
  keyIsCategorized,
} from '../CostReportList/CostReportList/CostReportListUtils';
import {
  RowVariants,
  RowVariantsString,
} from '../CostReportList/CostReportListRow/CostReportListRowUtils';
import { getCostValue } from '../CostReportUtils';

export type Cell = {
  columnDescription: ColumnDescription | VarianceColumnDescription; // TODO: type of the columns keys...
  cost?: CostReportValue;
  hasLedger?: boolean;
  isEmpty?: boolean;
  isExact?: boolean;
  isProjectColumn?: boolean;
  isWide?: boolean;
  status?: Status;
  isEstimateLine?: boolean;
  isShaded?: boolean;
};

export const columnsT = (t: TermStore, includeOwnerCosts: boolean) => {
  if (!t) return new Map<CostReportColumnKey, string>();
  return new Map([
    [CostReportColumnKey.ESTIMATE_KEY, t.rawTerm(TermKey.ESTIMATE)],
    [CostReportColumnKey.TARGET_KEY, t.rawTerm(TermKey.TARGET)],
    [CostReportColumnKey.DELTA_KEY, t.rawTerm(TermKey.DELTA)],
    [
      CostReportColumnKey.RUNNINGTOTAL_KEY,
      includeOwnerCosts
        ? t.titleCase(TermKey.PROJECT_RUNNING_TOTAL)
        : t.titleCase(TermKey.RUNNING_TOTAL),
    ],
    [CostReportColumnKey.REMAINING_KEY, t.rawTerm(TermKey.GAP)],
    [CostReportColumnKey.PENDING_KEY, 'Pending'],
    [CostReportColumnKey.PENDINGDEDUCTS_KEY, 'Pending Deducts'],
    [CostReportColumnKey.PENDINGADDS_KEY, 'Pending Adds'],
    [CostReportColumnKey.ACCEPTED_KEY, 'Accepted'],
    [CostReportColumnKey.ACCEPTEDDEDUCTS_KEY, 'Accepted Deducts'],
    [CostReportColumnKey.ACCEPTEDADDS_KEY, 'Accepted Adds'],
    [CostReportColumnKey.REJECTED_KEY, 'Rejected'],
    [CostReportColumnKey.REJECTEDDEDUCTS_KEY, 'Rejected Deducts'],
    [CostReportColumnKey.REJECTEDADDS_KEY, 'Rejected Adds'],
    [CostReportColumnKey.INCORPORATED_KEY, 'Incorporated'],
    [CostReportColumnKey.METRIC_KEY, 'Total Metric'],
    [CostReportColumnKey.CATEGORIZEDMETRIC_KEY, 'Categorized Metric'],
  ]);
};

export const getColumnHeaderFromColumnKey = (columnKey: CostReportColumnKey) => {
  const { nonUnitColumnKey, unitAbbrev } = getNonUnitColumnKey(columnKey);
  if (unitAbbrev === '') return TOTAL;
  return isMetricKey(nonUnitColumnKey) ? unitAbbrev : `${getCurrencySymbol()}/${unitAbbrev}`;
};

export const getColumnTermFromColumnKey = (
  t: TermStore,
  includeOwnerCosts: boolean,
  columnKey?: CostReportColumnKey
) => {
  if (!columnKey) return undefined;
  const columns = columnsT(t, includeOwnerCosts);
  const column = columns && columns.get(columnKey);
  if (column) return column;

  // if not found in the map, we have a unit column
  const { nonUnitColumnKey, unitAbbrev } = getNonUnitColumnKey(columnKey);
  const columnTerm = columns.get(nonUnitColumnKey);
  return columnTerm ? getUnitColumnTerm(columnTerm, unitAbbrev) : undefined;
};

// We will use these CostReportColumnTypes to get our needed reports in CostReport
export const addReports = [
  CostReportColumnType.INCORPORATED_REPORT,
  CostReportColumnType.OPTIONS_REPORT,
  CostReportColumnType.PENDING_REPORT,
  CostReportColumnType.REJECTED_REPORT,
  CostReportColumnType.NOTAPPLICABLE_REPORT,
];

export const projectReports = [
  CostReportColumnType.ACCEPTED_REPORT,
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.REMAINING_REPORT,
  CostReportColumnType.RUNNINGTOTAL_REPORT,
  CostReportColumnType.TARGET_REPORT,
  CostReportColumnType.DELTA_REPORT,
];

export const defaultColumnKeys: CostReportColumnKey[] = [
  CostReportColumnKey.ESTIMATE_KEY,
  CostReportColumnKey.TARGET_KEY,
  CostReportColumnKey.DELTA_KEY,
  CostReportColumnKey.ACCEPTED_KEY,
  CostReportColumnKey.PENDINGADDS_KEY,
  CostReportColumnKey.PENDINGDEDUCTS_KEY,
];

export const acceptedColumnKeys: CostReportColumnKey[] = [
  CostReportColumnKey.ACCEPTED_KEY,
  CostReportColumnKey.ACCEPTEDADDS_KEY,
  CostReportColumnKey.ACCEPTEDDEDUCTS_KEY,
];

const isAcceptedKey = (columnKey: CostReportColumnKey) => acceptedColumnKeys.includes(columnKey);

const isAcceptedRowVariant = (variant: RowVariantsString) => variant === RowVariants.ACCEPTED;

export const matchKeys = (
  columnKey: CostReportColumnKey,
  foundColumnKey: CostReportColumnKey,
  variant: RowVariantsString
) => {
  if (isAcceptedKey(columnKey) && isAcceptedRowVariant(variant))
    return foundColumnKey === CostReportColumnKey.ACCEPTED_KEY;
  return foundColumnKey === columnKey;
};

export const matchDates = (longTime?: Time, shortDate?: Time) =>
  (!shortDate && !longTime) || (!!longTime && !!shortDate && longTime.includes(shortDate));

export const columnMatches = (
  column: CostReportColumnData | VarianceColumnDescription,
  columnKey: CostReportColumnKey,
  unitID?: string,
  milestoneID?: string,
  date?: Time,
  variant?: RowVariantsString
) => {
  if (!column) return false;
  const keyMatches = column.columnKey && matchKeys(columnKey, column.columnKey, variant);
  const unitMatches = !unitID || column.unitID === unitID;
  const milestoneMatches =
    (!milestoneID && !column.milestoneID) || column.milestoneID === milestoneID;
  const dateMatches = matchDates(column.date, date);
  return keyMatches && unitMatches && milestoneMatches && dateMatches;
};

export const getColumnSubtotal = (
  columns: CostReportColumnData[],
  columnKey: CostReportColumnKey,
  milestoneID?: string,
  date?: Time,
  variant?: RowVariantsString
) => {
  const column = columns.find((col: CostReportColumnData) =>
    columnMatches(col, columnKey, undefined, milestoneID, date, variant)
  );
  return column ? column.subtotal : undefined;
};

const getCell: (
  columnDescription: ColumnDescription | VarianceColumnDescription,
  columns: CostReportColumnData[],
  columnKeyQuantityMap: Map<string, Numeric>,
  useDirectCost: boolean,
  variant: RowVariantsString,
  isShaded: boolean,
  isEstimateLine?: boolean
) => Cell | undefined = (
  columnDescription,
  columns,
  columnKeyQuantityMap,
  useDirectCost,
  variant,
  isShaded,
  isEstimateLine
) => {
  if (columnDescription) {
    const { columnKey, unitID, isWide } = columnDescription;
    const { milestoneID, date } = columnDescription as VarianceColumnDescription;
    // We find the matching definition where the column is in the columnKey or columnKey_ABBREVIATION
    const hasUnit = !!unitID;
    const subtotal = getColumnSubtotal(
      columns,
      adjustColumnKeyForEstimateSubtotal(columnKey, variant),
      milestoneID,
      date,
      variant
    );

    // find the report, based on columnKey + quantity
    let cost = isReportSubtotal(variant)
      ? getFnFromColumnKey(columnKey)(subtotal, useDirectCost)
      : computeCost(subtotal, useDirectCost);

    if (columnKey && isTotalMetricKey(columnKey)) {
      cost =
        columnKeyQuantityMap &&
        columnKeyQuantityMap.get(getMilestoneColumnKey(columnKey, milestoneID));
    }

    const status = subtotal ? subtotal.status : undefined;
    const isProjectColumn = columnKey && getIsProjectColumn(columnKey);

    return {
      columnDescription,
      cost: cost ?? undefined,
      isExact: hasUnit,
      isWide,
      isProjectColumn,
      status,
      isShaded,
      isEstimateLine,
    };
  }
  return undefined;
};

const adjustColumnKeyForEstimateSubtotal = (
  columnKey: CostReportColumnKey | undefined,
  variant: RowVariantsString
) => {
  if (columnKey && columnKey !== CostReportColumnKey.ESTIMATE_KEY) return columnKey;
  if (variant === RowVariants.ACCEPTED) return CostReportColumnKey.ACCEPTED_KEY;
  if (variant === RowVariants.RUNNINGTOTAL) return CostReportColumnKey.RUNNINGTOTAL_KEY;
  return CostReportColumnKey.ESTIMATE_KEY;
};

const isReportSubtotal = (variant: RowVariantsString) => {
  return [
    RowVariants.ACCEPTED,
    RowVariants.RUNNINGTOTAL,
    RowVariants.SUBTOTAL,
    RowVariants.MARKUP,
  ].includes(variant as RowVariants);
};

export const getIsProjectColumn = (columnKey: CostReportColumnKey) => {
  const { nonUnitColumnKey } = getNonUnitColumnKey(columnKey);
  return [
    CostReportColumnKey.ESTIMATE_KEY,
    CostReportColumnKey.TARGET_KEY,
    CostReportColumnKey.DELTA_KEY,
    CostReportColumnKey.REMAINING_KEY,
    CostReportColumnKey.RUNNINGTOTAL_KEY,
  ].includes(nonUnitColumnKey);
};

// TODO - follow up with cost report api clean up - move this logic to be
export const getFnFromColumnKey = (columnKey?: CostReportColumnKey) => {
  if (!columnKey) return rangeFn;
  const { nonUnitColumnKey } = getNonUnitColumnKey(columnKey);

  switch (nonUnitColumnKey) {
    case CostReportColumnKey.ESTIMATE_KEY:
    case CostReportColumnKey.TARGET_KEY:
    case CostReportColumnKey.DELTA_KEY:
    case CostReportColumnKey.REMAINING_KEY:
    case CostReportColumnKey.RUNNINGTOTAL_KEY:
    case CostReportColumnKey.ACCEPTED_KEY:
    case CostReportColumnKey.INCORPORATED_KEY:
    case CostReportColumnKey.VARIANCE_KEY:
      return rangeFn;
    case CostReportColumnKey.PENDING_KEY:
    case CostReportColumnKey.REJECTED_KEY:
      return segmentedFn;
    case CostReportColumnKey.ACCEPTEDADDS_KEY:
    case CostReportColumnKey.PENDINGADDS_KEY:
    case CostReportColumnKey.REJECTEDADDS_KEY:
      return computeAddsFn;
    case CostReportColumnKey.ACCEPTEDDEDUCTS_KEY:
    case CostReportColumnKey.PENDINGDEDUCTS_KEY:
    case CostReportColumnKey.REJECTEDDEDUCTS_KEY:
      return computeDeductsFn;
    case CostReportColumnKey.METRIC_KEY:
    case CostReportColumnKey.CATEGORIZEDMETRIC_KEY:
    case CostReportColumnKey.VARIANCEMETRIC_KEY:
      return numericFn;
    default:
      return rangeFn;
  }
};

const computeDeductsFn = (
  s: Subtotal | undefined,
  useDirectCost: boolean
): PartialAddDeduct | undefined => s && { ...segmentedFn(s, useDirectCost), adds: undefined };
const computeAddsFn = (
  s: Subtotal | undefined,
  useDirectCost: boolean
): PartialAddDeduct | undefined => s && { ...segmentedFn(s, useDirectCost), deducts: undefined };

const segmentedFn = (s: Subtotal | undefined, useDirectCost: boolean) =>
  s && (useDirectCost ? s.directCostSegmented : s.segmented);

const rangeFn = (s: Subtotal | undefined, useDirectCost: boolean) =>
  s && (useDirectCost ? s.directCostRange : s.range);

const numericFn = (s: Subtotal | undefined) => s && s.numeric;

const computeCost = (subtotal?: Subtotal, useDirectCost = false) => {
  if (!subtotal) return null;

  const { directCostRange, range, directCostSegmented, segmented, numeric } = subtotal;
  if (directCostRange && range) {
    return rangeFn(subtotal, useDirectCost) as Cost;
  }
  if (directCostSegmented && segmented && !directCostRange && !range) {
    return segmentedFn(subtotal, useDirectCost) as PartialAddDeduct;
  }
  if (numeric) {
    return numericFn(subtotal);
  }
  return null;
};

export const getCellsT = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  treeNode: any,
  displayColumnDescriptions: ColumnDescription[],
  shadedColumns: CostReportColumnKey[],
  variant: RowVariantsString,
  useDirectCost: boolean,
  isVariance: boolean,
  columnKeyQuantityMap: Map<string, Numeric>
) => {
  const {
    data: { columns, lineID },
  } = treeNode;
  if (!displayColumnDescriptions || !columns) return [];
  const isEstimateLine = !!lineID;

  const cells: Cell[] = [];
  // for each columnTerm selected, try to get cell values
  displayColumnDescriptions.forEach((description: ColumnDescription) => {
    const isShaded =
      !isVariance &&
      !!description?.parentColumnKey &&
      shadedColumns.includes(description.parentColumnKey);

    const cell: Cell | undefined = getCell(
      description,
      columns,
      columnKeyQuantityMap,
      useDirectCost,
      variant,
      isShaded,
      isEstimateLine
    );
    if (cell) cells.push(cell);
  });
  // We want to identify the first column to display, where to complete the ledger line
  const firstIdx = cells.findIndex((cell: Cell | undefined) => {
    if (!cell) return false;
    const { cost } = cell;
    if (!cost) return false;
    const costValue = getCostValue(cost);
    // if the cost value exists, not as an empty object, and its not zero
    return costValue !== 0;
  });
  // everything before our first column is empty
  return cells.map((cell: Cell, idx) => {
    const hasFirstIdx = firstIdx > -1;
    const isBeforeFirstIdx = idx < firstIdx;
    const isAtFirstIdx = idx <= firstIdx;

    return {
      ...cell,
      hasLedger: !hasFirstIdx || isAtFirstIdx,
      isEmpty: !hasFirstIdx || isBeforeFirstIdx,
    };
  });
};

export type ColumnDescription =
  | undefined
  | {
      columnKey?: CostReportColumnKey;
      parentColumnKey?: CostReportColumnKey;
      label?: string; // only for non-buttons
      isWide?: boolean;
      title?: string; // only on buttons
      unitID?: UUID;
    };

export type VarianceColumnDescription = ColumnDescription & {
  milestoneID: UUID;
  milestoneName: string;
  date?: string;
};

export const getColumnDescriptionsByKeys = (
  keyPairs: ColumnKeyPair[],
  columnDescriptions: ColumnDescription[]
): ColumnDescription[] => {
  const descriptions: ColumnDescription[] = [];
  if (keyPairs && keyPairs.length) {
    keyPairs.forEach((keyPair: ColumnKeyPair) => {
      const { columnKey, parentColumnKey } = keyPair;
      const match = columnDescriptions.find(
        (cd: ColumnDescription) =>
          cd && cd.columnKey === columnKey && cd.parentColumnKey === parentColumnKey
      );
      if (match) {
        match.parentColumnKey = parentColumnKey;
        descriptions.push(match);
      }
    });
  }
  return descriptions;
};

export type ColumnKeyPair = {
  columnKey: CostReportColumnKey;
  parentColumnKey: CostReportColumnKey;
};

export const buildCostReportDisplayColumnDescriptions = (
  columns: CostReportColumnKey[],
  columnDescriptions: ColumnDescription[],
  isVariance: boolean,
  settings: MilestoneCostReportSettings,
  expressions: Expression[]
) => {
  const headerDescriptions = getDisplayColumnDescriptions(
    columns,
    columnDescriptions,
    isVariance,
    settings,
    expressions,
    true
  );

  const descriptions = getDisplayColumnDescriptions(
    columns,
    columnDescriptions,
    isVariance,
    settings,
    expressions
  );

  return { displayColumnDescriptions: descriptions, headerDescriptions };
};

export const buildCostReportisplayColumnDescriptions = (
  columns: CostReportColumnKey[],
  columnDescriptions: ColumnDescription[],
  expressions: Expression[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any
) => {
  const catMetricsDisplayColumnDescriptions = getDisplayColumnDescriptions(
    columns,
    columnDescriptions,
    false, // only used in PrintCostReport, not variance
    settings,
    expressions
  );

  const headerDescriptions = getDisplayColumnDescriptions(
    columns,
    columnDescriptions,
    false, // only used in PrintCostReport, not variance
    settings,
    expressions,
    true
  );

  return { displayColumnDescriptions: catMetricsDisplayColumnDescriptions, headerDescriptions };
};

export const getDisplayColumnDescriptions = (
  keys: CostReportColumnKey[],
  columnDescriptions: ColumnDescription[],
  isVariance: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings?: any,
  columnExpressions?: Expression[],
  isHeader = false
): ColumnDescription[] => {
  let columnKeyPairs: ColumnKeyPair[] = [];
  const totalNotSelected = Boolean(
    columnExpressions?.every((columnExpression) => columnExpression.text !== TOTAL)
  );
  const { metrics = [], subcolumns = [] } = settings;
  const metricsSelected = metrics.length && !subcolumns.length;
  const noSelection = !metrics.length && !subcolumns.length;

  if (!columnExpressions || noSelection) return [];

  const metricColumnExpressions = columnExpressions.filter(({ isMetric }) => isMetric);
  const nonMetricColumnExpressions = columnExpressions.filter(({ isMetric }) => !isMetric);

  const filterDescriptions = isHeader && metricsSelected && !isVariance;
  if (!filterDescriptions) {
    columnKeyPairs = getColumnKeyPairs(
      keys,
      nonMetricColumnExpressions,
      isHeader,
      totalNotSelected
    );
  }
  if (!isVariance) {
    const metricColumnKeyPairs = getMetricColumnKeyPairs(metricColumnExpressions, !!isHeader);
    columnKeyPairs = [...columnKeyPairs, ...metricColumnKeyPairs];
  }

  const displayColumnDescriptions = getColumnDescriptionsByKeys(columnKeyPairs, columnDescriptions);
  return displayColumnDescriptions;
};

const getColumnKeyPairs = (
  keys: CostReportColumnKey[],
  filteredColumnExpressions: Expression[],
  isHeader: boolean,
  totalNotSelected: boolean
) => {
  const columnKeyPairs: ColumnKeyPair[] = [];
  if (keys && keys.length) {
    keys.forEach((key) => {
      const isProjectColumn = getIsProjectColumn(key);
      if (isHeader && (isProjectColumn ? true : totalNotSelected)) {
        columnKeyPairs.push({ columnKey: key, parentColumnKey: key });
      } else {
        filteredColumnExpressions.forEach((columnExpression) => {
          const { id, text, include, isMetric } = columnExpression;
          const includeUnitColumns = isProjectColumn || text === TOTAL;
          if (include && includeUnitColumns) {
            const columnKey = getColumnKey(id, key, text, isMetric);
            if (columnKey) columnKeyPairs.push({ columnKey, parentColumnKey: key });
          }
        });
      }
    });
  }
  return columnKeyPairs;
};

const getMetricColumnKeyPairs = (metricColumnExpressions: Expression[], isHeader: boolean) => {
  const columnKeyPairs: ColumnKeyPair[] = [];

  if (!isHeader) {
    metricColumnExpressions.forEach((columnExpression) => {
      const { isCategorized, text, include } = columnExpression;
      if (include) {
        const columnKey = getMetricColumnKey(undefined, isCategorized, text);
        columnKeyPairs.push({ columnKey, parentColumnKey: columnKey });
      }
    });
  }
  return columnKeyPairs;
};

const getUnitColumnTerm = (columnTerm: string, abbrev: string) => {
  return `${columnTerm} / ${abbrev}`;
};
const getColumnTitle = (
  title: string,
  unitText: string,
  milestoneName: string,
  isMetric: boolean
) => {
  if (isMetric) return `${milestoneName} metric ${unitText}`;
  return `${title} divided by ${getBaseUnitText(unitText)}`;
};

const getProjectColumns = (
  columns: Map<CostReportColumnKey, string>,
  milestoneName: string
): ColumnDescription[] => {
  const estimateColumn = columns.get(CostReportColumnKey.ESTIMATE_KEY);
  const targetColumn = columns.get(CostReportColumnKey.TARGET_KEY);
  const runningTotalColumn = columns.get(CostReportColumnKey.RUNNINGTOTAL_KEY);
  return [
    {
      columnKey: CostReportColumnKey.ESTIMATE_KEY,
      title: `${estimateColumn} at ${milestoneName}`,
    },
    {
      columnKey: CostReportColumnKey.TARGET_KEY,
      title: `${targetColumn} at ${milestoneName}`,
    },
    {
      columnKey: CostReportColumnKey.DELTA_KEY,
      title:
        targetColumn && estimateColumn
          ? `Difference between ${targetColumn.toLowerCase()} and ${estimateColumn.toLowerCase()}`
          : undefined,
    },
    {
      columnKey: CostReportColumnKey.RUNNINGTOTAL_KEY,
      title: estimateColumn
        ? `${runningTotalColumn} of accepted and ${estimateColumn.toLowerCase()}`
        : undefined,
    },
    {
      columnKey: CostReportColumnKey.REMAINING_KEY,
      title:
        targetColumn && runningTotalColumn
          ? `Difference between ${targetColumn.toLowerCase()} and ${runningTotalColumn.toLowerCase()}`
          : undefined,
    },
  ];
};
const getDuplicatedProjectColumns = (
  t: TermStore,
  projectColumns: ColumnDescription[],
  milestoneName: string,
  isVariance: boolean,
  columnExpressions: Expression[],
  includeOwnerCosts: boolean
): ColumnDescription[] => {
  const projectColumnsDuplicated: ColumnDescription[] = [];
  projectColumns.forEach((c: ColumnDescription) => {
    if (c) {
      const column = c;
      column.parentColumnKey = column.columnKey;
      projectColumnsDuplicated.push(column);
      columnExpressions
        .filter(({ isMetric }: Expression) => isVariance || !isMetric)
        .forEach(({ id, isCategorized, isMetric, text }: Expression) => {
          if (c.columnKey) {
            const shouldAddColumn = isVariance || text !== TOTAL || (!isMetric && text !== TOTAL);

            if (shouldAddColumn) {
              const columnTerm = getColumnTermFromColumnKey(t, includeOwnerCosts, c.columnKey);
              const columnKey = getColumnKey(id, c.columnKey, text, isMetric, isCategorized);
              projectColumnsDuplicated.push({
                columnKey,
                parentColumnKey: c.columnKey,
                title: getColumnTitle(c.title || '', text, milestoneName, isMetric),
                unitID: id, // going to use this to verify a match!
                isWide: !!columnTerm && columnTerm.length > 20,
              });
            }
          }
        });
    }
  });
  return projectColumnsDuplicated;
};

const getMetricColumns = (
  milestoneName: string,
  isVariance: boolean,
  columnExpressions: Expression[]
): ColumnDescription[] => {
  const metricColumns: ColumnDescription[] = [];

  columnExpressions
    .filter(({ isMetric }: Expression) => isMetric)
    .forEach(({ id, isCategorized, text }: Expression) => {
      const shouldAddColumn = isVariance || text !== TOTAL;

      if (shouldAddColumn) {
        const columnKey = getMetricColumnKey(undefined, isCategorized, text);
        metricColumns.push({
          columnKey,
          title: getColumnTitle('', text, milestoneName, false),
          unitID: id, // going to use this to verify a match!
          isWide: false,
        });
      }
    });
  return metricColumns;
};

// this order dictates to selector order
export const getColumnDescriptionsT =
  (t: TermStore, includeOwnerCosts: boolean) =>
  (
    milestoneName: string,
    isVariance: boolean,
    columnExpressions?: Expression[]
  ): ColumnDescription[] => {
    const columns = columnsT(t, includeOwnerCosts);
    let projectColumns = getProjectColumns(columns, milestoneName);
    if (columnExpressions) {
      const projectColumnsDuplicated = getDuplicatedProjectColumns(
        t,
        projectColumns,
        milestoneName,
        isVariance,
        columnExpressions,
        includeOwnerCosts
      );
      projectColumns = projectColumnsDuplicated;
      if (!isVariance) {
        const metricColumns = getMetricColumns(milestoneName, isVariance, columnExpressions);
        projectColumns.push(...metricColumns);
      }
    }

    const estimateColumn = columns.get(CostReportColumnKey.ESTIMATE_KEY);
    const descriptions: ColumnDescription[] = [
      { label: 'Project Subtotals' },
      ...projectColumns,
      { label: 'Item Subtotals' },
      {
        columnKey: CostReportColumnKey.ACCEPTED_KEY,
        title: estimateColumn
          ? `Total of accepted changes against ${milestoneName} ${estimateColumn.toLowerCase()}`
          : undefined,
      },
      {
        columnKey: CostReportColumnKey.ACCEPTEDADDS_KEY,
        title: 'Accepted adds',
      },
      {
        columnKey: CostReportColumnKey.ACCEPTEDDEDUCTS_KEY,
        title: 'Accepted deducts',
      },
      {
        columnKey: CostReportColumnKey.PENDING_KEY,
        isWide: true,
        title: 'Total of pending items',
      },
      {
        columnKey: CostReportColumnKey.PENDINGADDS_KEY,
        title: 'Amount by which pending items could increase costs',
      },
      {
        columnKey: CostReportColumnKey.PENDINGDEDUCTS_KEY,
        title: 'Amount by which pending items could decrease costs',
      },
      {
        columnKey: CostReportColumnKey.REJECTED_KEY,
        isWide: true,
        title: `Total of rejected items`,
      },
      {
        columnKey: CostReportColumnKey.REJECTEDADDS_KEY,
        title: 'Rejected adds',
      },
      {
        columnKey: CostReportColumnKey.REJECTEDDEDUCTS_KEY,
        title: 'Rejected deducts',
      },
      {
        columnKey: CostReportColumnKey.INCORPORATED_KEY,
        title: `Changes incorporated into ${milestoneName}`,
      },
    ].map((cd: ColumnDescription) => ({
      columnKey: getColumnKey(cd?.columnKey),
      parentColumnKey: cd?.columnKey,
      ...cd,
    }));
    return descriptions;
  };

export const mapReportTypetoColumn = (type: CostReportColumnType) => {
  const split = type.split('_');
  return `${split[0]}_KEY` as CostReportColumnKey;
};

const getVarianceColumnDescriptions = (columnExpressions: Expression[], isHeader = false) => {
  const varianceKey = CostReportColumnKey.VARIANCE_KEY;
  const varianceColumnDescription: ColumnDescription = {
    columnKey: varianceKey,
    parentColumnKey: varianceKey,
    title: `Variance between milestones`, // TODO add milestone names?
    isWide: columnExpressions.length === 1,
  };

  const varianceDisplayDescriptions: ColumnDescription[] =
    !columnExpressions.length && isHeader ? [varianceColumnDescription] : [];

  columnExpressions.forEach(({ id, isMetric, text }) => {
    const columnKey = getColumnKey(id, CostReportColumnKey.VARIANCE_KEY, text, isMetric);

    const title = getColumnTitle(`Variance between milestones`, text, 'Variance', isMetric);

    varianceDisplayDescriptions.push({
      columnKey,
      parentColumnKey: varianceKey,
      title,
      unitID: id,
    });
  });
  return varianceDisplayDescriptions;
};

export const getVarianceReportDescriptions = (
  t: TermStore,
  types: CostReportColumnType[],
  milestoneName: string,
  columnExpressions: Expression[],
  isHeader: boolean,
  includeOwnerCosts: boolean
) => {
  const columns: ColumnKeyPair[] = [];
  types.forEach((type) => {
    const key = mapReportTypetoColumn(type);
    if (!columnExpressions.length && isHeader) {
      columns.push({
        columnKey: key,
        parentColumnKey: key,
      });
    }

    columnExpressions.forEach(({ id, isCategorized, isMetric, text }) => {
      const columnType = mapReportTypetoColumn(type);
      const columnKey = getColumnKey(id, columnType, text, isMetric, isCategorized);
      if (columnKey) columns.push({ columnKey, parentColumnKey: key });
    });
  });
  const columnDescriptions = getColumnDescriptionsT(t, includeOwnerCosts)(
    milestoneName,
    true,
    columnExpressions
  );
  const displayColumnDescriptions = getColumnDescriptionsByKeys(columns, columnDescriptions);

  const varianceDisplayDescriptions = getVarianceColumnDescriptions(columnExpressions, isHeader);

  return [...displayColumnDescriptions, ...varianceDisplayDescriptions];
};

const getEnabledExpression = (
  unitStrings: string[],
  unit: Unit,
  isCategorized: boolean,
  isMetric: boolean
): Expression => {
  const { abbreviationSingular, id } = unit;
  const unitText = getUnitText(abbreviationSingular, isMetric, isCategorized);
  return {
    id,
    include: unitStrings && unitStrings.includes(unitText),
    isCategorized,
    isMetric,
    text: unitText,
  };
};

const getAllMetricRelatedSettings = (settings: MilestoneCostReportSettings) => {
  const { metrics, subcolumns } = settings;
  const hasMetrics = metrics && metrics.length;
  const hasSubcolumns = subcolumns && subcolumns.length;

  if (hasMetrics && hasSubcolumns) return [...metrics, ...subcolumns];
  if (hasMetrics) return [...metrics];
  if (hasSubcolumns) return [...subcolumns];
  return [];
};

export const getExpressionsFromUnits = (settings: CostReportSettings, enabledUnits: Unit[]) => {
  let metricColumns: string[];
  let subColumns: string[];
  if ('varianceColumns' in settings) {
    metricColumns = settings.varianceColumns;
    subColumns = settings.varianceColumns;
  } else {
    metricColumns = settings.metrics;
    subColumns = settings.subcolumns;
  }

  const defaultExpression = {
    include: subColumns && subColumns.includes(TOTAL),
    isCategorized: false,
    isMetric: false,
    text: TOTAL,
  } as const;

  const enabledExpressions: Expression[] = [];
  enabledUnits.forEach((u) => {
    enabledExpressions.push(getEnabledExpression(metricColumns, u, false, true)); // METRIC_TOTAL
    enabledExpressions.push(getEnabledExpression(subColumns, u, false, false)); // $/METRIC_TOTAL
    enabledExpressions.push(getEnabledExpression(metricColumns, u, true, true)); // METRIC_CATEGORIZED
    enabledExpressions.push(getEnabledExpression(subColumns, u, true, false)); // $/METRIC_CATEGORIZED
  });
  return [defaultExpression, ...enabledExpressions];
};

export const getShadedColumns = (headerDescriptions: ColumnDescription[]) => {
  const shaded: CostReportColumnKey[] = [];
  headerDescriptions.forEach(
    (description, i) => i % 2 === 0 && description?.columnKey && shaded.push(description.columnKey)
  );
  return shaded;
};

export const getMatchingCostReportDescriptions = (
  displayColumnDescriptions: ColumnDescription[],
  columnKey?: CostReportColumnKey
) =>
  displayColumnDescriptions.filter((column) => {
    return column?.parentColumnKey === columnKey;
  });

export const getMatchingVarianceDescriptions = (
  displayColumnDescriptions: ColumnDescription[],
  i: number
) => {
  const count = displayColumnDescriptions.length / 3;

  const idx1 = i * count;
  const idx2 = idx1 + count;

  return displayColumnDescriptions.slice(idx1, idx2);
};

const hasNonTotalColumns = (columns: string[]) => columns && columns.some((unit) => unit !== TOTAL);

export const hasUnitColumns = (settings: CostReportSettings, isVariance: boolean) => {
  if (isVariance) {
    const { varianceColumns } = settings as VarianceSettings;
    return hasNonTotalColumns(varianceColumns);
  }
  const { metrics = [], subcolumns = [] } = settings as MilestoneCostReportSettings;
  return metrics.length || hasNonTotalColumns(subcolumns);
};

const getUnitQuantity = (nonZeroQuantities: Quantity[], unit: string) => {
  const nonCategorizedUnitText = getNonCategorizedUnitText(unit);
  const quantity = nonZeroQuantities.find(
    (q) =>
      q.unit.abbreviationSingular === nonCategorizedUnitText ||
      q.unit.abbreviationSingular === getBaseUnitText(nonCategorizedUnitText)
  );
  return quantity;
};

export const getCostReportColumns = (
  nonZeroQuantities: Quantity[],
  settings: MilestoneCostReportSettings
) => {
  const allMetricRelatedSettings = getAllMetricRelatedSettings(settings);
  const allColumns: CostReportColumnInput[] = addReports.map((type: CostReportColumnType) => ({
    type,
  }));
  // TODO: We might be able to limit the reports by columns, statuses in the future
  projectReports.forEach((type: CostReportColumnType) => {
    allColumns.push({ type });
    if (allMetricRelatedSettings) {
      allMetricRelatedSettings.forEach((unitKey: string) => {
        const isCategorized = keyIsCategorized(unitKey);
        const quantity = getUnitQuantity(nonZeroQuantities, unitKey);
        if (quantity) {
          allColumns.push({ type, unitID: quantity.unit.id, isCategorized });
        }
      });
    }
  });
  if (allMetricRelatedSettings && hasUnitColumns(settings, false)) {
    allMetricRelatedSettings.forEach((unit: string) => {
      const isCategorized = keyIsCategorized(unit);
      const quantity = getUnitQuantity(nonZeroQuantities, unit);
      if (quantity) {
        const type = isCategorized
          ? CostReportColumnType.CATEGORIZEDMETRIC_REPORT
          : CostReportColumnType.METRIC_REPORT;
        allColumns.push({ type, unitID: quantity.unit.id, isCategorized });
      }
    });
  }
  return allColumns;
};
