import { useListBoxSection } from 'react-aria';
import { ListState, Node } from 'react-stately';

import Entry, { EntryContent } from './Entry';
import { SelectEntry, SelectEntryID } from './types';

export type SectionProps = {
  onViewChildren?: (parentID: SelectEntryID) => void;
  section: Node<SelectEntry>;
  state: ListState<SelectEntry>;
};

export const SelectSection = (props: SectionProps) => {
  const value = props.section.value;
  if (!value) {
    throw Error('Failed to get value from entry.');
  }

  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: value.label,
  });

  const children = props.state.collection.getChildren?.(props.section.key);
  if (!children) return null;

  return (
    <li {...itemProps}>
      <div
        className="w-full bg-background-2 px-2 py-1 text-type-primary type-label"
        {...headingProps}
      >
        <EntryContent badge={value.badge} description={value.description} label={value.label} />
      </div>
      <ul {...groupProps}>
        {[...children].map((node) => (
          <Entry
            key={node.key}
            entry={node}
            onViewChildren={props.onViewChildren}
            state={props.state}
          />
        ))}
      </ul>
    </li>
  );
};
