import { useRef } from 'react';
import { VisuallyHidden, useFocusRing, useSwitch } from 'react-aria';
import { useToggleState } from 'react-stately';

import { StrictUnion } from '../../../utilities/types';

type BaseProps = {
  isChecked?: boolean;
  'data-cy'?: string;
  id?: string;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  onChange: (checked: boolean) => void;
};

type LabelledProps = BaseProps & {
  'aria-label'?: string;
  label: string;
};

type UnlabelledProps = BaseProps & {
  'aria-label': string;
  label?: string;
};

type Props = StrictUnion<LabelledProps | UnlabelledProps>;

const Switch = (props: Props) => {
  const toggleProps = {
    isDisabled: props.isDisabled,
    isSelected: props.isChecked,
    onChange: props.onChange,
  };

  const state = useToggleState(toggleProps);
  const ref = useRef(null);
  const {
    inputProps: { id, ...inputProps },
  } = useSwitch(
    {
      ...toggleProps,
      'aria-label': props['aria-label'],
      children: props.label,
      id: props.id,
    },
    state,
    ref
  );

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <label
      className={[
        'group relative inline-flex h-10 cursor-pointer items-center gap-2 rounded',
        props.isFullWidth ? 'w-full' : '',
      ].join(' ')}
      data-cy={props['data-cy']}
      id={id}
    >
      <Label isDisabled={props.isDisabled} label={props.label} />
      <VisuallyHidden className="left-0 top-0">
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <Toggle
        isChecked={props.isChecked}
        isDisabled={props.isDisabled}
        isFocusVisible={isFocusVisible}
      />
    </label>
  );
};

const Label = ({ isDisabled, label }: Pick<Props, 'isDisabled' | 'label'>) => {
  const text = label ? (
    <div className={`type-body1 ${isDisabled ? 'text-type-inactive' : 'text-type-primary'}`}>
      {label}
    </div>
  ) : null;
  return text;
};

const Toggle = (props: { isChecked?: boolean; isDisabled?: boolean; isFocusVisible: boolean }) => {
  let containerClassName = '';
  let switchClassName = '';
  if (props.isDisabled) {
    containerClassName = props.isChecked ? 'bg-switch-disabled-on' : 'bg-switch-disabled-off';
    switchClassName = props.isChecked ? 'bg-switch-disabled-on' : 'bg-switch-disabled-off';
  } else {
    containerClassName = props.isChecked
      ? 'bg-switch-track-active'
      : 'bg-switch-track-off group-hover:bg-switch-track-active';
    switchClassName = props.isChecked
      ? 'bg-switch-knob-active'
      : 'bg-switch-knob-off group-hover:bg-switch-hover';
  }

  return (
    <div
      className={`${containerClassName} relative ml-auto h-[10px] w-8 shrink-0 rounded-2xl transition-all`}
    >
      <div
        className={[
          'absolute -top-[3px] h-4 w-4 rounded-full shadow transition-[left]',
          switchClassName,
          props.isChecked
            ? 'left-[calc(100%-1rem)]' /* it's calc(100% - the-width-of-the-switch) */
            : 'left-0',
          props.isFocusVisible ? 'outline outline-4' : '',
        ].join(' ')}
      />
    </div>
  );
};

export default Switch;
