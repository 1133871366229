import { useListBoxSection } from 'react-aria';

import Checkbox from '../Checkbox/Checkbox';

import Entry, { EntryContent } from './Entry';
import { SectionProps } from './SelectSection';

export const MultiSelectSection = (props: SectionProps) => {
  const value = props.section.value;
  if (!value) {
    throw Error('Failed to get value from entry.');
  }

  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: value.label,
  });

  const children = props.state.collection.getChildren?.(props.section.key);
  if (!children) return null;

  const allChildren = [...children];
  const selectableChildren = allChildren.filter((node) => !node.value?.disabled);

  // Multi-select state
  const selectedKeys = props.state.selectionManager.selectedKeys;
  const allSelected = allChildren.every((node) => selectedKeys.has(node.key));
  const someSelected = allChildren.some((node) => selectedKeys.has(node.key));
  const isIndeterminate = someSelected && !allSelected;

  const onClick = () => {
    // We use selectableChildren here to only toggle entries that can be selected
    const allSelectableSelected = selectableChildren.every((node) => selectedKeys.has(node.key));
    const childKeys = selectableChildren.map((node) => node.key);
    const currentSelection = new Set(selectedKeys);

    childKeys.forEach((key) => {
      if (allSelectableSelected) {
        currentSelection.delete(key);
      } else {
        currentSelection.add(key);
      }
    });

    props.state.selectionManager.setSelectedKeys([...currentSelection]);
  };

  return (
    <li {...itemProps}>
      <div
        className={`flex w-full cursor-pointer items-center bg-background-primary p-2 text-type-primary hover:bg-selection-hover ${allSelected ? 'bg-selection-selected' : ''}`}
        {...headingProps}
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onClick();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div className="flex items-center gap-2">
          <div
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            role="presentation"
          >
            <Checkbox
              aria-label={`${value.label} section checkbox`}
              isIndeterminate={isIndeterminate}
              isSelected={allSelected || someSelected}
              onChange={onClick}
            />
          </div>
          <EntryContent badge={value.badge} description={value.description} label={value.label} />
        </div>
      </div>
      <ul {...groupProps}>
        {allChildren.map((node) => (
          <Entry
            key={node.key}
            className="pl-10"
            entry={node}
            onViewChildren={props.onViewChildren}
            state={props.state}
          />
        ))}
      </ul>
    </li>
  );
};
