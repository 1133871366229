import { Key, KeyboardEvent, useRef } from 'react';
import { AriaListBoxOptions, useListBox } from 'react-aria';
import { ListState } from 'react-stately';

import { isMac } from '../../../utilities/utilities';
import Checkbox from '../Checkbox/Checkbox';

import Entry, { EntryContent, EntryProps } from './Entry';
import { MultiSelectSection } from './MultiSelectSection';
import { SectionProps, SelectSection } from './SelectSection';
import { SelectEntry, SelectEntryID } from './types';

// Simple component for the "Select All" option at the top of multi-select lists
function SelectAllEntry(props: {
  onSelectAll: (isChecked: boolean) => void;
  isIndeterminate: boolean;
  isSelected: boolean;
}) {
  return (
    <li
      aria-selected={props.isSelected}
      className="flex gap-2 border-b border-border-muted bg-background-primary p-2 transition hover:bg-selection-hover"
      role="option"
      tabIndex={0}
    >
      <Checkbox
        aria-label="select all entries"
        fullWidth
        isIndeterminate={props.isIndeterminate}
        isSelected={props.isSelected}
        onChange={props.onSelectAll}
      >
        <EntryContent label="Select All" />
      </Checkbox>
    </li>
  );
}

export default function EntryList(props: {
  isAllSelectable?: boolean;
  isMultiSelect?: boolean;
  menuProps: AriaListBoxOptions<SelectEntry>;
  onFilterEntry?: (entry: SelectEntry) => boolean | SelectEntry[];
  onHoverEntry?: EntryProps['onHover'];
  onViewChildren?: SectionProps['onViewChildren'];
  onViewParent: () => void;
  parentEntryID?: SelectEntryID | null;
  state: ListState<SelectEntry>;
}) {
  const { state } = props;

  const ref = useRef<HTMLUListElement>(null);
  const { listBoxProps } = useListBox(
    {
      ...props.menuProps,
      selectionMode: props.isMultiSelect ? 'multiple' : 'single',
    },
    props.state,
    ref
  );

  const { onKeyDown } = listBoxProps;
  listBoxProps.onKeyDown = (e) => {
    // By default, Esc will clear all selected entries in a MultiSelect context. That's silly.
    if (e.key === 'Escape') return;

    // By default, the Ctrl/Cmd+A action only selects all the entries, it won't deselect them.
    if (
      props.isAllSelectable &&
      ((isMac() && e.metaKey && e.key === 'a') || (!isMac() && e.ctrlKey && e.key === 'a'))
    ) {
      state.selectionManager.toggleSelectAll();
    }

    // Handle nav for arrow keys to support filtering / children.
    if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
      handleKeyboardNavEvent(e, ref.current, state.selectionManager.focusedKey);
    } else {
      onKeyDown?.(e);
    }
  };

  const parentEntry = props.parentEntryID
    ? state.collection.getItem(props.parentEntryID)
    : undefined;

  // Filter entries based on search/filter criteria
  const entries = [...state.collection].flatMap((entry) => {
    if (!entry?.value) return [];
    if (!props.onFilterEntry) return [entry];

    const res = props.onFilterEntry(entry.value);
    if (typeof res === 'boolean') return res ? [entry] : [];

    return res.flatMap((e) => {
      const node = props.state.collection.getItem(e.id);
      return node ? [node] : [];
    });
  });

  // Get all entries (including children of sections)
  const allEntries = entries.flatMap((entry) => {
    if (!entry?.value?.isSection) {
      return [entry];
    }

    const children = props.state.collection.getChildren?.(entry.key) ?? [];
    const filterFn = props.onFilterEntry;
    if (!filterFn) {
      return [...children];
    }

    return [...children].filter((child) => {
      if (!child?.value) return false;
      const res = filterFn(child.value);
      return typeof res === 'boolean' ? res : res.length > 0;
    });
  });

  // Filter to just selectable entries for selection operations
  const selectableEntries = allEntries.filter((entry) => !entry?.value?.disabled);

  /*
   * Select all selects the entries in the current view as well as their parent, if exists.
   * If select all is unchecked or indeterminate, selecting it will select all entries per the above condition.
   * If select all is checked, selecting it will clear out selection
   */
  const onSelectAll = () => {
    // Current list of entry ids, and if applicable their parent
    const entryIDsInView = [
      ...selectableEntries.map((e) => e.key),
      ...(props.parentEntryID ? [props.parentEntryID] : []),
    ];

    // If every entry in view is selected, select all will remove those from the selection
    if (entryIDsInView.every((id) => state.selectionManager.selectedKeys.has(id))) {
      const remainingKeys = [...state.selectionManager.selectedKeys].filter(
        (key) => !entryIDsInView.includes(key)
      );
      state.selectionManager.setSelectedKeys(remainingKeys);
      return;
    }

    const currentEntryIDs = [...selectableEntries.map((e) => e.key)];
    const newSelections = props.parentEntryID
      ? [props.parentEntryID, ...currentEntryIDs]
      : currentEntryIDs;

    // Maintain current select and append rest of select all
    state.selectionManager.setSelectedKeys([
      ...state.selectionManager.selectedKeys,
      ...newSelections,
    ]);
  };

  return (
    <ul {...listBoxProps} ref={ref} className="outline-none" data-cy="entrylist">
      {props.isAllSelectable && (
        <SelectAllEntry
          isIndeterminate={
            Boolean(state.selectionManager.selectedKeys.size) &&
            state.selectionManager.selectedKeys.size < allEntries.length
          }
          isSelected={Boolean(state.selectionManager.selectedKeys.size)}
          onSelectAll={onSelectAll}
        />
      )}
      {parentEntry && <Entry entry={parentEntry} onViewParent={props.onViewParent} state={state} />}
      {entries.map((entry) =>
        entry.value?.isSection ? (
          <Section
            key={entry.key}
            onViewChildren={props.onViewChildren}
            section={entry}
            state={state}
          />
        ) : (
          <Entry
            key={entry.key}
            entry={entry}
            onHover={props.onHoverEntry}
            onViewChildren={props.onViewChildren}
            state={state}
          />
        )
      )}
      {entries.length === 0 && (
        <div className="px-4 py-2 italic text-type-inactive type-body1">No results...</div>
      )}
    </ul>
  );
}

const Section = (props: SectionProps) => {
  const isMultiSelect = props.state.selectionManager.selectionMode === 'multiple';
  return isMultiSelect ? <MultiSelectSection {...props} /> : <SelectSection {...props} />;
};

function handleKeyboardNavEvent(
  e: KeyboardEvent<Element>,
  uListEl: HTMLUListElement | null,
  focusedKey: Key | null
) {
  if (!uListEl) return;
  if (focusedKey === null) return;

  if (e.key === 'ArrowDown') {
    const nextElement = uListEl.querySelector(
      `li:has([data-key="${focusedKey}"]) ~ li:has([data-key]:not([aria-disabled])) [role=option]`
    );
    if (nextElement instanceof HTMLElement) nextElement.focus();
    else {
      const firstElement = uListEl.querySelector(
        'ul[role="listbox"] > li:has([data-key]:not([aria-disabled])) [role=option]'
      );
      if (firstElement instanceof HTMLElement) firstElement.focus();
    }
  } else if (e.key === 'ArrowUp') {
    // This doesn't handle skipping a disabled entry. TBD...
    const prevElement = uListEl.querySelector(
      `li:has(+ li > [data-key="${focusedKey}"]) [role=option]`
    );
    if (prevElement instanceof HTMLElement) prevElement.focus();
  } else if (e.key === 'ArrowRight') {
    const viewChildrenButtonElement = uListEl.querySelector(`[data-key="${focusedKey}"] + button`);
    if (viewChildrenButtonElement instanceof HTMLElement) viewChildrenButtonElement.focus();
  } else if (e.key === 'ArrowLeft') {
    const viewParentButtonElement = uListEl.querySelector(
      `button:has(+ [data-key="${focusedKey}"])`
    );
    if (viewParentButtonElement instanceof HTMLElement) viewParentButtonElement.focus();
    else {
      const optionElement = uListEl.querySelector(`[data-key="${focusedKey}"]`);
      if (optionElement instanceof HTMLElement) optionElement.focus();
    }
  }
}
