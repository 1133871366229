import {
  ALL_MILESTONES,
  ASSIGNEE,
  BUCKET,
  BUCKET_MEETING,
  BUCKET_MILESTONE,
  CREATOR,
  STATUS,
  VISIBILITY,
} from '../../constants';
import { ItemsSortKey } from '../../generated/graphql';
import { makeOptionsFromCategorizations } from '../../utilities/categorization';
import { removeYear } from '../../utilities/string';
import { SelectEntry } from '../scales';
import { MultiGroupOrderOption } from '../shared-widgets/MultiGroup/MultiGroupOrderCategorizationUtils';

export const computeGroupByOptions = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  projectCategorizations: any[] = [],
  isScheduleImpactEnabled: boolean,
  filteredMilestoneId?: string
): MultiGroupOrderOption[] => {
  const singleCategorizations = [
    {
      name: 'Status',
      variant: STATUS,
    },
    {
      name: 'Assignee',
      variant: ASSIGNEE,
    },
    {
      name: 'Creator',
      variant: CREATOR,
    },
  ];
  const bucketName =
    !filteredMilestoneId || filteredMilestoneId === ALL_MILESTONES
      ? BUCKET_MILESTONE
      : BUCKET_MEETING;
  if (bucketName !== BUCKET_MEETING) {
    singleCategorizations.push({
      name: BUCKET_MILESTONE,
      variant: BUCKET,
    });
  }
  if (isScheduleImpactEnabled) {
    singleCategorizations.push({
      name: 'Schedule impact',
      variant: 'schedule impact',
    });
  }

  singleCategorizations.push({
    name: 'Share settings',
    variant: VISIBILITY,
  });

  if (projectCategorizations) {
    const options = makeOptionsFromCategorizations(projectCategorizations);
    return [...singleCategorizations, ...options];
  }
  return singleCategorizations;
};

export const computeSortBy = (
  categorizations: Categorization[],
  isScheduleImpactEnabled: boolean,
  shouldDisplayCosts: boolean
) => {
  const sortBy: SelectEntry[] = [
    { id: ItemsSortKey.SORT_NUMBER, label: 'Item number' },
    { id: ItemsSortKey.SORT_STATUS, label: 'Status' },
    { id: ItemsSortKey.SORT_VISIBILITY, label: 'Visibility' },
    { id: ItemsSortKey.SORT_CREATOR, label: 'Creator' },
    { id: ItemsSortKey.SORT_CREATION_TIME, label: 'Creation time' },
    { id: ItemsSortKey.SORT_DUE_DATE, label: 'Due date' },
    { id: ItemsSortKey.SORT_DATE_MODIFIED, label: 'Last updated' },
  ];
  if (shouldDisplayCosts) {
    sortBy.push({ id: ItemsSortKey.SORT_COST_IMPACT, label: 'Cost impact' });
    sortBy.push({ id: ItemsSortKey.SORT_COST_IMPACT_MAGNITUDE, label: 'Magnitude' });
  }
  if (isScheduleImpactEnabled) {
    sortBy.push({ id: ItemsSortKey.SORT_SCHEDULE_IMPACT, label: 'Schedule impact' });
  }
  categorizations.map((c) =>
    sortBy.push({
      id: c.name,
      label: removeYear(c.name, 'builtin' in c && c.builtin),
    })
  );

  return sortBy;
};
