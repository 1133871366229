import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTE_VIEW } from '../actions/actionTypes';
import {
  AnalyticsEvent,
  EventProperties,
  SendAnalyticsFn,
  analyticsEvent,
} from '../analytics/analyticsEventProperties';
import { useAnalyticsProperties } from '../components/contexts/analytics';
import { NULL_ID } from '../constants';
import { CostDisplay } from '../generated/graphql';
import { useCostModePermissions } from '../utilities/costMode';
import { getPageView } from '../utilities/getPageView';
import { UsePermissions } from '../utilities/permissions/types';
import usePermissions from '../utilities/permissions/usePermissions';
import { useAuth0Properties } from '../utilities/userProfile';
import { replaceUUIDs } from '../utilities/uuid';

const CLEAR_TYPENAME = { __typename: undefined } as const;
export type { EventProperties };
export type { AnalyticsEvent };
export type { SendAnalyticsFn };

export default function useSendAnalytics(): SendAnalyticsFn {
  const permissions = usePermissions();
  return useSendAnalyticsPermissions(permissions);
}

export function useSendAnalyticsPermissions(permissions: UsePermissions) {
  const costMode = useCostModePermissions(permissions);
  const analyticsProperties = useAnalyticsProperties();

  return useCallback(
    (event: AnalyticsEvent) => {
      const isProjectAnalytics =
        analyticsProperties?.projectProperties?.projectId &&
        analyticsProperties?.projectProperties?.projectId !== NULL_ID;

      window?.analytics?.track(event.type, {
        ...event.eventProperties,
        ...analyticsProperties?.permissionsProperties,
        ...analyticsProperties?.projectProperties,
        ...analyticsProperties?.groupProperties,
        ...CLEAR_TYPENAME,
        screenWidth: window?.innerWidth,
        screenHeight: window?.innerHeight,
        costDisplay: isProjectAnalytics ? costMode.costDisplay : undefined,
        markupMode:
          isProjectAnalytics && costMode.costDisplay === CostDisplay.SHOW_COSTS
            ? costMode.markupMode
            : undefined,
      });
    },
    [costMode, analyticsProperties]
  );
}

// useAnalyticsIdentifyUser - Hook for User Identify on Changes
export const useAnalyticsIdentifyUser = () => {
  const [isIdentified, setIsIdentified] = useState(false);
  const { analytics } = window || {};
  const auth0Properties = useAuth0Properties();
  const analyticsProperties = useAnalyticsProperties();
  const projectId = analyticsProperties?.projectProperties?.projectId;
  const companyId = analyticsProperties?.companyProperties?.companyId;

  useEffect(() => {
    if (analytics && projectId) analytics.group(projectId);
  }, [analytics, projectId]);

  useEffect(() => {
    if (analytics && companyId) analytics.group(companyId);
  }, [analytics, companyId]);

  useEffect(() => {
    if (!isIdentified && analytics && auth0Properties) {
      analytics.identify({
        ...auth0Properties,
        ...analyticsProperties?.companyProperties,
        ...analyticsProperties?.userProperties,
        ...CLEAR_TYPENAME,
      });
      setIsIdentified(true);
    }
  }, [analytics, auth0Properties, analyticsProperties, isIdentified]);
};

// useAnalyticsLocationViewEvent - Hook for reacting to location change with an event
export const useAnalyticsLocationViewEvent = () => {
  const sendAnalytics = useSendAnalytics();

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const routePath = pathname.split('/').map(replaceUUIDs).join('/');

    sendAnalytics(analyticsEvent(ROUTE_VIEW, { route: routePath }));

    // DEPRECATED - Consider not using these analytics going forward since they rely on hardcoded
    // strings and managing specifics for subroutes. If you/someone you know is asking for new page
    // view analytics, point them at the `route_view` event populated above which will catch everything
    // and can be segmented by the `route` property in Amplitude.
    sendAnalytics(analyticsEvent(getPageView(pathname)));
  }, [pathname, sendAnalytics]);
};
