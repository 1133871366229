import { PageViews } from '../analytics/analyticsEventProperties';
import { EXECUTIVE_DASHBOARD_PATH, PROJECT_COST_BREAKDOWN_REPORT } from '../constants';

export function getPageView(pathname: string) {
  const currentPath = pathname.split('/');
  const currentPage = currentPath[2];
  const currentPageDetail = currentPath[3];
  const msrPage = currentPath[4] === 'costs';
  const pcbrPage = currentPath[4] === PROJECT_COST_BREAKDOWN_REPORT;
  const isExecDashboard = currentPath[1] === EXECUTIVE_DASHBOARD_PATH;
  const isBenchmarking = currentPath[1] === 'benchmarking';

  if ((currentPage === undefined || (currentPage === '/' && !isExecDashboard)) && !isBenchmarking) {
    return PageViews.PROJECT_LIST;
  }
  if (currentPage === 'milestones' && currentPageDetail === '') {
    return PageViews.MILESTONES;
  }
  if (currentPage === 'milestones' && currentPageDetail !== '') {
    if (msrPage) {
      return PageViews.MSR;
    }
    if (currentPageDetail === 'variance') {
      return PageViews.VARIANCE_REPORT;
    }
    if (pcbrPage) {
      return PageViews.PROJECT_COST_BREAKDOWN_REPORT;
    }
    return PageViews.MILESTONE_DETAILS;
  }
  if (currentPage === 'items' && currentPageDetail === '') {
    return PageViews.ITEM_LIST;
  }
  if (currentPage === 'items' && currentPageDetail !== '') {
    return PageViews.ITEM_DETAILS;
  }
  if (currentPage === 'settings' && currentPageDetail === '') {
    return PageViews.SETTINGS;
  }
  if (currentPage === 'team' && currentPageDetail === '') {
    return PageViews.TEAM;
  }
  if (currentPage === 'dashboard' && currentPageDetail === '') {
    return PageViews.DASHBOARD;
  }
  if (currentPage === 'reports' && currentPageDetail === '') {
    return PageViews.REPORTS;
  }
  if (isExecDashboard) {
    return PageViews.EXECUTIVE_DASHBOARD;
  }
  if (currentPageDetail === 'roles') {
    return PageViews.TEAM_ROLES;
  }
  return `${currentPage}_view`;
}
