import { ALL_MILESTONES } from '../../../constants';
import {
  MinimalCostReportsQuery,
  MinimalCostReportsQueryVariables,
} from '../../../generated/graphql';
import { columns } from '../../../hooks/constants';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { useCostModePermissions } from '../../../utilities/costMode';
import { UsePermissions } from '../../../utilities/permissions/types';

import { minimalCostReportsQuery } from './queries';

export const GetGroupBysInput: (groupBys: DisplayGroupBy[]) => GroupByItem[] = (groupBys) =>
  groupBys.map(({ id, name, level }) => ({
    categorization: {
      id,
      name,
    },
    level,
  }));

const makeVariablesForMCRQuery = (milestoneID: string | undefined, groupBy: DisplayGroupBy[]) => ({
  milestones: milestoneID ? [{ milestoneID }] : [],
  groupByItems: GetGroupBysInput(groupBy),
});

export default function useMilestoneCostReportQuery(
  permissions: UsePermissions,
  milestoneId: string | undefined,
  groupBy: DisplayGroupBy[],
  costReportColumns: CostReportColumnInput[] = columns,
  projectID: string,
  viewFilter: ViewFilterInput,
  useDraftEstimate = false,
  useDraftBudget = false,
  skip = false,
  mountPolicy?: MountPolicy
) {
  const validMilestoneId = milestoneId !== ALL_MILESTONES && milestoneId ? milestoneId : undefined;
  return useQuery<MinimalCostReportsQuery, MinimalCostReportsQueryVariables>(
    minimalCostReportsQuery,
    {
      skip: skip || !projectID || !validMilestoneId,
      variables: {
        ...makeVariablesForMCRQuery(validMilestoneId, groupBy),
        costMode: useCostModePermissions(permissions),
        viewFilter,
        columns: costReportColumns,
        projectID,
        useDraftEstimate,
        useDraftBudget,
      },
    },
    mountPolicy
  );
}
