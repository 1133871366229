import { createContext, useContext } from 'react';

import { GetProjectPropsQuery } from '../../generated/graphql';

type ProjectProps = NonNullable<GetProjectPropsQuery['project']>;

const ProjectPropsContext = createContext<ProjectProps | undefined>(undefined);
export const ProjectPropsContextProvider = ProjectPropsContext.Provider;

export function useProjectProps(): ProjectProps | undefined {
  const projectProps = useContext(ProjectPropsContext);

  return projectProps;
}
