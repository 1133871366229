import { isEnumValue } from '../utilities/types';

// 1. Add a new entry to RouteKeys
export enum RouteKeys {
  SPLAT = 'SPLAT',

  // The enum names should reflect the url path. For example, if the path is
  // ':projectId/team/roles', the enum name should be 'PROJECT_TEAM_ROLES'. This
  // maintains a level of specificity that provides clarity on how links are
  // used within our app and prevents conflicting or confusing path names.
  APOLLO = 'APOLLO',
  AUTHORIZE = 'AUTHORIZE',
  BENCHMARKING = 'BENCHMARKING',
  BENCHMARKING_EXPLORE = 'BENCHMARKING_EXPLORE',
  BENCHMARKING_PROJECT_COMPS = 'BENCHMARKING_PROJECT_COMPS',
  BENCHMARKING_PROJECT_COMPS_PRINT = 'BENCHMARKING_PROJECT_COMPS_PRINT',
  BENCHMARKING_PROJECT_COMPS_SAVED = 'BENCHMARKING_PROJECT_COMPS_SAVED',
  BENCHMARKING_PROJECT_COMPS_SAVED_PRINT = 'BENCHMARKING_PROJECT_COMPS_SAVED_PRINT',
  BENCHMARKING_REPORTS = 'BENCHMARKING_REPORTS',
  COMPANY = 'COMPANY',
  COMPANY_EMPLOYEES = 'COMPANY_EMPLOYEES',
  COMPANY_EMPLOYEES_INDEX = 'COMPANY_EMPLOYEES_INDEX',
  COMPANY_EMPLOYEES_USER = 'COMPANY_EMPLOYEES_USER',
  COMPANY_INSIGHTS_ALERTS = 'COMPANY_INSIGHTS_ALERTS',
  COMPANY_ORGANIZATIONS = 'COMPANY_ORGANIZATIONS',
  COMPANY_ORGANIZATIONS_ORGANIZATION = 'COMPANY_ORGANIZATIONS_ORGANIZATION',
  COMPANY_PARTNERS = 'COMPANY_PARTNERS',
  COMPANY_PARTNERS_INDEX = 'COMPANY_PARTNERS_INDEX',
  COMPANY_PARTNERS_USER = 'COMPANY_PARTNERS_USER',
  COMPANY_PROJECT_STATS = 'COMPANY_PROJECT_STATS',
  COMPANY_SETTINGS = 'COMPANY_SETTINGS',
  COMPANY_STANDARDS = 'COMPANY_STANDARDS',
  COMPANY_STANDARDS_CATEGORIZATIONS = 'COMPANY_STANDARDS_CATEGORIZATIONS',
  COMPANY_STANDARDS_PROJECT_TEMPLATES = 'COMPANY_STANDARDS_PROJECT_TEMPLATES',
  COMPANY_STANDARDS_PROJECT_TYPES = 'COMPANY_STANDARDS_PROJECT_TYPES',
  DEACTIVATED = 'DEACTIVATED',
  EXPORT = 'EXPORT',
  EXPORT_PROJECT = 'EXPORT_PROJECT',
  EXPORT_PROJECT_ACTIVE_MILESTONE_CONTINGENCY = 'EXPORT_PROJECT_ACTIVE_MILESTONE_CONTINGENCY',
  EXPORT_PROJECT_ALL_MILESTONES_CONTINGENCY = 'EXPORT_PROJECT_ALL_MILESTONES_CONTINGENCY',
  EXPORT_PROJECT_COST_BREAKDOWN_REPORT = 'EXPORT_PROJECT_COST_BREAKDOWN_REPORT',
  EXPORT_PROJECT_ITEMS_LIST = 'EXPORT_PROJECT_ITEMS_LIST',
  EXPORT_PROJECT_MILESTONE_BUDGET = 'EXPORT_PROJECT_MILESTONE_BUDGET',
  EXPORT_PROJECT_MILESTONE_ESTIMATE = 'EXPORT_PROJECT_MILESTONE_ESTIMATE',
  EXPORT_PROJECT_MSR = 'EXPORT_PROJECT_MSR',
  EXPORT_PROJECT_VARIANCE = 'EXPORT_PROJECT_VARIANCE',
  FORECASTING = 'FORECASTING',
  FORECASTING_EXPLORE = 'FORECASTING_EXPLORE',
  FORECASTING_PROJECT_COMPS = 'FORECASTING_PROJECT_COMPS',
  FORECASTING_PROJECT_COMPS_PRINT = 'FORECASTING_PROJECT_COMPS_PRINT',
  FORECASTING_PROJECT_COMPS_SAVED = 'FORECASTING_PROJECT_COMPS_SAVED',
  FORECASTING_PROJECT_COMPS_SAVED_PRINT = 'FORECASTING_PROJECT_COMPS_SAVED_PRINT',
  FORECASTING_REPORTS = 'FORECASTING_REPORTS',
  INSIGHTS = 'INSIGHTS',
  INSIGHTS_CLASSIC = 'INSIGHTS_CLASSIC',
  INSIGHTS_PROJECT = 'INSIGHTS_PROJECT',
  NOT_FOUND = 'NOT_FOUND',
  PRINT = 'PRINT',
  PRINT_PROJECT = 'PRINT_PROJECT',
  PRINT_PROJECT_ACTIVE_MILESTONE_CONTINGENCY = 'PRINT_PROJECT_ACTIVE_MILESTONE_CONTINGENCY',
  PRINT_PROJECT_ALL_MILESTONES_CONTINGENCY = 'PRINT_PROJECT_ALL_MILESTONES_CONTINGENCY',
  PRINT_PROJECT_BREAKDOWNS = 'PRINT_PROJECT_BREAKDOWNS',
  PRINT_PROJECT_CONTINGENCY_ALLOWANCE_REPORT = 'PRINT_PROJECT_CONTINGENCY_ALLOWANCE_REPORT',
  PRINT_PROJECT_CONTINGENCY_ALL_MILESTONES_REPORT = 'PRINT_PROJECT_CONTINGENCY_ALL_MILESTONES_REPORT',
  PRINT_PROJECT_COST_BREAKDOWN = 'PRINT_PROJECT_COST_BREAKDOWN',
  PRINT_PROJECT_COST_BREAKDOWN_REPORT = 'PRINT_PROJECT_COST_BREAKDOWN_REPORT',
  PRINT_PROJECT_DASHBOARD = 'PRINT_PROJECT_DASHBOARD',
  PRINT_PROJECT_DASHBOARD_SUMMARY = 'PRINT_PROJECT_DASHBOARD_SUMMARY',
  PRINT_PROJECT_ITEMS_LIST = 'PRINT_PROJECT_ITEMS_LIST',
  PRINT_PROJECT_ITEMS_LIST_DETAILS = 'PRINT_PROJECT_ITEMS_LIST_DETAILS',
  PRINT_PROJECT_ITEM_DETAILS = 'PRINT_PROJECT_ITEM_DETAILS',
  PRINT_PROJECT_MILESTONE_BUDGET = 'PRINT_PROJECT_MILESTONE_BUDGET',
  PRINT_PROJECT_MILESTONE_ESTIMATE = 'PRINT_PROJECT_MILESTONE_ESTIMATE',
  PRINT_PROJECT_MSR = 'PRINT_PROJECT_MSR',
  PRINT_PROJECT_MSR_SUMMARY = 'PRINT_PROJECT_MSR_SUMMARY',
  PRINT_PROJECT_REPORT = 'PRINT_PROJECT_REPORT',
  PRINT_PROJECT_SCENARIOS = 'PRINT_PROJECT_SCENARIOS',
  PRINT_PROJECT_VARIANCE = 'PRINT_PROJECT_VARIANCE',
  PROGRAM = 'PROGRAM',
  PROGRAM_DASHBOARD = 'PROGRAM_DASHBOARD',
  PROGRAM_DASHBOARD_PRINT = 'PROGRAM_DASHBOARD_PRINT',
  PROJECT = 'PROJECT',
  PROJECT_CONTINGENCY_ALLOWANCE_REPORT = 'PROJECT_CONTINGENCY_ALLOWANCE_REPORT',
  PROJECT_CONTINGENCY_ALLOWANCE_REPORT_TYPE = 'PROJECT_CONTINGENCY_ALLOWANCE_REPORT_TYPE',
  PROJECT_CONTINGENCY_ALL_MILESTONES_REPORT = 'PROJECT_CONTINGENCY_ALL_MILESTONES_REPORT',
  PROJECT_DASHBOARD = 'PROJECT_DASHBOARD',
  PROJECT_EXPORT = 'PROJECT_EXPORT',
  PROJECT_IDEAS = 'PROJECT_IDEAS',
  PROJECT_IDEAS_IDEA = 'PROJECT_IDEAS_IDEA',
  PROJECT_IDEAS_INDEX = 'PROJECT_IDEAS_INDEX',
  PROJECT_ITEMS = 'PROJECT_ITEMS',
  PROJECT_ITEMS_ACTIVITY = 'PROJECT_ITEMS_ACTIVITY',
  PROJECT_ITEMS_INDEX = 'PROJECT_ITEMS_INDEX',
  PROJECT_ITEMS_ITEM = 'PROJECT_ITEMS_ITEM',
  PROJECT_ITEMS_ITEM_ESTIMATE = 'PROJECT_ITEMS_ITEM_ESTIMATE',
  PROJECT_ITEMS_ITEM_INDEX = 'PROJECT_ITEMS_ITEM_INDEX',
  PROJECT_MILESTONES = 'PROJECT_MILESTONES',
  PROJECT_MILESTONES_INDEX = 'PROJECT_MILESTONES_INDEX',
  PROJECT_MILESTONES_MILESTONE = 'PROJECT_MILESTONES_MILESTONE',
  PROJECT_MILESTONES_MILESTONE_COST_BREAKDOWN_REPORT = 'PROJECT_MILESTONES_MILESTONE_COST_BREAKDOWN_REPORT',
  PROJECT_MILESTONES_MILESTONE_IMPORT_ESTIMATE = 'PROJECT_MILESTONES_MILESTONE_IMPORT_ESTIMATE',
  PROJECT_MILESTONES_MILESTONE_MSR = 'PROJECT_MILESTONES_MILESTONE_MSR',
  PROJECT_MILESTONES_MILESTONE_TRENDING_COST_REPORT = 'PROJECT_MILESTONES_MILESTONE_TRENDING_COST_REPORT',
  PROJECT_PRINT = 'PROJECT_PRINT',
  PROJECT_REPORTS = 'PROJECT_REPORTS',
  PROJECT_REPORTS_INDEX = 'PROJECT_REPORTS_INDEX',
  PROJECT_REPORTS_REPORT = 'PROJECT_REPORTS_REPORT',
  PROJECT_REPORT_BACKCOMPAT = 'PROJECT_REPORT_BACKCOMPAT',
  PROJECT_REPORT_DISTRIBUTION_FILE = 'PROJECT_REPORT_DISTRIBUTION_FILE',
  PROJECT_REPORT_DISTRIBUTION_UNSUBSCRIBE = 'PROJECT_REPORT_DISTRIBUTION_UNSUBSCRIBE',
  PROJECT_SCENARIOS = 'PROJECT_SCENARIOS',
  PROJECT_SETTINGS = 'PROJECT_SETTINGS',
  PROJECT_SIGNUP = 'PROJECT_SIGNUP',
  PROJECT_SIGNUP_CONFIRM_COMPANY = 'PROJECT_SIGNUP_CONFIRM_COMPANY',
  PROJECT_SIGNUP_CREATE_COMPANY = 'PROJECT_SIGNUP_CREATE_COMPANY',
  PROJECT_SIGNUP_SETUP_USER = 'PROJECT_SIGNUP_SETUP_USER',
  PROJECT_TEAM = 'PROJECT_TEAM',
  PROJECT_TEAM_COMPANIES = 'PROJECT_TEAM_COMPANIES',
  PROJECT_TEAM_ROLES = 'PROJECT_TEAM_ROLES',
  PROJECT_TEAM_ROLES_ROLE_EDIT = 'PROJECT_TEAM_ROLES_ROLE_EDIT',
  PROJECT_TEAM_ROLES_ROLE_VIEW = 'PROJECT_TEAM_ROLES_ROLE_VIEW',
  PROJECT_TEAM_TEAMMATES = 'PROJECT_TEAM_TEAMMATES',
  PROJECT_TIMELINE = 'PROJECT_TIMELINE',
  PROJECT_VARIANCE = 'PROJECT_VARIANCE',
  RISKS = 'RISKS',
  RISKS_INDEX = 'RISKS_INDEX',
  RISK_DETAILS = 'RISK_DETAILS',
  ROOT = 'ROOT',
  SEARCH_ITEMS = 'SEARCH_ITEMS',
  SEARCH_PROJECTS = 'SEARCH_PROJECTS',
}

type IndexRoutePath = { index: true };
type NonIndexRoutePath = { path: string; children?: RoutePaths };
type RoutePath = IndexRoutePath | NonIndexRoutePath;

type RoutePaths = { [key in RouteKeys]?: RoutePath };

// 2. Define your path
const routePaths: RoutePaths = {
  [RouteKeys.APOLLO]: { path: '/apollo' },
  [RouteKeys.DEACTIVATED]: { path: '/deactivated' },
  [RouteKeys.NOT_FOUND]: { path: '/404' },
  [RouteKeys.EXPORT]: {
    path: '/export',
    children: {
      [RouteKeys.EXPORT_PROJECT]: {
        path: ':projectId',
        children: {
          [RouteKeys.EXPORT_PROJECT_ACTIVE_MILESTONE_CONTINGENCY]: { path: 'contingency-active-milestone-report/:reportID' },
          [RouteKeys.EXPORT_PROJECT_ALL_MILESTONES_CONTINGENCY]: { path: 'contingency-all-milestone-report/:reportID' },
          [RouteKeys.EXPORT_PROJECT_ITEMS_LIST]: { path: 'items' },
          [RouteKeys.EXPORT_PROJECT_MILESTONE_BUDGET]: { path: 'milestones/:milestoneId/budget' },
          [RouteKeys.EXPORT_PROJECT_MILESTONE_ESTIMATE]: { path: 'milestones/:milestoneId/estimate' },
          [RouteKeys.EXPORT_PROJECT_MSR]: { path: 'milestones/:milestoneId/costs' },
          [RouteKeys.EXPORT_PROJECT_COST_BREAKDOWN_REPORT]: { path: 'milestones/:milestoneId/cost-breakdown' },
          [RouteKeys.EXPORT_PROJECT_VARIANCE]: { path: 'variance/:reportID' },
        },
      },
    },
  },
  [RouteKeys.PRINT]: {
    path: '/print',
    children: {
      [RouteKeys.PRINT_PROJECT]: {
        path: ':projectId',
        children: {
          [RouteKeys.PRINT_PROJECT_BREAKDOWNS]: { path: 'breakdowns' },
          [RouteKeys.PRINT_PROJECT_CONTINGENCY_ALL_MILESTONES_REPORT]: { path: 'contingency-all-milestone-report' },
          [RouteKeys.PRINT_PROJECT_CONTINGENCY_ALLOWANCE_REPORT]: { path: 'contingency-allowance-report' },
          [RouteKeys.PRINT_PROJECT_COST_BREAKDOWN]: { path: 'milestones/:milestoneId/cost-breakdown' },
          [RouteKeys.PRINT_PROJECT_DASHBOARD_SUMMARY]: { path: 'dashboard/summary' }, // are these the same??
          [RouteKeys.PRINT_PROJECT_DASHBOARD]: { path: 'dashboard' }, // are these the same??
          [RouteKeys.PRINT_PROJECT_ITEM_DETAILS]: { path: 'items/:itemId' },
          [RouteKeys.PRINT_PROJECT_ITEMS_LIST_DETAILS]: { path: 'items-details' },
          [RouteKeys.PRINT_PROJECT_ITEMS_LIST]: { path: 'items' },
          [RouteKeys.PRINT_PROJECT_MSR_SUMMARY]: { path: 'milestones/:milestoneId/costs/summary' },
          [RouteKeys.PRINT_PROJECT_MSR]: { path: 'milestones/:milestoneId/costs' },
          [RouteKeys.PRINT_PROJECT_REPORT]: { path: '/report/:reportID' },
          [RouteKeys.PRINT_PROJECT_SCENARIOS]: { path: 'scenarios' },
          [RouteKeys.PRINT_PROJECT_VARIANCE]: { path: 'variance/:reportID?' },
        },
      },
    },
  },
  [RouteKeys.ROOT]: {
    path: '',
    children: {
      [RouteKeys.SEARCH_PROJECTS]: { path: '' },
      [RouteKeys.AUTHORIZE]: { path: 'authorize/:system' },
      [RouteKeys.SEARCH_ITEMS]: { path: 'items' },
      [RouteKeys.COMPANY]: {
        path: 'company',
        children: {
          [RouteKeys.COMPANY_EMPLOYEES]: {
            path: 'employees',
            children: {
              [RouteKeys.COMPANY_EMPLOYEES_INDEX]: { index: true },
              [RouteKeys.COMPANY_EMPLOYEES_USER]: { path: ':userId' },
            },
          },
          [RouteKeys.COMPANY_INSIGHTS_ALERTS]: { path: 'insights-alerts' },
          [RouteKeys.COMPANY_ORGANIZATIONS]: { path: 'organizations', children: { [RouteKeys.COMPANY_ORGANIZATIONS_ORGANIZATION]: { path: ':organizationId' } } },
          [RouteKeys.COMPANY_PARTNERS]: {
            path: 'partners',
            children: {
              [RouteKeys.COMPANY_PARTNERS_INDEX]: { index: true },
              [RouteKeys.COMPANY_PARTNERS_USER]: { path: ':userId' },
            },
          },
          [RouteKeys.COMPANY_PROJECT_STATS]: { path: 'project-stats' },
          [RouteKeys.COMPANY_SETTINGS]: { path: 'settings' },
          [RouteKeys.COMPANY_STANDARDS]: {
            path: 'standards',
            children: {
              [RouteKeys.COMPANY_STANDARDS_CATEGORIZATIONS]: { path: 'categorizations' },
              [RouteKeys.COMPANY_STANDARDS_PROJECT_TEMPLATES]: { path: 'project-templates' },
              [RouteKeys.COMPANY_STANDARDS_PROJECT_TYPES]: { path: 'project-types' },
            },
          },
        },
      },
      [RouteKeys.BENCHMARKING]: {
        path: 'benchmarking',
        children: {
          [RouteKeys.BENCHMARKING_EXPLORE]: { path: 'explore' },
          [RouteKeys.BENCHMARKING_REPORTS]: { path: 'reports' },
          [RouteKeys.BENCHMARKING_PROJECT_COMPS]: { path: 'project-comparison' },
          [RouteKeys.BENCHMARKING_PROJECT_COMPS_SAVED]: { path: 'project-comparison/:reportID' },
          [RouteKeys.BENCHMARKING_PROJECT_COMPS_PRINT]: { path: 'project-comparison/print' },
          [RouteKeys.BENCHMARKING_PROJECT_COMPS_SAVED_PRINT]: { path: 'project-comparison/:reportID/print' },
        },
      },
      [RouteKeys.INSIGHTS]: { path: 'insights', children: { [RouteKeys.INSIGHTS_PROJECT]: { path: ':projectId' } } },
      [RouteKeys.INSIGHTS_CLASSIC]: { path: 'insights-classic' },
      [RouteKeys.PROGRAM]: {
        path: 'program',
        children: {
          [RouteKeys.PROGRAM_DASHBOARD]: {
            path: ':programID',
          },
          [RouteKeys.PROGRAM_DASHBOARD_PRINT]: { path: ':programID/print' },
        },
      },
      [RouteKeys.PROJECT]: {
        path: ':projectId',
        children: {
          [RouteKeys.PROJECT_CONTINGENCY_ALL_MILESTONES_REPORT]: { path: 'contingency-all-milestone-report' },
          [RouteKeys.PROJECT_CONTINGENCY_ALLOWANCE_REPORT]: { path: 'contingency-allowance-report' },
          [RouteKeys.PROJECT_DASHBOARD]: { path: 'dashboard' },
          [RouteKeys.PROJECT_IDEAS]: {
            path: 'ideas',
            children: {
              [RouteKeys.PROJECT_IDEAS_INDEX]: { index: true },
              [RouteKeys.PROJECT_IDEAS_IDEA]: { path: ':ideaID' },
            },
          },
          [RouteKeys.PROJECT_ITEMS]: {
            path: 'items',
            children: {
              [RouteKeys.PROJECT_ITEMS_INDEX]: { index: true },
              [RouteKeys.PROJECT_ITEMS_ACTIVITY]: { path: 'item-activity' },
              [RouteKeys.PROJECT_ITEMS_ITEM]: {
                path: ':itemId',
                children: {
                  [RouteKeys.PROJECT_ITEMS_ITEM_INDEX]: { index: true },
                  [RouteKeys.PROJECT_ITEMS_ITEM_ESTIMATE]: { path: 'estimate' },
                },
              },
            },
          },
          [RouteKeys.PROJECT_EXPORT]: { path: 'export/*' },
          [RouteKeys.PROJECT_MILESTONES]: {
            path: 'milestones',
            children: {
              [RouteKeys.PROJECT_MILESTONES_INDEX]: { index: true },
              [RouteKeys.PROJECT_MILESTONES_MILESTONE]: {
                path: ':milestoneId',
                children: {
                  [RouteKeys.PROJECT_MILESTONES_MILESTONE_COST_BREAKDOWN_REPORT]: { path: 'project-cost-breakdown-report' },
                  [RouteKeys.PROJECT_MILESTONES_MILESTONE_IMPORT_ESTIMATE]: { path: 'import/:estimateId' },
                  [RouteKeys.PROJECT_MILESTONES_MILESTONE_MSR]: { path: 'costs' },
                  [RouteKeys.PROJECT_MILESTONES_MILESTONE_TRENDING_COST_REPORT]: { path: 'trending-cost-report' },
                },
              },
            },
          },
          [RouteKeys.PROJECT_PRINT]: { path: 'print/*' },
          [RouteKeys.PROJECT_REPORT_DISTRIBUTION_FILE]: { path: 'report-distribution-file/:assetId' },
          [RouteKeys.PROJECT_REPORT_DISTRIBUTION_UNSUBSCRIBE]: { path: 'report-distribution-unsubscribe/:reportDistributionId' },
          [RouteKeys.PROJECT_REPORT_BACKCOMPAT]: { path: 'report/:reportID' },
          [RouteKeys.PROJECT_REPORTS]: {
            path: 'reports',
            children: {
              [RouteKeys.PROJECT_REPORTS_INDEX]: { index: true },
              [RouteKeys.PROJECT_REPORTS_REPORT]: { path: ':reportID' },
            },
          },
          [RouteKeys.PROJECT_SCENARIOS]: { path: 'scenarios' },
          [RouteKeys.PROJECT_SETTINGS]: { path: 'settings' },
          [RouteKeys.PROJECT_SIGNUP]: {
            path: 'sign-up',
            children: {
              [RouteKeys.PROJECT_SIGNUP_CONFIRM_COMPANY]: { path: 'company/confirm' },
              [RouteKeys.PROJECT_SIGNUP_CREATE_COMPANY]: { path: 'company/create' },
              [RouteKeys.PROJECT_SIGNUP_SETUP_USER]: { path: 'user/setup' },
            },
          },
          [RouteKeys.PROJECT_TEAM]: {
            path: 'team',
            children: {
              [RouteKeys.PROJECT_TEAM_TEAMMATES]: { path: 'teammates' },
              [RouteKeys.PROJECT_TEAM_COMPANIES]: { path: 'companies' },
              [RouteKeys.PROJECT_TEAM_ROLES]: {
                path: 'roles',
                children: {
                  [RouteKeys.PROJECT_TEAM_ROLES_ROLE_VIEW]: { path: ':roleId' },
                  [RouteKeys.PROJECT_TEAM_ROLES_ROLE_EDIT]: { path: ':roleId/edit' },
                },
              },
            },
          },
          [RouteKeys.PROJECT_TIMELINE]: { path: 'timeline' },
          [RouteKeys.PROJECT_VARIANCE]: { path: 'variance' },
          [RouteKeys.RISKS]: {
            path: 'risks',
            children: {
              [RouteKeys.RISKS_INDEX]: { index: true },
              [RouteKeys.RISK_DETAILS]: { path: ':riskID' },
            },
          },
        },
      },
      [RouteKeys.SPLAT]: {
        // This is our fallback to the legacy style of routing.
        path: '*',
      },
    },
  },
};

// TODO: I dunno, is better types here worth it?
const flattenedPaths: { [key in RouteKeys]?: string } = {};

function addChildRoutes(route: NonIndexRoutePath) {
  if (!route.children) return;

  Object.entries(route.children ?? []).forEach((entry) => {
    const [childKey, child] = entry;

    if (isEnumValue(RouteKeys, childKey)) {
      if ('path' in child) {
        child.path = `${route.path}/${child.path}`;
        flattenedPaths[childKey] = child.path;
        addChildRoutes(child);
      }
    }
  });
}

Object.entries(routePaths).forEach(([key, value]) => {
  if (isEnumValue(RouteKeys, key) && 'path' in value) {
    flattenedPaths[key] = value.path;
    addChildRoutes(value);
  }
});

export default flattenedPaths;
