import { Help } from '@material-ui/icons';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { SortDirection } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { Tooltip } from '../Tooltip';

import styles from './TableHeaderStyles';
import { SortManager, TableHeader as TableHeaderType } from './types';

type Props = {
  classes: Classes<typeof styles>;
  dataCy: string;
  header: TableHeaderType;
  isCompact?: boolean;
  sortManager?: SortManager;
  top?: number;
};

const TableHeader = (props: Props) => {
  if (!props.header.copy && !props.header.component) {
    return <span className={props.classes.headerCell} style={{ top: props.top }} />;
  }

  if (!props.sortManager || !props.header.headerSortKey) {
    return (
      <div
        className={`${props.classes.cell} ${props.classes.headerCell} ${
          props.isCompact ? props.classes.headerCellCompact : props.classes.headerCellStandard
        } content-center`}
        data-cy={`table-header-${props.dataCy}`}
        style={{ top: props.top }}
      >
        <HeaderCellContent
          classes={props.classes}
          header={props.header}
          sortManager={props.sortManager}
        />
      </div>
    );
  }

  return (
    <button
      aria-label={`sort by ${props.header.copy}`}
      className={`${props.classes.cell} ${props.classes.headerCell} ${
        props.isCompact ? props.classes.headerCellCompact : props.classes.headerCellStandard
      }`}
      data-cy={`table-header-${props.dataCy}`}
      onClick={() => {
        let newSortDirection = SortDirection.SORT_ASCENDING;
        if (
          props.sortManager &&
          props.sortManager.sortState.sortKey === props.header.headerSortKey
        ) {
          newSortDirection =
            props.sortManager.sortState.sortDirection === SortDirection.SORT_ASCENDING
              ? SortDirection.SORT_DESCENDING
              : SortDirection.SORT_ASCENDING;
        }
        if (props.header.headerSortKey && props.sortManager) {
          props.sortManager.setSort({
            sortKey: props.header.headerSortKey,
            sortDirection: newSortDirection,
          });
        }
      }}
      style={{ top: props.top }}
    >
      <HeaderCellContent
        classes={props.classes}
        header={props.header}
        sortManager={props.sortManager}
      />
    </button>
  );
};

export default withStyles(styles)(TableHeader);

type HeaderCellContentProps = {
  classes: Classes<typeof styles>;
  header: TableHeaderType;
  sortManager?: SortManager;
};

function HeaderCellContent(props: HeaderCellContentProps) {
  const isActiveHeader = props.sortManager?.sortState.sortKey === props.header.headerSortKey;
  return (
    <div
      className={`flex items-center gap-[6px] ${props.header.isRightAligned ? 'justify-end text-right' : ''}`}
    >
      {props.header.component}
      {props.header.copy}
      {Boolean(props.header.tooltip) && (
        <Tooltip content={props.header.tooltip}>
          <div className="flex items-center text-type-muted icon-sm">
            <Help fontSize="inherit" />
          </div>
        </Tooltip>
      )}
      {props.header.copy && props.header.headerSortKey && props.sortManager && (
        <ArrowDownwardIcon
          classes={{
            root: `
                ${props.classes.iconSortArrow}
                ${isActiveHeader ? props.classes.iconSortArrowActive : ''}
                ${
                  isActiveHeader &&
                  props.sortManager.sortState.sortDirection === SortDirection.SORT_DESCENDING
                    ? props.classes.iconSortArrowFlipped
                    : ''
                }
              `,
          }}
        />
      )}
    </div>
  );
}
