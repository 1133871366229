import queryString from 'query-string';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { TermKey } from '../../api/gqlEnums';
import { ScheduleImpactFilter, TimelineActivityType } from '../../api/gqlEnumsBe';
import {
  ACCEPTED,
  ALL_ACTIVITIES,
  ALL_MILESTONES,
  INCORPORATED,
  ITEM_WITH_OPTIONS,
  NONE_DUE_DATE,
  NOT_APPLICABLE,
  NOT_CHOSEN,
  PENDING,
  REJECTED,
  SEARCH,
  SEPARATED_COST,
  STATUS,
  UNGROUPED,
} from '../../constants';
import {
  IntegrationFilterType,
  ItemsFilterBy,
  ItemsGroupKey,
  ItemsSortKey,
  Status,
  TimelineActivityOptionsQuery,
  Visibility,
  VisibilityView,
} from '../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import { RouteKeys } from '../../routes/paths';
import { getRangeDates } from '../../utilities/dates';
import { returnGroupByOptions } from '../../utilities/grouping';
import { getItemStatusLabel } from '../../utilities/item-status';
import { generateVisibleItemsCostImpactRange } from '../../utilities/items';
import { generateSharedPath } from '../../utilities/routes/links';
import { useProjectID } from '../../utilities/routes/params';
import { pluralizeCountString, pluralizeString } from '../../utilities/string';
import { useProjectProps } from '../contexts/project-props';
import { renderCostString } from '../CostReport/CostReportUtils';
import {
  FilterManager,
  SummaryValue,
  formatFilterSummary,
  getCategorizationsNameMap,
  restoreFilters,
} from '../FilterPanel/filterUtils';
import { isPrivateVisibility } from '../Items/ItemsUtils';
import { isScheduleSettingsDisabled } from '../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import { SelectEntry } from '../scales';
import { filterDisplayGroupBys } from '../shared-widgets/MultiGroup/MultiGroupOrderCategorizationUtils';
import useMemoWrapper from '../useMemoWrapper';

import { computeGroupByOptions } from './ItemsListSortBarUtils';
import { LIST } from './ItemsListViewToggle';
import { useCachedItemsListQuery, useItemsListQuery } from './useItemsListQuery';
import { emptyItemsTree, useCachedItemsTreeQuery, useItemsTreeQuery } from './useItemsTreeQuery';

type MessageProps = {
  status: string[];
};

export type ItemsListSettings = {
  activityID: string | null;
  assignee?: string;
  collapse: string[];
  creator: string[];
  contingencyDraws: string[];
  currentMilestone: [UUID | null, UUID | null];
  due?: string;
  filterText: string;
  expand: string[];
  groupBy: string[];
  isExpanded: boolean;
  scheduleImpacts: ScheduleImpactFilter[];
  search?: string;
  show: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  sort: any;
  status: Status[];
  view: string;
  viewFilter: string;
  visibility: Visibility[];
  dueState?: string;
  from?: RouteKeys;
  integrations: IntegrationFilterType[];
};

export const SCHEDULE_IMPACT_FILTER_KEY = 'scheduleImpacts';
export const CONTINGENCY_DRAW_FILTER_KEY = 'contingencyDraws';

export const ALL_STATUSES = [ACCEPTED, PENDING, REJECTED, INCORPORATED, NOT_APPLICABLE, NOT_CHOSEN];
export const FILTERED_STATUSES = [REJECTED, PENDING, ACCEPTED, INCORPORATED];
export const SCHEDULE_IMPACT_FILTERS = [
  ScheduleImpactFilter.TBD,
  ScheduleImpactFilter.NA,
  ScheduleImpactFilter.INCREASE,
  ScheduleImpactFilter.DECREASE,
  ScheduleImpactFilter.CRITICAL,
  ScheduleImpactFilter.NONCRITICAL,
];

export const SCHEDULE_IMPACT_FILTER_NAMES = {
  [ScheduleImpactFilter.TBD]: 'TBD',
  [ScheduleImpactFilter.NA]: 'N/A',
  [ScheduleImpactFilter.INCREASE]: 'Schedule increase',
  [ScheduleImpactFilter.DECREASE]: 'Schedule decrease',
  [ScheduleImpactFilter.CRITICAL]: 'Critical path',
  [ScheduleImpactFilter.NONCRITICAL]: 'Not critical path',
};

export const INTEGRATIONS_FILTER_KEY = 'integrations';
export const INTEGRATIONS_FILTERS = [
  IntegrationFilterType.INTEGRATION_FILTER_INCLUDE_CHANGE_EVENTS,
  IntegrationFilterType.INTEGRATION_FILTER_EXCLUDE_CHANGE_EVENTS,
];
export const INTEGRATIONS_FILTER_NAMES = new Map([
  [IntegrationFilterType.INTEGRATION_FILTER_INCLUDE_CHANGE_EVENTS, 'Items with Integration'],
  [IntegrationFilterType.INTEGRATION_FILTER_EXCLUDE_CHANGE_EVENTS, 'Items without Integration'],
]);

export const VISIBILITIES = [Visibility.PRIVATE_DRAFT, Visibility.PUBLISHED];

const itemsListStatusDefaults = Object.values(Status).filter((status) => status !== NOT_APPLICABLE);

// THESE ARE ALL THE POSSIBLE TERMS FOR THE ITEMS LIST
export const ITEMSLIST_DEFAULTS: ItemsListSettings = {
  activityID: null,
  assignee: undefined,
  collapse: [UNGROUPED],
  expand: [],
  creator: [],
  contingencyDraws: [],
  currentMilestone: [null, null], // will get patched by activeMilestone
  due: undefined,
  filterText: '',
  groupBy: [],
  isExpanded: false,
  integrations: INTEGRATIONS_FILTERS,
  scheduleImpacts: SCHEDULE_IMPACT_FILTERS,
  search: undefined,
  show: ITEM_WITH_OPTIONS,
  sort: ItemsSortKey.SORT_NUMBER,
  status: itemsListStatusDefaults,
  view: LIST,
  viewFilter: JSON.stringify({}),
  visibility: VISIBILITIES,
  dueState: undefined,
  from: undefined,
};

export const ITEMLIST_FILTER_DEFAULTS: Pick<
  ItemsListSettings,
  | 'scheduleImpacts'
  | 'show'
  | 'status'
  | 'creator'
  | 'visibility'
  | 'contingencyDraws'
  | 'integrations'
> = {
  scheduleImpacts: ITEMSLIST_DEFAULTS.scheduleImpacts,
  contingencyDraws: ITEMSLIST_DEFAULTS.contingencyDraws,
  integrations: ITEMSLIST_DEFAULTS.integrations,
  show: ITEMSLIST_DEFAULTS.show,
  status: ITEMSLIST_DEFAULTS.status,
  creator: ITEMSLIST_DEFAULTS.creator,
  visibility: ITEMSLIST_DEFAULTS.visibility,
};

// get a link to an item list. Default to showing items in all statuses
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const getItemListLink = (projectId: UUID, setState: any) => {
  const state = { ...ITEMSLIST_DEFAULTS, ...{ [STATUS]: ALL_STATUSES }, ...setState };
  const search = `?${queryString.stringify(state, { arrayFormat: 'index' })}`;
  return generateSharedPath(RouteKeys.PROJECT_ITEMS, { projectId, search });
};

// HERE'S THE CURRENT LIST OF KEYWORDS WE SEEK IN FILTERTEXT:
const keywords = ['search', 'due', 'assignee'];

export const generateEmptyMessage = ({ status }: MessageProps) => {
  const isFiltered = JSON.stringify(status) !== JSON.stringify(FILTERED_STATUSES);
  let message = 'Nothing here.';
  if (isFiltered) {
    message = 'No items, but there may be options matching this filter that are not shown.';
  }
  return message;
};

export const getCollapse = (id: string, collapse: string[] = [], expand: string[] = []) =>
  !(expand.includes(id) || (!collapse.includes(id) && !collapse.includes(UNGROUPED)));

const getItemUnits = (totalCount: number, hasItemsAndOptions: boolean, connector = 'and') =>
  hasItemsAndOptions
    ? `${pluralizeCountString('item', totalCount)} ${connector} ${pluralizeString(
        'option',
        totalCount
      )}`
    : pluralizeCountString('item', totalCount);

export type VisibleItemInformation = {
  costItemIDs: UUID[];
  milestoneIDs: UUID[];
  totalCountItemsAndOptions: number;
  totalCountItemsOnly: number;
  visibleCost: Cost;
  visibleCount: number;
};

export const getBranchDisplayName = (displayName: string, t: TermStore) => {
  if (displayName !== SEPARATED_COST) return displayName;
  return `Separated ${t.titleCase(TermKey.MARKUP)}`;
};

export const getVisibleItemInformation = (
  itemsTree: ItemsTree,
  itemsList: ItemsListItem[],
  itemsListMap: Map<UUID, ItemsListItem>
): VisibleItemInformation => {
  const checkedItemLikes = new Set<UUID>();
  const milestoneIDs: UUID[] = [];
  const totalCountItemsAndOptions = itemsList.length;
  const totalCountItemsOnly = itemsList.filter((itemLike) => !itemLike.parent).length;
  const visibleCosts: Cost[] = [];
  const costItemIDs: UUID[] = [];
  const visibleCount = itemsTree.totalItems;

  const accumulateVisibleItem = (visibleItem: ItemsListItem) => {
    if (!isPrivateVisibility(visibleItem.visibility)) {
      visibleCosts.push(visibleItem.cost);
      costItemIDs.push(visibleItem.id);
    }
    if (!milestoneIDs.includes(visibleItem.milestone.id)) {
      milestoneIDs.push(visibleItem.milestone.id);
    }
    checkedItemLikes.add(visibleItem.id);
  };

  const orderedItemLikeIDs = itemsTree.orderedItemIDs;
  const orderedOptionIDs: UUID[] = [];

  // Add Items and Items With Options first
  orderedItemLikeIDs.forEach((itemLikeID) => {
    if (checkedItemLikes.has(itemLikeID)) return;
    const itemLike = itemsListMap.get(itemLikeID);
    if (!itemLike) return;
    if (itemLike.parent) {
      orderedOptionIDs.push(itemLikeID);
      return;
    }
    accumulateVisibleItem(itemLike);
  });
  // Add Options only if their parent hasn't been added
  orderedOptionIDs.forEach((optionID) => {
    if (checkedItemLikes.has(optionID)) return;
    const option = itemsListMap.get(optionID);
    const parentID = option?.parent?.id;
    if (parentID && checkedItemLikes.has(parentID)) return;
    if (option) accumulateVisibleItem(option);
  });

  const visibleCost = generateVisibleItemsCostImpactRange(visibleCosts);
  return {
    costItemIDs,
    milestoneIDs,
    totalCountItemsAndOptions,
    totalCountItemsOnly,
    visibleCost,
    visibleCount,
  };
};

export const getVisibleItemInformationTextForDisplay = (
  hasItemsAndOptions: boolean,
  visibleInfo: VisibleItemInformation,
  canViewCost: boolean
) => {
  const { totalCountItemsAndOptions, totalCountItemsOnly, visibleCost, visibleCount } = visibleInfo;
  const totalCount = hasItemsAndOptions ? totalCountItemsAndOptions : totalCountItemsOnly;
  const itemUnits = getItemUnits(totalCount, hasItemsAndOptions);
  const roundedCost = renderCostString({
    cost: visibleCost,
    isWide: true,
    isSigned: true,
  });
  const text = `${visibleCount} of ${itemUnits} shown`;
  if (!canViewCost) return text;
  return `${text}, total: ${roundedCost}`;
};

export const getSummaryText = (
  displayItemsAndOptions: boolean,
  canViewCosts: boolean,
  itemsLikeIDsSelectedCount: number,
  visibleItemsInfo: VisibleItemInformation,
  _loading: boolean
) =>
  itemsLikeIDsSelectedCount === 0
    ? getVisibleItemInformationTextForDisplay(
        displayItemsAndOptions,
        visibleItemsInfo,
        canViewCosts
      )
    : summarizedItemsLikeSelected(displayItemsAndOptions, itemsLikeIDsSelectedCount);

export const getVisibleItemInformationTextForTooltip = (
  hasItemsAndOptions: boolean,
  visibleInfo: VisibleItemInformation
) => {
  const { totalCountItemsAndOptions, totalCountItemsOnly, visibleCost, visibleCount } = visibleInfo;
  const totalCount = hasItemsAndOptions ? totalCountItemsAndOptions : totalCountItemsOnly;
  const itemUnits = getItemUnits(totalCount, hasItemsAndOptions);
  const exactCost = renderCostString({
    cost: visibleInfo.visibleCost,
    isWide: true,
    isExact: true,
  });
  const roundedCost = renderCostString({
    cost: visibleCost,
    isWide: true,
  });
  return `${visibleCount} of ${itemUnits} shown, total:\n${exactCost} (exact cost)\n${roundedCost} (rounded cost)\n`;
};

export const getSummaryTooltipText = (
  displayItemsAndOptions: boolean,
  itemsLikeIDsSelectedCount: number,
  visibleItemsInfo: VisibleItemInformation,
  _loading: boolean
) =>
  itemsLikeIDsSelectedCount === 0
    ? getVisibleItemInformationTextForTooltip(displayItemsAndOptions, visibleItemsInfo)
    : summarizedItemsLikeSelected(displayItemsAndOptions, itemsLikeIDsSelectedCount);

export const summarizedItemsLikeSelected = (hasItemsAndOptions: boolean, itemLikeCount: number) =>
  `${getItemUnits(itemLikeCount, hasItemsAndOptions, 'or')} selected`;

function stringAppend(object: string, add: string) {
  let newString = object ? `${object} ${add}` : add;
  const { length } = newString;
  if (newString.substring(length) === ' ') newString = newString.substring(0, length);
  return newString;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const fixDueNone = (parsedStrings: any) => {
  const fixed = parsedStrings;
  if (parsedStrings.due === 'none') fixed.due = NONE_DUE_DATE;
  return fixed;
};

export const parseFilterText = (filterText: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const parsedStrings: any = { filterText };
  keywords.forEach((keyword: string) => {
    parsedStrings[keyword] = undefined;
  });
  // we should parse the filterText for terms here, and write it to settings all at once
  let lastKeyword: string = SEARCH;
  filterText
    .toLowerCase()
    .split(' ')
    .forEach((term: string) => {
      // does the term match a keyword? then set keyword with first term...
      const matchedKeyword = keywords.find((keyword: string) => {
        const { length } = keyword;
        return term.substring(0, length + 1) === `${keyword}:`; // looking for colon format
      });
      if (matchedKeyword) {
        lastKeyword = matchedKeyword;
        const { length } = lastKeyword;
        parsedStrings[lastKeyword] = stringAppend(
          parsedStrings[lastKeyword],
          term.substring(length + 1) // add the text after the new keyword
        );
      } else {
        parsedStrings[lastKeyword] = stringAppend(parsedStrings[lastKeyword], term);
      }
    });
  return fixDueNone(parsedStrings);
};

export const formatSettingsForExport = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any,
  creators: { id: UUID; name: string }[],
  categorizations: Categorization[]
): SummaryValue[] => {
  const summary: SummaryValue[] = [];
  const hasSearch = settings.search && settings.search.length > 0;
  const hasStatusFilters = settings.status && settings.status.length > 0;
  const hasCreatorFilters = settings.creator && settings.creator.length > 0;
  const hasViewFilter = settings.viewFilter && settings.viewFilter.length > 2;

  if (hasSearch) summary.push({ header: 'Search', value: settings.search });

  if (hasStatusFilters)
    summary.push({
      header: 'Status',
      value: settings.status.map((s: string) => getItemStatusLabel(s)).join(', '),
    });

  if (hasCreatorFilters) {
    const filteredCollaborators = creators.filter(
      (c: { id: UUID; name: string }) => c.id && settings.creator.includes(c.id)
    );

    summary.push({
      header: pluralizeString('Creator', filteredCollaborators.length),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      value: filteredCollaborators.map((c: any) => c.name).join(', '),
    });
  }

  if (hasViewFilter) {
    const { filters } = restoreFilters(settings.viewFilter);
    const categorizationsNameMap = getCategorizationsNameMap(categorizations);
    return [...summary, ...formatFilterSummary(filters, categorizationsNameMap, 50, 1)];
  }
  return summary;
};

const getItemsGroupBy = (
  groupByString: string,
  hasGroupByItems: boolean,
  isAllMilestones: boolean
) => {
  if (hasGroupByItems) return ItemsGroupKey.GROUP_CATEGORY;
  switch (groupByString) {
    // In case of a new case, please update isGroupedByCategory
    case 'Status':
      return ItemsGroupKey.GROUP_STATUS;
    case 'Visibility':
      return ItemsGroupKey.GROUP_VISIBILITY;
    case 'Share settings':
      return ItemsGroupKey.GROUP_VISIBILITY;
    // In case of a new case, please update isGroupedByCategory
    case 'Creator':
      return ItemsGroupKey.GROUP_CREATOR;
    case 'Assignee':
      return ItemsGroupKey.GROUP_ASSIGNEE;
    case 'Meeting':
      return isAllMilestones ? null : ItemsGroupKey.GROUP_MEETING;
    case 'Milestone':
      return isAllMilestones ? ItemsGroupKey.GROUP_MILESTONE : null;
    case 'Schedule impact':
      return ItemsGroupKey.GROUP_SCHEDULE_IMPACT;
    // In case of a new case, please update isGroupedByCategory
    default:
      return null;
  }
};

export const getItemsSortBy = (
  sortBy: ItemsSortKey,
  categorizations: Categorization[]
): ItemsSortBy => {
  if (!sortBy) return { sortKey: ItemsSortKey.SORT_NUMBER };
  switch (sortBy) {
    case ItemsSortKey.SORT_COST_IMPACT:
    case ItemsSortKey.SORT_CREATION_TIME:
    case ItemsSortKey.SORT_CREATOR:
    case ItemsSortKey.SORT_DATE_MODIFIED:
    case ItemsSortKey.SORT_COST_IMPACT_MAGNITUDE:
    case ItemsSortKey.SORT_DUE_DATE:
    case ItemsSortKey.SORT_NUMBER:
    case ItemsSortKey.SORT_SCHEDULE_IMPACT:
    case ItemsSortKey.SORT_STATUS:
    case ItemsSortKey.SORT_VISIBILITY:
      return { sortKey: sortBy };
    default:
      break;
  }
  const categorization = categorizations.find((categorization) => categorization.name === sortBy);
  if (categorization)
    return { sortKey: ItemsSortKey.SORT_CATEGORY, categorizationID: categorization.id };
  return { sortKey: ItemsSortKey.SORT_NUMBER };
};

export const useItemsTree = (
  filterManager: FilterManager,
  settings: ItemsListSettings,
  excludeDraftItems = false,
  deprecatedCacheOnly = false
): { itemsTree: ItemsTree; loading: boolean; isEmpty: boolean; refetch: () => void } => {
  const projectId = useProjectID();
  if (!projectId) throw new Error('Project ID not found');

  const {
    data: { project },
    loading: projectPropsLoading,
  } = useProjectPropsQuery(projectId);
  const activeMilestoneId = project?.activeMilestone.id;
  const { data: projectCategorizationsData, loading: projectCategorizationsLoading } =
    useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(projectCategorizationsData);

  const { filterQueryInput: viewFilter } = filterManager;
  const {
    activityID,
    assignee,
    creator,
    currentMilestone,
    due,
    groupBy,
    integrations,
    scheduleImpacts,
    contingencyDraws,
    search,
    show,
    sort,
    status,
    visibility,
  } = settings;

  const filteredMilestoneId =
    (currentMilestone && currentMilestone[0]) || activeMilestoneId || ALL_MILESTONES;
  const milestoneId = (filteredMilestoneId !== ALL_MILESTONES && filteredMilestoneId) || null;
  const meetingId = currentMilestone?.length === 2 ? currentMilestone[1] : null;
  const isAllMilestones = filteredMilestoneId === ALL_MILESTONES;

  const [dueDateStart, dueDateEnd] = getRangeDates(due);

  // GROUP BY - translate the settings from strings to groupings
  // We split up categorization levels as options
  // TODO: Cleanup how we go from ItemsListSettings to DisplayGroupBy[]
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();
  const groupByOptions = useMemoWrapper(
    computeGroupByOptions,
    categorizations,
    isScheduleImpactEnabled,
    filteredMilestoneId
  );
  const displayGroupBy = returnGroupByOptions(groupBy, groupByOptions) || [];
  const groupBys = filterDisplayGroupBys(displayGroupBy);
  const groupByString = groupBy && !!groupBy.length ? groupBy[0] : '';

  const itemsGroupBy = getItemsGroupBy(groupByString, !!groupBys.length, isAllMilestones);
  const itemsSortBy = getItemsSortBy(sort, categorizations);
  const itemsFilter: ItemsFilterBy = {
    creators: creator.filter((c: string) => !!c),
    scheduleImpacts,
    contingencyDraws,
    statuses: status,
    showOptions: show === ITEM_WITH_OPTIONS,
    searchText: assignee || search || '',
    dueDateStart,
    dueDateEnd,
    visibilities: excludeDraftItems
      ? visibility.filter((v) => v !== Visibility.PRIVATE_DRAFT)
      : visibility,
    integrations: integrations ?? [], // if the integrations prop doesn't exist in local storage, because these settings were created before it was introduced then we need to provide a default value.
  };

  const standardQuery = useItemsTreeQuery(
    itemsSortBy,
    itemsFilter,
    itemsGroupBy,
    milestoneId,
    activityID === ALL_ACTIVITIES ? null : activityID,
    projectId,
    meetingId,
    viewFilter,
    groupBys,
    projectPropsLoading || projectCategorizationsLoading || deprecatedCacheOnly
  );

  const cachedQuery = useCachedItemsTreeQuery(
    itemsSortBy,
    itemsFilter,
    itemsGroupBy,
    milestoneId,
    activityID === ALL_ACTIVITIES ? null : activityID,
    projectId,
    meetingId,
    viewFilter,
    groupBys,
    !deprecatedCacheOnly
  );

  const { data, loading, refetch } = deprecatedCacheOnly ? cachedQuery : standardQuery;
  const isEmpty = !data?.itemsTree;
  const itemsTree = data?.itemsTree ?? emptyItemsTree;

  return { itemsTree, loading, refetch, isEmpty };
};

export const useItemsList = (
  filterManager: Pick<FilterManager, 'filterQueryInput'>,
  settings: ItemsListSettings,
  excludePrivateItems: boolean,
  loadItemCosts: boolean,
  deprecatedCacheOnly?: boolean
) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');
  const {
    data: { project },
    loading: projectPropsLoading,
  } = useProjectPropsQuery(projectId);
  const activeMilestoneId = project?.activeMilestone.id;

  const { filterQueryInput: viewFilter } = filterManager;
  const { currentMilestone, activityID, show = ITEM_WITH_OPTIONS, integrations } = settings;

  const filteredMilestoneId =
    (currentMilestone && currentMilestone[0]) || activeMilestoneId || ALL_MILESTONES;
  const milestoneId = (filteredMilestoneId !== ALL_MILESTONES && filteredMilestoneId) || null;
  const visibilityView = excludePrivateItems
    ? VisibilityView.PUBLIC_VIEW
    : VisibilityView.HYBRID_VIEW;

  const standardQuery = useItemsListQuery(
    milestoneId,
    activityID === ALL_ACTIVITIES ? null : activityID,
    projectId,
    show === ITEM_WITH_OPTIONS, // showOptions
    viewFilter,
    visibilityView,
    integrations,
    loadItemCosts,
    projectPropsLoading || deprecatedCacheOnly
  );

  const cachedQuery = useCachedItemsListQuery(
    milestoneId,
    activityID === ALL_ACTIVITIES ? null : activityID,
    projectId,
    show === ITEM_WITH_OPTIONS, // showOptions
    viewFilter,
    visibilityView,
    integrations,
    loadItemCosts,
    !deprecatedCacheOnly
  );

  const { data, loading, refetch } = deprecatedCacheOnly ? cachedQuery : standardQuery;
  const itemsList = useMemo(() => data?.itemsList.items ?? [], [data?.itemsList]);
  const itemsListMap = useMemo(() => {
    const itemsMap = new Map<UUID, ItemsListItem>();
    itemsList.forEach((item: ItemsListItem) => itemsMap.set(item.id, item));
    return itemsMap;
  }, [itemsList]);

  return { itemsList, loading, itemsListMap, refetch };
};

export const useItemsListMilestone = (settings: ItemsListSettings): UUID | undefined => {
  const activeMilestoneId = useProjectProps()?.activeMilestone.id;
  const { currentMilestone } = settings;
  const filteredMilestoneId =
    (currentMilestone && currentMilestone[0]) || activeMilestoneId || ALL_MILESTONES;
  const milestoneID = (filteredMilestoneId !== ALL_MILESTONES && filteredMilestoneId) || undefined;
  return milestoneID;
};

const getSelectEntry = (id: string, label: string) => ({ id, label });

type TimelineActivity = TimelineActivityOptionsQuery['timeline']['activities'][number];

export const getActivitySelectEntries = (activities: TimelineActivity[]): SelectEntry[] => {
  const all = getSelectEntry(ALL_ACTIVITIES, ALL_ACTIVITIES);
  const phases = activities
    .filter((a) => a.type === TimelineActivityType.PHASE)
    .map((a) => getSelectEntry(a.id, a.name));
  const milestones = activities
    .filter((a) =>
      [TimelineActivityType.MILESTONE, TimelineActivityType.ACTIVE_MILESTONE].includes(a.type)
    )
    .map((a) => getSelectEntry(a.id, a.name));
  const events = activities
    .filter((a) => a.type === TimelineActivityType.EVENT)
    .map((a) => getSelectEntry(a.id, a.name));

  const entries: SelectEntry[] = [all];

  if (phases.length > 0) {
    entries.push({
      isSection: true,
      entries: phases,
      id: TimelineActivityType.PHASE.toString(),
      label: 'Phases',
    });
  }
  if (milestones.length > 0) {
    entries.push({
      isSection: true,
      entries: milestones,
      id: TimelineActivityType.MILESTONE.toString(),
      label: 'Milestones',
    });
  }
  if (events.length > 0) {
    entries.push({
      isSection: true,
      entries: events,
      id: TimelineActivityType.EVENT.toString(),
      label: 'Events',
    });
  }
  return entries;
};
