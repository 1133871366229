import Icon from '../Icon/Icon';
import TextInput from '../TextInput/TextInput';

export default function FilterTextInput(props: {
  onChange: (value: string) => void;
  onEscape: () => void;
  value: string;
}) {
  return (
    <div className="bg-background-primary p-2">
      <TextInput
        aria-label="enter text to filter entries"
        autoFocus
        data-cy="filter-text-input"
        onChange={props.onChange}
        onClear={() => props.onChange('')}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            props.onEscape();
          }
        }}
        placeholder="Enter text to filter entries..."
        startAdornment={<Icon name="search" />}
        value={props.value}
      />
    </div>
  );
}
