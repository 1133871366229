type Props = {
  children: string | number;
  /** How far in from the edge of the relative parent the badge should be positioned.
   *
   * Defaults to right = 8 and top = 8 */
  inset?: {
    right?: number;
    top?: number;
  };
};

export default function Badge(props: Props) {
  const right = props.inset?.right ?? 8;
  const top = props.inset?.top ?? 8;

  return (
    <div
      className="absolute z-50 flex h-5 min-w-[20px] items-center justify-center rounded-full bg-badge-notification px-1 text-type-secondary type-body3"
      data-cy="badge"
      style={{
        top: 0 - top,
        right: 0 - right,
      }}
    >
      {props.children}
    </div>
  );
}
