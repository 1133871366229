import { ReactElement } from 'react';

import {
  defaultToastValue,
  setReactiveLocal,
  toastParametersVar,
} from '../api/apollo/reactiveVars';
import { ToastType } from '../api/gqlEnums';
import { TOAST_VAR } from '../constants';

type ToastData = {
  icon?: ReactElement | undefined;
  message: string | JSX.Element;
};

export function setToast(
  data: ToastData | null = null,
  type: ToastParameters['type'] = ToastType.DEFAULT,
  item: ToastParameters['item'] = null
) {
  const { icon, message = '' } = data || {};
  const newValue = { icon, message, type, item, show: true } as ToastParameters;
  toastParametersVar(newValue);
}

export function closeToast() {
  // some toasts occur accross page loads so we need to clear the stored
  // value of the reactive variable
  setReactiveLocal(toastParametersVar, TOAST_VAR, { ...defaultToastValue });
}
