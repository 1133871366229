import { commonCostReportColumnFields, gql } from '../../../api/graphqlFragments';

export const minimalCostReportsQuery = gql`
  query minimalCostReports(
    $milestones: [CostReportMilestoneInput!]!
    $columns: [CostReportColumnInput!]!
    $groupByItems: [GroupByItem!]!
    $costMode: CostMode!
    $projectID: UUID!
    $viewFilter: ViewFilter!
    $useDraftEstimate: Boolean!
    $useDraftBudget: Boolean!
  ) {
    milestoneCostReports(
      parameters: {
        milestones: $milestones
        columns: $columns
        groupByItems: $groupByItems
        costMode: $costMode
        projectID: $projectID
        viewFilter: $viewFilter
        useDraftEstimate: $useDraftEstimate
        useDraftBudget: $useDraftBudget
      }
    ) {
      milestoneID
      costReportColumns {
        ...commonCostReportColumnFields
      }
    }
  }
  ${commonCostReportColumnFields}
`;
