import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';

import joinAPI from '../api/joinAPI';

const JoinAuthContext = createContext<JoinAuth | null>(null);

type JoinUser = {
  auth0: string;
  createdAt: string;
  didSetup: boolean;
  email: string;
  emailVerificationHashes: string[];
  id: string;
  isEmailVerified: boolean;
  jobTitle: string;
  name: string;
  phone: string;
  picture: string;
  resentEmailVerificationAt: string;
  signupAt: string;
  status: string;
  updatedAt: string;
};

export interface JoinAuth extends Auth0ContextInterface {
  isLoading: boolean;
  loggedIn: boolean;
  joinUser: JoinUser | null;
}

type Props = {
  children: ReactNode;
};

export default function JoinAuthProvider(props: Props) {
  const auth = useAuth0();
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoadingJoinUser, setIsLoadingJoinUser] = useState(false);
  const [joinUser, setJoinUser] = useState<JoinUser | null>(null);

  // Checking for this value allows us to skip the user profile fetch when running integration tests
  const skip = localStorage.getItem('skip_user_profile_fetch');

  useEffect(() => {
    if (!skip && auth.isAuthenticated) {
      setIsLoadingJoinUser(true);

      // I would much rather we did not need to do this - however our grid controllers
      // are written outside of the React ecosystem, so cannot easily access the value
      // of this provider.
      // auth.getAccessTokenSilently().then((accessToken) => {
      //   localStorage.setItem('access_token', accessToken);
      // });
      joinAPI
        .requestLogin(auth)
        .then((response) => {
          const authUser = JSON.parse(response.body);
          setLoggedIn(true);
          setJoinUser({
            auth0: authUser.Auth0,
            createdAt: authUser.CreatedAt,
            didSetup: authUser.DidSetup,
            email: authUser.Email,
            emailVerificationHashes: authUser.EmailVerificationHashes,
            id: authUser.ID,
            isEmailVerified: authUser.IsEmailVerified,
            jobTitle: authUser.JobTitle,
            name: authUser.Name,
            phone: authUser.Phone,
            picture: authUser.Picture,
            resentEmailVerificationAt: authUser.ResentEmailVerificationAt,
            signupAt: authUser.SignupAt,
            status: authUser.Status,
            updatedAt: authUser.UpdatedAt,
          });
        })
        .finally(() => setIsLoadingJoinUser(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [auth.isAuthenticated]);

  const value: JoinAuth = {
    ...auth,
    isLoading: auth.isLoading || isLoadingJoinUser,
    loggedIn,
    joinUser,
  };

  // If we are running integration testing we set these values to bypass authentication
  if (skip) {
    value.isAuthenticated = true;
    value.isLoading = false;
    value.loggedIn = true;
    value.isLoading = false;
    const userProfile = localStorage.getItem('user_profile');
    value.joinUser = userProfile && JSON.parse(userProfile);
  }

  return (
    <JoinAuthContext.Provider value={value satisfies JoinAuth}>
      {props.children}
    </JoinAuthContext.Provider>
  );
}

export function useJoinAuth() {
  const value = useContext(JoinAuthContext);

  // We throw here if there's no auth data since it allows us to return a non-nullable value.
  // This is safe as long as every user of this is a descendent of JoinAuthProvider
  if (!value) throw new Error('no auth context');

  return value;
}
