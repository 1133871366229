import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import {
  ACCEPTED,
  COSTS,
  HIDE,
  PENDING,
  STATUS,
  TOTAL,
  UNGROUPED,
  VIEW_FILTER,
  VIEW_OPTIONS,
} from '../../../constants';
import { NS_OWNER_COSTS_V2 } from '../../../features';
import { CostReportColumnKey } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import {
  categoryDefaultLevelNames,
  makeDefaultLevelNames,
  makeOptionsFromCategorizations,
} from '../../../utilities/categorization';
import { useCostModePermissions } from '../../../utilities/costMode';
import { returnGroupByOptions } from '../../../utilities/grouping';
import { UsePermissions } from '../../../utilities/permissions/types';
import { usePersistentStates } from '../../../utilities/urlState';
import { useFilterManager } from '../../FilterPanel/filterUtils';
import { useSortedUnits } from '../../Milestone/hooks/UnitHooks';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { filterDisplayGroupBys } from '../../shared-widgets/MultiGroup/MultiGroupOrderCategorizationUtils';
import useMemoWrapper from '../../useMemoWrapper';
import {
  ColumnDescription,
  buildCostReportDisplayColumnDescriptions,
  columnsT,
  defaultColumnKeys,
  getColumnDescriptionsByKeys,
  getColumnDescriptionsT,
  getExpressionsFromUnits,
} from '../CostReportColumns/CostReportColumns';
import {
  DEPRECATED_DEFAULTS,
  transformMSRSettings,
} from '../CostReportList/CostReportList/CostReportListUtils';

export const COSTREPORT_PARAMS = {
  collapse: 'collapse',
  columns: 'columns',
  expand: 'expand',
  groupBy: 'groupBy',
  status: STATUS,
  viewMode: 'viewMode',
  estimateLines: 'estimateLines',
};

export const COSTREPORT_DEFAULTS: MilestoneCostReportSettings = {
  groupBy: categoryDefaultLevelNames,
  viewMode: VIEW_OPTIONS.CHART_AND_LIST,
  collapse: [UNGROUPED], // start collapsed
  columns: defaultColumnKeys,
  status: [PENDING, ACCEPTED],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  expand: [] as any[],
  estimateLines: HIDE,
  [VIEW_FILTER]: JSON.stringify({}),
  subcolumns: [TOTAL] as string[],
  metrics: [] as string[],
  types: [],
};

export const generateCostReportStorageParam = (milestoneId: string) =>
  `Milestone ${milestoneId} Cost Report `;

const useCostReportParams = (
  permissions: UsePermissions,
  categorizations: Categorization[],
  defaultCategorizations: Categorization[],
  milestoneName: string,
  page: string,
  enabledUnits: Unit[],
  ownStorageParam?: string,
  activeMilestoneID?: UUID,
  isVariance = false
) => {
  // Terminology
  const t = useContext(ProjectTermStore);
  const { includeOwnerCosts } = useCostModePermissions(permissions);
  const hasOwnerCostFeature = useHasFeature(NS_OWNER_COSTS_V2);

  const columnMap = columnsT(t, includeOwnerCosts && hasOwnerCostFeature);
  const defaultGroupBy = useMemoWrapper(makeDefaultLevelNames, defaultCategorizations);
  const transformCostReportDefaults = {
    ...COSTREPORT_DEFAULTS,
    ...DEPRECATED_DEFAULTS,
    groupBy: defaultGroupBy,
  };

  const params = useParams();
  const milestoneId = activeMilestoneID || params.milestoneId;
  if (!milestoneId) {
    throw new Error('Milestone ID not found');
  }

  // We group by the estimate's categorizations as a default setting, but allow the user to override.
  // LOCAL "STATE" SETUP from URL and localStorage per milestone

  const transformSettings = transformMSRSettings(enabledUnits);

  const [settings, setSettings] = usePersistentStates<
    CostReportSettings,
    TransformMilestoneCostReportSettings
  >(
    window.location,
    page || COSTS,
    transformCostReportDefaults,
    ownStorageParam || generateCostReportStorageParam(milestoneId),
    transformSettings,
    ''
  );

  // SettingValue -- create a type from the setting values of TransformMilestoneCostReportSettings
  type SettingValue = string | string[] | undefined;

  const setSetting = (param: string, value: SettingValue) => {
    const resetChanges = [COSTREPORT_PARAMS.groupBy, COSTREPORT_PARAMS.status];
    const resetCollapse = resetChanges.includes(param) ? { collapse: [], expand: [] } : {};
    setSettings({ [param]: value, ...resetCollapse });
  };

  const filterManager = useFilterManager(
    settings[VIEW_FILTER],
    (newValue: string) => setSetting(VIEW_FILTER, newValue),
    permissions
  );

  const { columns, groupBy } = settings;

  const { units } = useSortedUnits(true);
  const expressions = getExpressionsFromUnits(settings, units);
  const columnDescriptions = getColumnDescriptionsT(t, includeOwnerCosts && hasOwnerCostFeature)(
    milestoneName,
    isVariance,
    expressions
  );
  // ColumnKey Correction
  // Columns will be either a column key, or include a column key (per-unit)
  const columnKeys = Array.from(columnMap.keys());
  const filtered =
    columns && columns.length
      ? columns.filter((c: string) =>
          columnKeys.some((k: CostReportColumnKey) => {
            const column = columnMap.get(k);
            return column && c.includes(column);
          })
        )
      : [];
  if (filtered.length < 0) {
    setSetting(COSTREPORT_PARAMS.columns, COSTREPORT_DEFAULTS.columns);
  }
  const { displayColumnDescriptions, headerDescriptions } =
    buildCostReportDisplayColumnDescriptions(
      columns as CostReportColumnKey[],
      columnDescriptions,
      isVariance,
      settings,
      expressions
    );

  const varianceKey = CostReportColumnKey.VARIANCE_KEY;
  if (
    isVariance &&
    !displayColumnDescriptions.find(
      (column: ColumnDescription) => column && column.columnKey === CostReportColumnKey.VARIANCE_KEY
    )
  ) {
    displayColumnDescriptions.push(
      ...getColumnDescriptionsByKeys(
        [{ columnKey: varianceKey, parentColumnKey: varianceKey }],
        columnDescriptions
      )
    );
  }

  // GroupBy
  // We split up categorization levels as options
  const options = makeOptionsFromCategorizations(categorizations);
  // We match the simple string to a complex option with level explanation for the tree
  // we also need to scrub for valid option ids
  const displayGroupBy = filterDisplayGroupBys(returnGroupByOptions(groupBy ?? [], options));

  return {
    categorizations,
    columnDescriptions,
    displayColumnDescriptions,
    displayGroupBy,
    headerDescriptions,
    filterManager,
    page,
    setSetting,
    setSettings,
    settings,
  };
};

export default useCostReportParams;
