import { useRef } from 'react';
import { useHover, useOption } from 'react-aria';
import { ListState, Node } from 'react-stately';

import Checkbox from '../Checkbox/Checkbox';
import Chip from '../Chip/Chip';
import Icon from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';

import { SelectEntry, SelectEntryID } from './types';

// Shared component for rendering entry content (label, description, badge)
export const EntryContent = ({
  label,
  badge,
  description,
  children,
  labelProps,
}: {
  label: string;
  badge?: SelectEntry['badge'];
  description?: SelectEntry['description'];
  children?: React.ReactNode;
  className?: string;
  labelProps?: React.HTMLAttributes<HTMLElement>;
}) => (
  <div className="flex flex-1 items-center gap-2">
    <div className="flex flex-col gap-0.5">
      <div {...labelProps} className="py-0.5 type-body1">
        {label}
      </div>
      {description && <div className="py-0.5 type-label">{description}</div>}
    </div>
    {badge && <Chip text={badge} />}
    {children}
  </div>
);

export type EntryProps = {
  entry: Node<SelectEntry>;
  onHover?: (entryID: UUID) => void;
  onViewChildren?: (parentID: SelectEntryID) => void;
  onViewParent?: () => void;
  state: ListState<SelectEntry>;
  className?: string;
};

export default function Entry(props: EntryProps) {
  const ref = useRef(null);

  const { optionProps, labelProps, isSelected, isDisabled } = useOption(
    { key: props.entry.key },
    props.state,
    ref
  );

  const { hoverProps } = useHover({
    onHoverStart: () => props.onHover && props.entry.value && props.onHover(props.entry.value.id),
  });

  const value = props.entry.value;
  if (!value) {
    throw Error('Failed to get value from entry.');
  }

  const hasChildren = value.hasChildren && Boolean(props.onViewChildren);

  return (
    <li
      className={`flex w-full items-center gap-2 overflow-hidden bg-background-primary ${
        props.onViewParent ? 'border-b border-border-muted' : ''
      }`}
      {...hoverProps}
    >
      {props.onViewParent && (
        <IconButton
          aria-label="go up a level"
          icon={<Icon name="keyboard_arrow_left" />}
          onClick={props.onViewParent}
          type="secondary"
        />
      )}
      <div
        {...optionProps}
        ref={ref}
        className={[
          'flex flex-grow items-center gap-2 p-2 outline-none transition focus-visible:bg-selection-hover',
          isSelected ? 'bg-selection-selected' : 'bg-background-primary',
          isDisabled
            ? 'cursor-not-allowed text-type-inactive'
            : 'cursor-pointer text-type-primary hover:bg-selection-hover',
          hasChildren ? 'rounded-e-full' : '',
          props.onViewParent ? 'rounded-s-full' : '',
          props.className || '',
        ].join(' ')}
      >
        {props.state.selectionManager.selectionMode === 'multiple' && (
          <Checkbox
            aria-label="entry is selected"
            isDisabled={isDisabled}
            isSelected={isSelected}
          />
        )}
        {value.startAdornment}
        <EntryContent
          badge={value.badge}
          description={value.description}
          label={value.label}
          labelProps={labelProps}
        />
        {value.endAdornment}
      </div>
      {hasChildren && (
        <IconButton
          aria-label={`see children for ${value.label}`}
          data-cy="children-button"
          icon={<Icon name="keyboard_arrow_right" />}
          onClick={() => {
            props.onViewChildren?.(value.id);
          }}
          type="secondary"
        />
      )}
    </li>
  );
}
