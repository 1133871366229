import { get, groupBy } from 'lodash';

import { CompanyRoleName } from '../api/gqlEnums';
import {
  BoolMap,
  ToCollaborator,
  TooltipMap,
} from '../components/dragon-scales/Share/ShareDialog/types';
import { NULL_ID } from '../constants';
import {
  AccessLevel,
  GetSharedResourceQuery,
  Org,
  OrgNode,
  SharedResource,
} from '../generated/graphql';

import { isNonNullable } from './types';

export function insert<T>(arr: readonly T[], index: number, newArr: readonly T[]) {
  return [...arr.slice(0, index), ...newArr, ...arr.slice(index)];
}

export const isNullID = (id: UUID | null | undefined): boolean =>
  id === NULL_ID || id === null || !id;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const replaceQueries = (refetchQueries: any[], queries: any[]) => {
  let refetchQs = refetchQueries;
  queries.forEach((q) => {
    const queryName = ((((q.query || {}).definitions || [])[0] || {}).name || {}).value;
    refetchQs = refetchQs.filter((q) => q !== queryName);
    refetchQs = [...refetchQs, q];
  });
  return refetchQs;
};

type FlatTree = {
  id: UUID;
  name: string;
  parentID?: UUID | null;
};

export type TreeEntry<T> = T & {
  id: UUID;
  label: string;
  depth: number;
  entries?: TreeEntry<T>[] | undefined;
};

type OrgNodesByOrg = Record<string, { name: string; nodes: TreeEntry<OrgNode>[] }>;

function mapEntry<T extends FlatTree>(
  entry: T,
  groupedByParents: _.Dictionary<T[]>,
  depth = 0
): TreeEntry<T> {
  return {
    ...entry,
    label: entry.name,
    depth,
    entries: groupedByParents[entry.id]?.map((e) => mapEntry(e, groupedByParents, depth + 1)),
  };
}

export function makeTree<T extends FlatTree>(data: T[]): TreeEntry<T>[] {
  const groupedByParents = groupBy(data, 'parentID');
  const tree = (groupedByParents.null || []).map((entry) => mapEntry(entry, groupedByParents));
  return tree;
}

export function flatten<T>(entries: TreeEntry<T>[]) {
  const flat: TreeEntry<T>[] = [];
  const flattenChildren = (entry: TreeEntry<T>) => {
    flat.push(entry);
    (entry.entries ?? []).forEach(flattenChildren);
  };
  entries.forEach((entry) => flattenChildren(entry));
  return flat;
}

export function getTreeChildrenById<T>(
  entries: TreeEntry<T>[],
  parentNodeID: UUID
): TreeEntry<T>[] {
  const result: TreeEntry<T>[] = [];
  function findChildren(nodes: TreeEntry<T>[]): void {
    const node = nodes.find((n) => n.id === parentNodeID);
    if (node) {
      if (node.entries) {
        result.push(...node.entries);
      }
    } else {
      nodes.map((n) => n.entries && findChildren(n.entries));
    }
  }

  findChildren(entries);
  return result;
}

export function collectEntriesByDepth<T>(
  nodes: TreeEntry<T>[]
): { depth: number; nodes: TreeEntry<T>[] }[] {
  const depthMap: { depth: number; nodes: TreeEntry<T>[] }[] = [];

  function traverse(nodeArray: TreeEntry<T>[]) {
    nodeArray.forEach((node) => {
      const depth = node.depth + 1;
      if (!depthMap[depth]) {
        depthMap[depth] = { depth, nodes: [] };
      }
      depthMap[depth].nodes.push(node);

      if (node.entries) {
        traverse(node.entries);
      }
    });
  }

  traverse(nodes);

  return depthMap.filter((entry) => entry !== undefined);
}

// Returns the smallest subtree of entries containing the given ids.
export function getSubtree<T>(entries: TreeEntry<T>[], ids: string[]) {
  const getChildren = (entry: TreeEntry<T>): TreeEntry<T>[] => {
    const children = (entry.entries ?? []).flatMap((it) => getChildren(it));
    if (ids.includes(entry.id) || children.length > 0) {
      return [
        {
          ...entry,
          entries: children,
        },
      ];
    }
    return [];
  };
  return entries.flatMap((entry) => getChildren(entry));
}

// Accepts a subtree of nodes and organizes by its respective Organizations
export function mapOrgNodesByOrg(orgs: Org[], subtree: TreeEntry<OrgNode>[]): OrgNodesByOrg {
  const acc: OrgNodesByOrg = {};
  return orgs.reduce((acc, org) => {
    const orgSubtree = subtree.filter((node) =>
      org.nodes.some((orgNode) => orgNode.id === node.id)
    );
    if (orgSubtree.length > 0) {
      acc[org.id] = { name: org.name, nodes: orgSubtree };
    }
    return acc;
  }, acc);
}

// Helper function to recursively collect nodeIDs from the tree
export const collectNodeIDs = (nodes: TreeEntry<OrgNode>[]): Set<string> => {
  const nodeIDs = new Set<string>();

  nodes.forEach((node) => {
    nodeIDs.add(node.id);
    if (node.entries && node.entries.length > 0) {
      const nestedIDs = collectNodeIDs(node.entries);
      nestedIDs.forEach((id) => nodeIDs.add(id));
    }
  });

  return nodeIDs;
};
function anyChildrenSelected<T extends TreeEntry<object>>(selectedEntries: UUID[], clicked: T) {
  const childrenIds = (clicked.entries || []).map(({ id }) => id) as UUID[];
  const selected = selectedEntries.filter((id) => !childrenIds.includes(id));
  const isAnyChildrenSelected = selected.length !== selectedEntries.length;
  const isAllChildrenSelected =
    childrenIds.length && selected.length + childrenIds.length === selectedEntries.length;
  return { isAnyChildrenSelected, isAllChildrenSelected };
}

export function onSelectLevels<T extends TreeEntry<object>>(entry: T, selectedEntries: UUID[]) {
  const isCurrentlySelected = selectedEntries.find((e) => e === entry.id) !== undefined;
  const { isAnyChildrenSelected, isAllChildrenSelected } = anyChildrenSelected(
    selectedEntries,
    entry
  );
  const childrenIds = flatten(entry.entries ?? []).map(({ id }) => id);
  let filtered: UUID[] = [];
  if (isCurrentlySelected) {
    if (isAllChildrenSelected) {
      filtered = selectedEntries.filter((v) => v !== entry.id);
      filtered = filtered.filter((id) => !childrenIds.includes(id));
    } else {
      filtered = selectedEntries.filter((v) => v !== entry.id);
    }
  } else if (!isCurrentlySelected) {
    if (isAnyChildrenSelected) {
      filtered = [...selectedEntries, entry.id];
    } else {
      const newIds = [entry.id, ...childrenIds];
      filtered = [...selectedEntries, ...newIds];
    }
  }
  return filtered;
}

export function idsToNames<T extends FlatTree>(ids: UUID[], entries: T[]): string[] {
  return ids
    ?.map((identifier) => entries.find(({ id }) => identifier === id)?.name)
    .filter(isNonNullable);
}

export function isMac() {
  return window.navigator.platform.toLowerCase().includes('mac');
}

export function sortToTop<T extends { id: UUID }>(entries: T[], topIds: UUID[]): T[] {
  const top: T[] = [];
  const bottom: T[] = [];
  entries.forEach((entry) => {
    if (topIds.includes(entry.id)) {
      top.push(entry);
    } else {
      bottom.push(entry);
    }
  });
  return [...top, ...bottom];
}

export function onlyUnique(value: string, index: number, array: string[]) {
  return array.indexOf(value) === index;
}

export const filterCompanyAdmins = (users: CompanyUser[] | undefined) =>
  (users || []).filter((user) => user.role?.name === CompanyRoleName.Admin);

export function hasSharedAccess(resources: SharedResource[], userID: UUID | undefined) {
  const hasArr = resources.map((resource) => {
    const accessLevels = resource?.accessLevels || [];
    return !!(resource?.users || [])
      .filter((_, index) => accessLevels[index] !== AccessLevel.NONE)
      .filter(({ id }) => id === userID)[0];
  });
  return hasArr.every((hasAccess) => hasAccess);
}

export function getSharedIds(
  resources: SharedResource[],
  collaborators: ToCollaborator[],
  currentID: UUID
) {
  const resourceCounts = new Map<UUID, number>();
  const disabledIds = new Set<UUID>();
  const collaboratorIdMap = new Map<UUID, UUID>(collaborators.map((c) => [c.user.id, c.id]));
  resources.forEach(({ users }) => {
    users.forEach((user) => {
      const count = resourceCounts.get(user.id) ?? 0;
      resourceCounts.set(user.id, count + 1);
    });
  });
  disabledIds.add(currentID);

  const sharedIds: UUID[] = [];
  const indeterminateMap: BoolMap = {};
  const tooltipMap: TooltipMap = {};
  resourceCounts.forEach((count, userID) => {
    const collaboratorID = collaboratorIdMap.get(userID);
    if (!collaboratorID) return;
    if (count === resources.length) {
      sharedIds.push(collaboratorID);
    }
    if (count > 0 && count < resources.length) {
      indeterminateMap[collaboratorID] = true;
      tooltipMap[collaboratorID] =
        `User has access to ${count} of ${resources.length} selected draft items`;
    }
  });
  return [sharedIds, Array.from(disabledIds), indeterminateMap, tooltipMap] as const;
}

export const getUserIds = (collaboratorIDs: UUID[], collaborators: ToCollaborator[]): UUID[] =>
  collaboratorIDs
    .map((id) =>
      get(
        collaborators.find((c) => c.id === id),
        'user.id'
      )
    )
    .filter(isNonNullable);

export function getShareResourcesUpdate(
  newCollaboratorIDs: UUID[],
  removedCollaboratorIDs: UUID[],
  collaborators: ToCollaborator[]
) {
  const addedUserIDs = getUserIds(newCollaboratorIDs, collaborators);
  const removedUserIDs = getUserIds(removedCollaboratorIDs, collaborators);
  const accessLevelsAdded: AccessLevel[] = new Array(addedUserIDs.length).fill(AccessLevel.DEFAULT);
  const accessLevelsRemoved: AccessLevel[] = new Array(removedUserIDs.length).fill(
    AccessLevel.NONE
  );
  const userIDs = [...addedUserIDs, ...removedUserIDs];
  const accessLevels = [...accessLevelsAdded, ...accessLevelsRemoved];
  return { userIDs, accessLevels };
}

export function toSharedResourceUsers(data: GetSharedResourceQuery | undefined) {
  return data?.getSharedResource?.resource?.users ?? [];
}

export function hasSharedResourceAccess(
  data: GetSharedResourceQuery | undefined,
  userID: UUID | undefined | null
) {
  const sharedUsers = toIds(toSharedResourceUsers(data));
  const hasSharedResourceAccess = sharedUsers.includes(userID ?? '');
  return hasSharedResourceAccess;
}

type Id = {
  id: UUID;
};

export function toIds<T extends Id>(arr: T[]) {
  return arr.map(({ id }) => id);
}

export function mapOrder<T extends Id>(array: T[] | undefined, order: UUID[]) {
  return [...(array ?? [])].sort((a, b) => {
    let weightA = 0;
    let weightB = 0;

    if (!order.includes(a.id)) {
      weightA += 100;
    }
    if (!order.includes(b.id)) {
      weightB += 100;
    }
    return order.indexOf(a.id) + weightA - (order.indexOf(b.id) + weightB);
  });
}
