import { ReactNode, forwardRef, useRef } from 'react';
import { AriaButtonProps, mergeProps, useButton, useFocusRing } from 'react-aria';

import composeRefs from '@seznam/compose-react-refs';

import Badge from '../Badge/Badge';
import { TooltipTriggerProps, pickTooltipTriggerProps } from '../Tooltip';
import { validateDataCy } from '../utils/data-cy';

type Props = TooltipTriggerProps & {
  'aria-label': string;
  badge?: string | number;
  'data-cy'?: string;
  icon: ReactNode;
  isDisabled?: boolean;
  onClick?: AriaButtonProps['onPress'];
  type: 'primary' | 'secondary' | 'destructive';
};

export default forwardRef<HTMLButtonElement, Props>(function Button(props, forwardedRef) {
  const ref = useRef(null);

  const { isFocusVisible, focusProps } = useFocusRing();

  const { buttonProps } = useButton(
    {
      isDisabled: Boolean(props.isDisabled),
      onPress: props.onClick,
      'aria-label': props['aria-label'],
    },
    ref
  );

  const classes = [
    'flex relative grow-0 shrink-0 h-10 w-10 icon-md items-center justify-center gap-1 rounded-full transition type-button',
  ];

  if (props.type === 'primary') {
    classes.push(
      'bg-button-primary text-type-secondary border-2 border-button-primary',
      'hover:enabled:bg-button-primary-hover hover:enabled:border-button-primary-hover',
      isFocusVisible ? 'outline' : 'outline-none',
      'disabled:text-type-inactive disabled:bg-button-inactive disabled:border-button-inactive'
    );
  } else if (props.type === 'secondary') {
    classes.push(
      'bg-button-icon-bg text-type-primary',
      'hover:enabled:bg-button-icon-hover',
      isFocusVisible ? 'outline' : 'outline-none',
      'disabled:text-type-inactive disabled:border-button-inactive'
    );
  } else if (props.type === 'destructive') {
    classes.push(
      'bg-button-icon-bg text-button-destructive',
      'hover:enabled:bg-button-destructive-hover',
      isFocusVisible ? 'outline outline-button-destructive' : 'outline-none',
      'disabled:text-type-inactive disabled:border-button-inactive'
    );
  }

  validateDataCy(props['data-cy'], 'button');

  return (
    <button
      {...mergeProps(pickTooltipTriggerProps(props), buttonProps, focusProps)}
      ref={composeRefs(ref, forwardedRef)}
      className={[...classes].join(' ')}
      data-cy={props['data-cy']}
    >
      {props.icon}
      {props.badge !== undefined && props.badge !== '' ? (
        <Badge inset={{ right: 4 }}>{props.badge}</Badge>
      ) : null}
    </button>
  );
});
