import { TermKey } from '../../api/gqlEnums';
import { getMarkupModePermissionLabel } from '../../components/TeamRoute/RolesRoute/table/permissions';
import { CostDisplay, MarkupMode, PermissionResource } from '../../generated/graphql';
import { UsePermissions } from '../permissions/types';

export type ProjectsCostModes = Record<UUID, CostMode>;

export const DefaultCostMode = {
  markupMode: MarkupMode.ALLOCATED_MARKUPS,
  costDisplay: CostDisplay.SHOW_COSTS,
  includeOwnerCosts: false,
};
Object.freeze(DefaultCostMode);

export const getProjectCostsDisplayLabel = (costMode: CostMode, t: TermStore) => {
  if (costMode.includeOwnerCosts) {
    return t.sentenceCase(TermKey.PROJECT_TOTAL);
  }
  return t.sentenceCase(TermKey.COST_OF_CONSTRUCTION);
};

export const checkCostModeIncludesMarkups = (costMode: CostMode) =>
  costMode.markupMode !== MarkupMode.NO_MARKUPS;

export const getMarkupModeLabel = (markupMode: MarkupMode, t: TermStore) => {
  switch (markupMode) {
    case MarkupMode.ALLOCATED_MARKUPS:
      return getMarkupModePermissionLabel(PermissionResource.ALLOCATED_MARKUPS_VIEW, t);
    case MarkupMode.SEPARATED_MARKUPS:
      return getMarkupModePermissionLabel(PermissionResource.SEPARATED_MARKUPS_VIEW, t);
    case MarkupMode.NO_MARKUPS:
      return getMarkupModePermissionLabel(PermissionResource.NO_MARKUPS_VIEW, t);
    default:
      return getMarkupModePermissionLabel(PermissionResource.ALLOCATED_MARKUPS_VIEW, t);
  }
};

export const getAvailableCostModes = (canView: UsePermissions['canView'], loading: boolean) => {
  const availableCostDisplays: CostDisplay[] = [];
  const availableMarkupModes: MarkupMode[] = [];
  if (loading) return { availableCostDisplays, availableMarkupModes, loading };

  availableCostDisplays.push(CostDisplay.HIDE_COSTS);
  if (canView(PermissionResource.DISPLAY_COSTS)) availableCostDisplays.push(CostDisplay.SHOW_COSTS);

  if (canView(PermissionResource.ALLOCATED_MARKUPS_VIEW))
    availableMarkupModes.push(MarkupMode.ALLOCATED_MARKUPS);
  if (canView(PermissionResource.SEPARATED_MARKUPS_VIEW))
    availableMarkupModes.push(MarkupMode.SEPARATED_MARKUPS);
  if (canView(PermissionResource.NO_MARKUPS_VIEW)) availableMarkupModes.push(MarkupMode.NO_MARKUPS);

  return {
    availableCostDisplays,
    availableMarkupModes,
    loading,
  };
};

export const getCostModePermissions = (
  permissions: UsePermissions,
  projectsCostModes: ProjectsCostModes,
  projectID?: UUID,
  hasOwnerCostFeature?: boolean
) => {
  if (!projectID) {
    // We are outside of a project route or we need a default cost mode
    return {
      // DefaultCostMode is a locked object.
      // To avoid a runtime error if a user of the cost mode API intends to change the resulting object,
      // we create a new unlocked object.
      // This also makes it consistent with the case where we have a project ID and the resulting object is unlocked.
      ...DefaultCostMode,
    };
  }

  // We are inside a project route or we need a cost mode for the project ID
  const { canView, loading } = permissions;
  const canViewOwnerCosts = canView(PermissionResource.OWNER_COSTS);
  let markupMode = DefaultCostMode.markupMode;
  let costDisplay = DefaultCostMode.costDisplay;
  let includeOwnerCosts = canViewOwnerCosts;

  if (projectsCostModes[projectID]) {
    markupMode = projectsCostModes[projectID].markupMode;
    costDisplay = projectsCostModes[projectID].costDisplay ?? DefaultCostMode.costDisplay;
    if (hasOwnerCostFeature)
      includeOwnerCosts =
        projectsCostModes[projectID].includeOwnerCosts ?? DefaultCostMode.includeOwnerCosts;
  }
  // Update CostMode if you don't have access to the current value
  // NOTE: there are a couple of reasons we need to check permissions in order
  // to change the cost mode: a) when accessing a project for the first time, b)
  // when previewing a user/role, and c) your permissions have been changed
  // since the last time you accessed the project. In the future, we should see
  // if there is a better place to handle this logic, so it doesn't need to be
  // baked into all the places we call useCostMode.
  const { availableCostDisplays, availableMarkupModes } = getAvailableCostModes(canView, loading);
  if (!loading) {
    if (Boolean(availableMarkupModes.length) && !availableMarkupModes.includes(markupMode))
      markupMode = availableMarkupModes[0];
    if (Boolean(availableCostDisplays.length) && !availableCostDisplays.includes(costDisplay))
      costDisplay = availableCostDisplays[0];
  }
  // check permissions for viewing owner costs
  if (!canViewOwnerCosts && includeOwnerCosts)
    includeOwnerCosts = DefaultCostMode.includeOwnerCosts;
  return {
    markupMode,
    costDisplay,
    includeOwnerCosts,
  };
};
