import { TermKey } from '../../../../api/gqlEnums';
import {
  CATEGORIZED,
  NULL_ID,
  SEPARATED_COST,
  SORT_ITEM_NUMBERS,
  TOTAL,
} from '../../../../constants';
import {
  CostReportColumnKey,
  CostReportColumnType,
  MarkupContribution,
  MarkupDisplayType,
} from '../../../../generated/graphql';
import { getCurrencySymbol } from '../../../../utilities/currency';
import { getDateString } from '../../../../utilities/dates';
import { nonZeroCost } from '../../../../utilities/items';
import { sortItemsBy } from '../../../../utilities/sorting';
import { CollapseSettings } from '../../../../utilities/urlState';
import { hasActiveFilter } from '../../../FilterPanel/filterUtils';
import { getCollapse } from '../../../ItemsList/ItemsListUtils';
import { VarianceReportComments } from '../../../ReportsTab/ReportHooks';
import {
  getCostReportByType,
  getMarkupTotal,
  getSegmentedMarkupTotals,
  getSegmentedOwnerTotals,
  getUserReportCommentLineID,
} from '../../CostReportUtils';
import CostReportCategoriesTree, {
  CostReportCategoriesTreeNode,
  NodeData,
  addCounts,
  getNextBranches,
} from '../CostReportCategoriesTree';

type ItemColumnCost = {
  columnInput?: CostReportColumnInput;
  columnKey?: CostReportColumnKey;
  categorizedItemCost: MilestoneCostReportColumnReport['categorizedItemCosts'][number];
  itemLike?: ItemLike;
};

export type ColumnMarkupCost = {
  columnInput: CostReportColumnInput;
  columnKey: CostReportColumnKey;
  markupContribution: MarkupContribution;
};

const nonZeroStringOrNumber = (cost: string | number) => {
  if (typeof cost === 'number') {
    return cost !== 0;
  }
  if (typeof cost === 'string') {
    return cost !== '0';
  }
  return false;
};

const nonZeroAddDeductCost = (cost: AddDeductCost) =>
  nonZeroStringOrNumber(cost && cost.adds) || nonZeroStringOrNumber(cost && cost.deducts);

const nonZeroNumericValue = (cost: Numeric) => nonZeroStringOrNumber(cost);

const nonZeroCostReportQueryItem = (subtotal: ReportColumnSubtotals[number]) =>
  nonZeroCost((subtotal.subtotalValue as SubtotalCost).range) ||
  nonZeroAddDeductCost((subtotal.subtotalValue as SubtotalAddsDeducts).segmented) ||
  nonZeroNumericValue((subtotal.subtotalValue as SubtotalNumeric).value);

export const getCategorizedText = (isCategorized?: boolean) => {
  if (isCategorized === undefined) return '';
  return isCategorized ? CATEGORIZED : TOTAL;
};

export const getUnitText = (
  unitAbbreviation: string | undefined,
  isMetric: boolean,
  isCategorized?: boolean
) => {
  const categorizedUnitText = `${getCategorizedText(isCategorized)}${unitAbbreviation}`;
  if (isMetric) {
    return categorizedUnitText;
  }
  return `${getCurrencySymbol()}/${categorizedUnitText}`;
};

export const getNonCategorizedUnitText = (unitText: string) =>
  unitText.replace(CATEGORIZED, '').replace(TOTAL, '');

export const getBaseUnitText = (unitText: string) =>
  unitText.replace(`${getCurrencySymbol()}/`, '');

export const getColumnKey = (
  unitID?: UUID,
  key?: CostReportColumnKey,
  unitText?: string,
  isMetric = false,
  isCategorized = false
) => {
  if (!key) return undefined;
  if (isMetric) return getMetricColumnKey(key, isCategorized, unitText);

  const suffix = unitText && unitID && getBaseUnitText(unitText);
  return `${key}${suffix ? `_${suffix}` : ''}` as CostReportColumnKey;
};

export const getMetricColumnKey = (
  key: CostReportColumnKey | undefined,
  isCategorized: boolean,
  unitText?: string
) => {
  let columnKey = key;
  if (key === CostReportColumnKey.VARIANCE_KEY || key === CostReportColumnKey.VARIANCEMETRIC_KEY) {
    columnKey = CostReportColumnKey.VARIANCEMETRIC_KEY;
  } else if (isCategorized) {
    columnKey = CostReportColumnKey.CATEGORIZEDMETRIC_KEY;
  } else {
    columnKey = CostReportColumnKey.METRIC_KEY;
  }
  return `${columnKey}${unitText ? `_${unitText}` : ''}` as CostReportColumnKey;
};

export const getNonUnitColumnKey = (columnKey: CostReportColumnKey) => {
  const parts = columnKey.split('_');
  if (parts.length >= 3) {
    return {
      nonUnitColumnKey: parts.slice(0, 2).join('_') as CostReportColumnKey,
      unitAbbrev: getNonCategorizedUnitText(parts[2]),
    };
  }
  return { nonUnitColumnKey: columnKey, unitAbbrev: '' };
};

export const isNonUnitColumnKey = (key: CostReportColumnKey) => {
  const { nonUnitColumnKey } = getNonUnitColumnKey(key);
  return nonUnitColumnKey === key;
};

export const metricColumnKeys: CostReportColumnKey[] = [
  CostReportColumnKey.METRIC_KEY,
  CostReportColumnKey.CATEGORIZEDMETRIC_KEY,
  CostReportColumnKey.VARIANCEMETRIC_KEY,
];
export const isMetricKey = (columnKey: CostReportColumnKey) => {
  const { nonUnitColumnKey } = getNonUnitColumnKey(columnKey);
  return metricColumnKeys.includes(nonUnitColumnKey as CostReportColumnKey);
};

export const isTotalMetricKey = (columnKey: CostReportColumnKey) =>
  isMetricKey(columnKey) && !keyIsCategorized(columnKey);

export const keyIsCategorized = (s: string) => s.includes(CATEGORIZED);

export const getMilestoneColumnKey = (columnKey: CostReportColumnKey, milestoneID?: UUID) =>
  `${getNonCategorizedUnitText(columnKey)}${milestoneID ? `_${milestoneID}` : ''}`;

export const insertTreeSubtotals = (
  tree: CostReportCategoriesTree,
  columnInput: CostReportColumnInput,
  columnKey: CostReportColumnKey,
  subtotals: ReportColumnSubtotals,
  isFiltered: boolean,
  isVarianceReport: boolean,
  varianceReportComments?: VarianceReportComments
) => {
  if (subtotals && subtotals.length) {
    subtotals.forEach((subtotal) => {
      if (isFiltered && !nonZeroCostReportQueryItem(subtotal)) return;
      const { categories, subtotalValue } = subtotal;
      const { directCostRange, range } = subtotalValue as SubtotalCost;
      const { directCostSegmented, segmented } = subtotalValue as SubtotalAddsDeducts;
      const { value } = subtotalValue as SubtotalNumeric;

      if (
        !isVarianceReport &&
        categories[0].id === NULL_ID &&
        !nonZeroCostReportQueryItem(subtotal)
      )
        return;
      const commentLineID = getUserReportCommentLineID(subtotal.categories);
      const comment = varianceReportComments?.subtotalComments?.find(
        (c) => c.commentLineID === commentLineID
      );
      // get any children comments from items
      const childComments = [
        ...(varianceReportComments?.itemComments || []),
        ...(varianceReportComments?.itemLineComments || []),
      ].filter((c) => c.commentLineID.startsWith(commentLineID));
      tree.insert(
        {
          categories,
          columnKey,
          directCostRange,
          directCostSegmented,
          range,
          segmented,
          numeric: value,
        },
        columnInput,
        false,
        comment,
        childComments
      );
    });
  }
};

const isAddsDeductsKey = (columnKey: CostReportColumnKey | undefined) => {
  if (!columnKey) return false;
  return columnKey.includes('ADDS') || columnKey.includes('DEDUCTS');
};

const isZeroCostReportQueryItem = ({ range }: { range: Cost }) => {
  return !nonZeroCost(range);
};

const getNonZeroVarianceSubtotals = (columns: ReportColumn[]) => {
  let nonZeroSubs: ReportColumnSubtotals = [];
  const varianceColumn = columns.filter(
    (column) => column.type === CostReportColumnType.VARIANCE
  )[0];
  if (!varianceColumn) return nonZeroSubs;
  const { report } = varianceColumn;
  if (report) {
    nonZeroSubs = report.subtotals.filter((subtotal) => nonZeroCostReportQueryItem(subtotal));
  }
  return nonZeroSubs;
};

const categoriesMatch = (cats: CategoryWithoutLevels[], nonZeroCats: CategoryWithoutLevels[]) =>
  cats.every((category) => nonZeroCats.includes(category));

const getNonZeroVarianceItemColumnCosts = (itemColumnCosts: ItemColumnCost[]) => {
  const zeroVarianceItemColumnCosts = itemColumnCosts.filter((itemColumnCost) => {
    const { categorizedItemCost } = itemColumnCost;
    return isZeroCostReportQueryItem(categorizedItemCost);
  });
  const nonZeroVarianceItemColumnCosts = itemColumnCosts.filter((itemColumnCost) => {
    const {
      categorizedItemCost: { categories, id },
    } = itemColumnCost;
    const match = zeroVarianceItemColumnCosts.some((zeroVarianceItemColumnCost) => {
      const {
        categorizedItemCost: { categories: zeroItemCategories, id: zeroItemID },
      } = zeroVarianceItemColumnCost;
      return categoriesMatch(zeroItemCategories, categories) && id === zeroItemID;
    });
    return !match;
  });
  return nonZeroVarianceItemColumnCosts;
};

export const insertTreeItemCosts = (
  tree: CostReportCategoriesTree,
  itemColumnCosts: ItemColumnCost[],
  hideEstimateLines?: boolean,
  itemStatuses?: string[],
  viewFilter?: ViewFilterInput,
  varianceReportComments?: VarianceReportComments | undefined
) => {
  itemColumnCosts.forEach(({ categorizedItemCost, columnInput, columnKey }: ItemColumnCost) => {
    const { canViewCosts, range, status, categories, itemContributionLines } = categorizedItemCost;
    const hasSelectedStatus = itemStatuses && itemStatuses.some((s: string) => s === status);

    const { categories: categoryFilter = [], uncategorizedCategorizationIDs = [] } =
      viewFilter ?? {};

    const hasFilters = !!categoryFilter.length || !!uncategorizedCategorizationIDs.length;
    const isItemCategoryIncluded =
      hasFilters &&
      !!categorizedItemCost.categories.filter(
        (c) =>
          !!categoryFilter?.find((f) => f.id === c.id) ||
          (uncategorizedCategorizationIDs?.includes(c.categorization?.id ?? '') && c.id === NULL_ID)
      ).length;

    const shouldInclude = !hasFilters || isItemCategoryIncluded;

    if (hasSelectedStatus && shouldInclude && columnKey) {
      const showLines = canViewCosts && !hideEstimateLines && !isAddsDeductsKey(columnKey);
      if (columnInput) {
        // find any report comments that may be associated with this item
        const commentLineID = getUserReportCommentLineID(categories, categorizedItemCost.id);
        const reportComment = varianceReportComments?.itemComments?.find(
          (c) => c.commentLineID === commentLineID
        );
        // get all item line comments that appear under this item
        const itemLineComments = varianceReportComments?.itemLineComments?.filter((c) =>
          itemContributionLines?.some((line) => {
            if (line) {
              const commentLineID = getUserReportCommentLineID(line.categories);
              return c.commentLineID.startsWith(commentLineID);
            }
            return false;
          })
        );

        tree.insert(
          {
            ...categorizedItemCost,
            parentId: categorizedItemCost.parentId ?? undefined,
            status: categorizedItemCost.status ?? undefined,
            columnKey,
            directCostRange: range,
            range,
          },
          columnInput,
          showLines,
          reportComment,
          itemLineComments
        );
      }
    }
  });
};

export const hasMarkupCategory = (categories: Pick<Category, 'number'>[]) =>
  categories.some((category) => category.number === SEPARATED_COST);

export type CalcSubtotalsData = {
  acceptedSubtotalData: NodeData;
  markupsSubtotalData: NodeData;
  categorizedSubtotalsTree: CostReportCategoriesTree;
  columnKeyQuantityMap: Map<string, Numeric>;
  directCostSubtotalData: NodeData;
  markupSubtotals: ColumnMarkupCost[];
  indirectSummaryData: NodeData;
  markupSummaryData: NodeData;
  contingencySummaryData?: NodeData;
  allowanceSummaryData?: NodeData;
  ownerCostsSubtotalData: NodeData;
  ownerCostsSummaryData: NodeData;
  ownerContingenciesSummaryData: NodeData;
  ownerAllowancesSummaryData: NodeData;
  runningTotalData: NodeData;
  includesIncorporatedMarkups: boolean;
};

type ReportColumn = (
  | MilestoneCostReports[number]['costReportColumns'][number]
  | VarianceReport['varianceColumns'][number]
) & {
  milestoneID?: string;
  date?: string;
};

type ReportColumnSubtotals = NonNullable<ReportColumn['report']>['subtotals'];

export const calcSubtotals = (
  t: TermStore,
  costMode: CostMode,
  costReports: MilestoneCostReports | VarianceReports,
  viewFilter: ViewFilterInput,
  isVariance = false,
  hideZeroVariance = false,
  isTotalIncluded = true,
  hideEstimateLines: boolean,
  itemStatuses?: string[],
  varianceReportComments?: VarianceReportComments,
  hasOwnerCostFeature = false
): CalcSubtotalsData => {
  const milestoneCostReport = costReports && costReports.length && costReports[0];

  const varianceReportColumns: ReportColumn[] = [];
  if (isVariance) {
    (costReports as VarianceReports).forEach((varianceReport) => {
      const { milestoneID, date: globalDate } = varianceReport;
      const date = globalDate ? getDateString(new Date(globalDate)) : undefined;
      varianceReport.varianceColumns.forEach((column, i) => {
        if (isTotalIncluded || i > 0)
          varianceReportColumns.push({ ...column, milestoneID: milestoneID ?? undefined, date });
      });
    });
  }

  // SUBTOTAL DATA
  // set up the containers for our subtotals
  const acceptedSubtotalData: NodeData = {
    // check
    columns: [],
    name: 'Total Accepted Changes',
  };
  const directCostSubtotalData: NodeData = {
    columns: [],
    name: `Subtotal of ${t.titleCase(TermKey.DIRECT_COST)}`,
  };
  // indirect is a subtotal of all markups, contingencies, and allowances
  // and is visible when a user can see subtotals but not markup details
  const indirectSummaryData: NodeData = {
    columns: [],
    name: t.titleCase(TermKey.MARKUP),
  };
  const markupSummaryData: NodeData = {
    columns: [],
    name: t.titleCase(TermKey.MARKUP),
  };
  const contingencySummaryData: NodeData = {
    columns: [],
    name: 'Contingencies',
  };
  const allowanceSummaryData: NodeData = {
    columns: [],
    name: 'Allowances',
  };
  const ownerCostsSummaryData: NodeData = {
    columns: [],
    name: 'Owner Costs',
    nodeNumber: 1,
  };
  const ownerContingenciesSummaryData: NodeData = {
    columns: [],
    name: 'Owner Contingencies',
    nodeNumber: 2,
  };
  const ownerAllowancesSummaryData: NodeData = {
    columns: [],
    name: 'Owner Allowances',
    nodeNumber: 3,
  };
  const markupsSubtotalData: NodeData = {
    columns: [],
    name: hasOwnerCostFeature
      ? t.titleCase(TermKey.COST_OF_CONSTRUCTION)
      : `Total Including ${t.titleCase(TermKey.MARKUP)}`,
  };
  const ownerCostsSubtotalData: NodeData = {
    columns: [],
    name: hasOwnerCostFeature ? t.titleCase(TermKey.PROJECT_TOTAL) : `Total Including Owner Costs`,
  };
  const runningTotalData: NodeData = {
    columns: [],
    name:
      hasOwnerCostFeature && costMode.includeOwnerCosts
        ? t.titleCase(TermKey.PROJECT_RUNNING_TOTAL)
        : t.titleCase(TermKey.RUNNING_TOTAL),
  };

  // track if any of the markups are incorporated
  let includesIncorporatedMarkups = false;

  const columnKeyQuantityMap = new Map<string, Numeric>();

  const categorizedSubtotalsTree = new CostReportCategoriesTree();
  const markupSubtotals: ColumnMarkupCost[] = [];
  // start parsing the report data
  if (milestoneCostReport && costReports.length > 0) {
    // Now, our subtotal rows get simpler costs for display
    const runningTotal = getCostReportByType(
      milestoneCostReport,
      CostReportColumnType.RUNNINGTOTAL_REPORT
    );

    if (runningTotal) {
      runningTotalData.columns.push({
        type: CostReportColumnType.RUNNINGTOTAL_REPORT,
        columnKey: CostReportColumnKey.RUNNINGTOTAL_KEY,
        subtotal: {
          directCostRange: runningTotal.directCostRange,
          directCostSegmented: runningTotal.directCostSegmented,
          range: runningTotal.projectTotalRange ?? runningTotal.range,
          segmented: runningTotal?.projectTotalSegmented ?? runningTotal.segmented,
          numeric: runningTotal.numeric,
        },
      });
    }
    const target = getCostReportByType(milestoneCostReport, CostReportColumnType.TARGET_REPORT);
    if (target) {
      runningTotalData.columns.push({
        type: CostReportColumnType.TARGET_REPORT,
        columnKey: CostReportColumnKey.TARGET_KEY,
        subtotal: {
          directCostRange: target.directCostRange,
          directCostSegmented: target.directCostSegmented,
          range: target.projectTotalRange ?? target.range,
          segmented: target.projectTotalSegmented ?? target.segmented,
          numeric: target.numeric,
        },
      });
    }
    const remaining = getCostReportByType(
      milestoneCostReport,
      CostReportColumnType.REMAINING_REPORT
    );
    if (remaining) {
      runningTotalData.columns.push({
        type: CostReportColumnType.REMAINING_REPORT,
        columnKey: CostReportColumnKey.REMAINING_KEY,
        subtotal: {
          directCostRange: remaining.directCostRange,
          directCostSegmented: remaining.directCostSegmented,
          range: remaining.projectTotalRange ?? remaining.range,
          segmented: remaining.projectTotalSegmented ?? remaining.segmented,
          numeric: remaining.numeric,
        },
      });
    }
    const accepted = getCostReportByType(milestoneCostReport, CostReportColumnType.ACCEPTED_REPORT);
    if (accepted) {
      acceptedSubtotalData.columns.push({
        type: CostReportColumnType.ACCEPTED_REPORT,
        columnKey: CostReportColumnKey.ACCEPTED_KEY,
        subtotal: {
          directCostRange: accepted.directCostRange,
          directCostSegmented: accepted.directCostSegmented,
          range: accepted.projectTotalRange ?? { value: 0 },
          segmented: accepted.projectTotalSegmented ?? { adds: 0, deducts: 0 },
          numeric: accepted.numeric,
        },
      });
    }
    // get cost values for each subtotal
    const isVarianceReport = isVariance || !('costReportColumns' in milestoneCostReport);
    const itemColumnCosts: ItemColumnCost[] = [];
    const reportColumns: ReportColumn[] = isVarianceReport
      ? varianceReportColumns
      : milestoneCostReport.costReportColumns;

    reportColumns.forEach((reportColumn) => {
      const { columnKey, quantity, report, milestoneID, isCategorized } = reportColumn;
      if (isTotalMetricKey(columnKey)) {
        const key = getColumnKey(
          quantity?.unit?.id,
          columnKey,
          quantity?.unit?.abbreviationSingular,
          true,
          !!isCategorized
        );

        if (key && report?.numeric) {
          columnKeyQuantityMap.set(getMilestoneColumnKey(key, milestoneID), report?.numeric);
        }
      }
    });
    const nonZeroSubs = getNonZeroVarianceSubtotals(reportColumns);
    // do they come back sorted? They could...
    reportColumns.forEach((column) => {
      const {
        report,
        type,
        quantity,
        milestoneID,
        date,
        columnKey: rawColumnKey,
        isCategorized,
      } = column;
      const unit = quantity && quantity.unit;
      const unitID = unit && unit.id;
      const columnInput = {
        type,
        unitID: unitID ?? undefined,
        milestoneID,
        date,
        isCategorized: !!isCategorized,
      };

      const categorizedUnitText = getUnitText(
        unit?.abbreviationSingular || '',
        isMetricKey(rawColumnKey),
        !!isCategorized
      );
      const columnKey = getColumnKey(
        unitID ?? undefined,
        rawColumnKey,
        categorizedUnitText,
        isMetricKey(rawColumnKey),
        !!isCategorized
      );
      if (report && columnKey) {
        const { categorizedItemCosts, markupContributions, subtotals } = report;

        // extract markup subtotal from subtotals...
        const categorySubtotals = subtotals.filter((subtotal) => {
          // filter out zero variance rows here
          if (hideZeroVariance) {
            const { categories } = subtotal;
            const match = nonZeroSubs.some((sub) => {
              const { categories: nonZeroCategories } = sub;
              return categoriesMatch(nonZeroCategories, categories);
            });
            if (!match) return false;
          }
          if (hasMarkupCategory(subtotal.categories)) {
            // This makes it so we don't show SEPERATED COST in our Subtotals
            return false;
          }
          return true;
        });

        insertTreeSubtotals(
          categorizedSubtotalsTree,
          columnInput,
          columnKey,
          categorySubtotals,
          hasActiveFilter(viewFilter),
          isVarianceReport,
          varianceReportComments
        );
        categorizedItemCosts.forEach((categorizedItemCost) => {
          if (!hasMarkupCategory(categorizedItemCost.categories))
            itemColumnCosts.push({ columnInput, categorizedItemCost, columnKey });
        });
        if (markupContributions) {
          markupContributions.forEach((markupContribution) => {
            markupSubtotals.push({ columnInput, columnKey, markupContribution });
            if (markupContribution.isIncorporated) includesIncorporatedMarkups = true;
          });
        }

        directCostSubtotalData.columns.push({
          ...columnInput,
          columnKey,
          subtotal: {
            directCostRange: report.directCostRange,
            directCostSegmented: report.directCostSegmented,
            range: report.range,
            segmented: report.segmented,
            numeric: report.numeric,
          },
        });
        markupsSubtotalData.columns.push({
          ...columnInput,
          columnKey,
          subtotal: {
            directCostRange: report.directCostRange,
            directCostSegmented: report.directCostSegmented,
            range: report.range,
            segmented: report.segmented,
            numeric: report.numeric,
          },
        });
        ownerCostsSubtotalData.columns.push({
          ...columnInput,
          columnKey,
          subtotal: {
            directCostRange: report.directCostRange,
            directCostSegmented: report.segmented,
            range: report.projectTotalRange ?? { value: 0 },
            segmented: report.projectTotalSegmented ?? { adds: 0, deducts: 0 },
            numeric: 0,
          },
        });
        const indirectRange = getMarkupTotal(markupContributions, [
          MarkupDisplayType.MARKUP,
          MarkupDisplayType.DRAW,
          MarkupDisplayType.ALLOWANCE,
          MarkupDisplayType.CONTINGENCY,
        ]);
        const indirectSegmentedTotal = getSegmentedMarkupTotals(markupContributions, [
          MarkupDisplayType.MARKUP,
          MarkupDisplayType.DRAW,
          MarkupDisplayType.ALLOWANCE,
          MarkupDisplayType.CONTINGENCY,
        ]);
        if (indirectRange)
          indirectSummaryData.columns.push({
            ...columnInput,
            columnKey,
            subtotal: {
              directCostRange: indirectRange,
              directCostSegmented: indirectSegmentedTotal,
              range: indirectRange,
              segmented: indirectSegmentedTotal,
              numeric: report.numeric,
            },
          });
        const markupRange = getMarkupTotal(markupContributions, [
          MarkupDisplayType.MARKUP,
          MarkupDisplayType.DRAW,
        ]);
        const markupSegmentedTotal = getSegmentedMarkupTotals(markupContributions);
        if (markupRange)
          markupSummaryData.columns.push({
            ...columnInput,
            columnKey,
            subtotal: {
              directCostRange: markupRange,
              directCostSegmented: markupSegmentedTotal,
              range: markupRange,
              segmented: markupSegmentedTotal,
              numeric: report.numeric,
            },
          });
        const contingencyRange = getMarkupTotal(markupContributions, [
          MarkupDisplayType.CONTINGENCY,
        ]);
        const contingencySegmentedTotal = getSegmentedMarkupTotals(markupContributions, [
          MarkupDisplayType.CONTINGENCY,
        ]);
        if (contingencyRange)
          contingencySummaryData.columns.push({
            ...columnInput,
            columnKey,
            subtotal: {
              directCostRange: contingencyRange,
              directCostSegmented: contingencySegmentedTotal,
              range: contingencyRange,
              segmented: contingencySegmentedTotal,
              numeric: report.numeric,
            },
          });
        const allowanceRange = getMarkupTotal(markupContributions, [MarkupDisplayType.ALLOWANCE]);
        const allowanceSegmentedTotal = getSegmentedMarkupTotals(markupContributions, [
          MarkupDisplayType.ALLOWANCE,
        ]);
        if (allowanceRange)
          allowanceSummaryData.columns.push({
            ...columnInput,
            columnKey,
            subtotal: {
              directCostRange: allowanceRange,
              directCostSegmented: allowanceSegmentedTotal,
              range: allowanceRange,
              segmented: allowanceSegmentedTotal,
              numeric: report.numeric,
            },
          });
        const ownerCostRange = getMarkupTotal(
          markupContributions,
          [MarkupDisplayType.MARKUP],
          true
        );
        const ownerCostSegmentedTotal = getSegmentedOwnerTotals(
          markupContributions,
          MarkupDisplayType.MARKUP
        );
        ownerCostsSummaryData.columns.push({
          ...columnInput,
          columnKey,
          subtotal: {
            directCostRange: ownerCostRange,
            directCostSegmented: ownerCostSegmentedTotal,
            range: ownerCostRange,
            segmented: ownerCostSegmentedTotal,
            numeric: report.numeric,
          },
        });
        const ownerContingencyRange = getMarkupTotal(
          markupContributions,
          [MarkupDisplayType.CONTINGENCY],
          true
        );
        const ownerContingencySegmentedTotal = getSegmentedOwnerTotals(
          markupContributions,
          MarkupDisplayType.CONTINGENCY
        );
        ownerContingenciesSummaryData.columns.push({
          ...columnInput,
          columnKey,
          subtotal: {
            directCostRange: ownerContingencyRange,
            directCostSegmented: ownerContingencySegmentedTotal,
            range: ownerContingencyRange,
            segmented: ownerContingencySegmentedTotal,
            numeric: report.numeric,
          },
        });
        const ownerAllowanceRange = getMarkupTotal(
          markupContributions,
          [MarkupDisplayType.ALLOWANCE],
          true
        );
        const ownerAllowanceSegmentedTotal = getSegmentedOwnerTotals(
          markupContributions,
          MarkupDisplayType.ALLOWANCE
        );
        ownerAllowancesSummaryData.columns.push({
          ...columnInput,
          columnKey,
          subtotal: {
            directCostRange: ownerAllowanceRange,
            directCostSegmented: ownerAllowanceSegmentedTotal,
            range: ownerAllowanceRange,
            segmented: ownerAllowanceSegmentedTotal,
            numeric: report.numeric,
          },
        });
      }
    });

    const filteredItemColumnCosts = hideZeroVariance
      ? getNonZeroVarianceItemColumnCosts(itemColumnCosts)
      : itemColumnCosts;

    insertTreeItemCosts(
      categorizedSubtotalsTree,
      filteredItemColumnCosts,
      hideEstimateLines,
      itemStatuses,
      viewFilter,
      varianceReportComments
    );
  }
  addCounts(categorizedSubtotalsTree.root);

  return {
    acceptedSubtotalData,
    markupsSubtotalData,
    ownerCostsSubtotalData,
    categorizedSubtotalsTree,
    columnKeyQuantityMap,
    directCostSubtotalData,
    markupSubtotals,
    indirectSummaryData,
    markupSummaryData,
    contingencySummaryData,
    allowanceSummaryData,
    ownerCostsSummaryData,
    ownerContingenciesSummaryData,
    ownerAllowancesSummaryData,
    runningTotalData,
    includesIncorporatedMarkups,
  };
};

// This helper inserts contributions for items and options
// in the order they appear in the input arrays (to allow for sort ordering)
// NOTE: we no longer use it for MSR, but it's necessary for items list
export const matchContributionsToItemsLike = (
  itemColumnCosts: ItemColumnCost[],
  items: Item[],
  options: Option[]
): ItemColumnCost[] => {
  const typeItemCosts: ItemColumnCost[] = [];
  // Maintains item sort order for the related content
  [...items, ...options].forEach((itemLike) => {
    const matchedContributions = itemColumnCosts.filter(
      (c: ItemColumnCost) => c.categorizedItemCost.id === itemLike.id
    );
    matchedContributions.forEach((c: ItemColumnCost) => {
      typeItemCosts.push({
        ...c,
        itemLike,
      });
    });
  });
  return typeItemCosts;
};

const checkNode = (data: NodeData) => {
  if (data && data.status && data.name) {
    return data;
  }
  return null;
};

export const currentNode = (nodes: CostReportCategoriesTreeNode[], allNodes: NodeData[]) => {
  if (nodes) {
    nodes.forEach((n: CostReportCategoriesTreeNode) => {
      if (n.data) {
        const data = checkNode(n.data);
        if (data) {
          allNodes.push(data);
        }
      }
      if (n && n.branches) {
        const sortedBranches = sortItemsBy[SORT_ITEM_NUMBERS](n.branches);
        currentNode(sortedBranches, allNodes);
      }
      return allNodes;
    });
  }
  return allNodes;
};

// This has the shared knowledge of which rows we will display, as <Row> works.
// TODO: Flatten render of rows, and use this instead of nested components
export const getDisplayedNodes = (
  nodes: CostReportCategoriesTreeNode[],
  settings: CollapseSettings
) => {
  const nodeIds: number[] = [];
  const { collapse, expand } = settings;
  const evaluateNode = (level: number) => (node: CostReportCategoriesTreeNode) => {
    const { nodeNumber } = node.data;
    if (nodeNumber) nodeIds.push(nodeNumber);
    const nodeId = `${level} ${nodeNumber}`; // The id is very simple now, for one tree
    const collapsed = getCollapse(nodeId, collapse, expand);
    if (!collapsed) {
      const nextBranches = getNextBranches(node);
      const sortedBranches = (nextBranches && sortItemsBy[SORT_ITEM_NUMBERS](nextBranches)) || [];
      sortedBranches.map(evaluateNode(level + 1));
    }
  };
  if (nodes && nodes.length) {
    nodes.map(evaluateNode(0));
  }
  return nodeIds;
};

export const getIsUnitColumn = (unit: string) => {
  const currencySymbol = getCurrencySymbol() || '';
  return unit !== TOTAL && !unit.includes(currencySymbol);
};

const settingExists = (setting: string[]) => setting && setting.length;

const getCategorizedUnitSetting = (
  unitSetting: string,
  categorizedMetrics: string[],
  units: Unit[]
) => {
  if (unitSetting === TOTAL) return { newText: TOTAL, isMetric: false };
  const isMetric = getIsUnitColumn(unitSetting);
  const unitAbbreviation = getBaseUnitText(unitSetting);
  const categorized: string[] = [];
  categorizedMetrics.forEach((categorizedMetric) => {
    const found = units.find(
      (unit) => unit.id === categorizedMetric && unit.abbreviationSingular === unitAbbreviation
    );
    if (found) categorized.push(found.abbreviationSingular);
  });
  const isCategorized = !!categorized.length;
  const newText = unitSetting.replace(
    unitAbbreviation,
    `${getCategorizedText(isCategorized)}${unitAbbreviation}`
  );
  return { newText, isMetric };
};

export const DEPRECATED_DEFAULTS: DeprecatedReportSettings = {
  categorizedMetrics: [],
  units: [TOTAL],
};
const getNonDeprecatedSettings = (newSettings: CostReportSettings | DeprecatedReportSettings) => {
  const filteredSettings = newSettings;
  Object.keys(DEPRECATED_DEFAULTS).forEach((key: string) => {
    delete (filteredSettings as Record<string, string | string[]>)[key];
  });
  return filteredSettings;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const getNewVarianceUnitSettings = (settings: any, units: Unit[]) => {
  const { categorizedMetrics, units: unitSetting, varianceColumns } = settings;
  if (!settingExists(unitSetting) || settingExists(varianceColumns)) return { varianceColumns };
  const mappedVarianceColumns: string[] = [];
  unitSetting.forEach((unitSetting: string) => {
    const { newText } = getCategorizedUnitSetting(unitSetting, categorizedMetrics, units);
    mappedVarianceColumns.push(newText);
  });
  return { varianceColumns: mappedVarianceColumns };
};

export const transformVarianceSettings =
  (units: Unit[]) => (settings: TransformVarianceReportSettings) => {
    const { varianceColumns } = getNewVarianceUnitSettings(settings, units);
    const newSettings = { ...settings, varianceColumns };
    return getNonDeprecatedSettings(newSettings) as VarianceSettings;
  };

const getNewMSRUnitSettings = (settings: TransformMilestoneCostReportSettings, units: Unit[]) => {
  const { metrics, subcolumns } = settings as MilestoneCostReportSettings;
  const { categorizedMetrics, units: unitSetting } = settings as DeprecatedReportSettings;
  if (!settingExists(unitSetting) || settingExists(metrics) || settingExists(subcolumns))
    return { metrics, subcolumns };
  const mappedMetrics: string[] = [];
  const mappedSubcolumns: string[] = [];
  unitSetting.forEach((unitSetting: string) => {
    const { newText, isMetric } = getCategorizedUnitSetting(unitSetting, categorizedMetrics, units);
    if (isMetric) {
      mappedMetrics.push(newText);
    } else {
      mappedSubcolumns.push(newText);
    }
  });
  return { metrics: mappedMetrics, subcolumns: mappedSubcolumns };
};

export const transformMSRSettings =
  (units: Unit[]) => (settings: TransformMilestoneCostReportSettings) => {
    const { metrics, subcolumns } = getNewMSRUnitSettings(settings, units);
    const newSettings = { ...settings, metrics, subcolumns };
    return getNonDeprecatedSettings(newSettings) as MilestoneCostReportSettings;
  };

export const hasUserReportComments = (comments?: VarianceReportComments) =>
  !!comments?.itemComments?.length ||
  !!comments?.itemLineComments?.length ||
  !!comments?.markupComments?.length ||
  !!comments?.subtotalComments?.length;
