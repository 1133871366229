import { PermissionResource } from '../../../generated/graphql';
import { PartialCategory, UsePermissions } from '../../../utilities/permissions/types';

export type ItemDescriptors = { number?: string; name?: string };

export const styleItemNumber = (number?: string | null) => (number ? `#${number}` : '');

export const generateTitle = (item: ItemDescriptors) => {
  const { name, number } = item;
  if (!number) return name || '';
  return `${styleItemNumber(number)}: ${name}`;
};

export const getItemDescriptor = (item: ItemDescriptors | null) =>
  styleItemNumber(item?.number) || item?.name || '';

export const isGroupedByCategory = (groupBy: string[] | undefined) => {
  const val = groupBy?.[0];
  const isNotCategoryGrouping =
    val &&
    (val === 'Status' ||
      val === 'Assignee' ||
      val === 'Creator' ||
      val === 'Schedule impact' ||
      val === 'Share settings');
  return val ? !isNotCategoryGrouping : false;
};

export const canBulkEditFunc = (
  canEdit: UsePermissions['canEdit'],
  args: { trades: PartialCategory[] }
) =>
  canEdit(PermissionResource.ITEM_STATUS, args) ||
  canEdit(PermissionResource.ITEM_ASSIGNEES, args) ||
  canEdit(PermissionResource.ITEM_MILESTONE_AND_MEETINGS, args) ||
  canEdit(PermissionResource.ITEM_CATEGORIES, args);
