// APP FRAME
export const ROUTE_VIEW = 'route_view';

// ASSETS
// upload
export const DRAG_DROP_ASSET_ITEM_DETAILS = 'DRAG_DROP_ASSET_ITEM_DETAILS';
export const DRAG_DROP_ASSET_ITEM_DIALOG = 'DRAG_DROP_ASSET_ITEM_DIALOG';
export const UPLOAD_ATTACH_ITEM_ASSET = 'UPLOAD_ATTACH_ITEM_ASSET';
export const LINK_REMOTE_ITEM_ASSET = 'LINK_REMOTE_ITEM_ASSET';
export const DRAG_DROP_ASSET_MILESTONE_DETAILS = 'DRAG_DROP_ASSET_MILESTONE_DETAILS';
export const DRAG_DROP_ASSET_MILESTONE_DIALOG = 'DRAG_DROP_ASSET_MILESTONE_DIALOG';
export const UPLOAD_ATTACH_MILESTONE_ASSET = 'UPLOAD_ATTACH_MILESTONE_ASSET';
export const LINK_REMOTE_MILESTONE_ASSET = 'LINK_REMOTE_MILESTONE_ASSET';
export const COPY_ITEM_UPLOAD_ASSET = 'COPY_ITEM_UPLOAD_ASSET';

export const INIT_UPLOAD = 'INIT_UPLOAD';
export const UPLOAD_STARTED = 'UPLOAD_STARTED';
export const UPLOAD_COMPLETED = 'UPLOAD_COMPLETED';

// detach
export const DETACH_ITEM_ASSET = 'DETACH_ITEM_ASSET';
export const DETACH_MILESTONE_ASSET = 'DETACH_MILESTONE_ASSET';

// download
export const DOWNLOAD_ITEM_ASSET = 'DOWNLOAD_ITEM_ASSET';
export const DOWNLOAD_MILESTONE_ASSET = 'DOWNLOAD_MILESTONE_ASSET';
export const COPY_ITEM_DOWNLOAD_ASSET = 'COPY_ITEM_DOWNLOAD_ASSET';

// viewer
export const ITEM_ASSET_VIEWER_OPEN = 'ITEM_ASSET_VIEWER_OPEN';
export const ITEM_ASSET_VIEWER_CLOSE = 'ITEM_ASSET_VIEWER_CLOSE';
export const MILESTONE_ASSET_VIEWER_OPEN = 'MILESTONE_ASSET_VIEWER_OPEN';
export const MILESTONE_ASSET_VIEWER_CLOSE = 'MILESTONE_ASSET_VIEWER_CLOSE';
export const OPEN_ASSET_NEW_TAB = 'OPEN_ASSET_NEW_TAB';
export const ASSETS_PDF_PAGE_UP = 'ASSETS_PDF_PAGE_UP';
export const ASSETS_PDF_PAGE_DOWN = 'ASSETS_PDF_PAGE_DOWN';
export const ASSETS_PDF_TYPE_PAGE_NUMBER = 'ASSETS_PDF_TYPE_PAGE_NUMBER';
export const ASSETS_PDF_FIT_HEIGHT = 'ASSETS_PDF_FIT_HEIGHT';
export const ASSETS_PDF_FIT_WIDTH = 'ASSETS_PDF_FIT_WIDTH';
export const ASSETS_PDF_ON_SCROLL_FINISH = 'ASSETS_PDF_FIT_WIDTH';

// ESTIMATES
export const ADD_CATEGORY = 'ADD_CATEGORY'; // used for both item and milestone
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY'; // used for both item and milestone
export const ADD_CATEGORY_FROM_ERROR_CTA = 'ADD_CATEGORY_FROM_ERROR_CTA'; // TO TS
export const ADD_ALL_NEW_CATEGORIES_FROM_ERROR_CTA = 'ADD_ALL_NEW_CATEGORIES_FROM_ERROR_CTA'; // TO TS
export const REPLACE_CATEGORY_FROM_ERROR_CTA = 'REPLACE_CATEGORY_FROM_ERROR_CTA'; // TO TS
export const HEADER_ERROR_SELECTED_CTA = 'HEADER_ERROR_SELECTED_CTA'; // TO TS
export const CELL_ERROR_SELECTED_CTA = 'CELL_ERROR_SELECTED_CTA'; // TO TS

// CATEGORY
export const ADD_CATEGORY_ANALYTICS = 'ADD_CATEGORY_ANALYTICS'; // used for both item and milestone
export const REMOVE_CATEGORY_ANALYTICS = 'REMOVE_CATEGORY_ANALYTICS'; // used for both item and milestone
export const ADD_COLLABORATOR_TRADES = 'ADD_COLLABORATOR_TRADES'; // used for both item and milestone
export const REMOVE_COLLABORATOR_TRADES = 'REMOVE_COLLABORATOR_TRADES'; // used for both item and milestone
export const CREATE_CATEGORY = 'projectSettings_addCategory';

// IMPORT ESTIMATE
export const GO_TO_COLUMN_CTA = 'GO_TO_COLUMN_CTA';
export const DELETE_ESTIMATE_CTA = 'DELETE_ESTIMATE_CTA';
export const EDIT_ESTIMATE_CTA = 'EDIT_ESTIMATE_CTA';
export const PREVIEW_ESTIMATE_CTA = 'PREVIEW_ESTIMATE_CTA';
export const PUBLISH_ESTIMATE_CTA = 'PUBLISH_ESTIMATE_CTA';
export const FINALIZE_IMPORT_ESTIMATE = 'FINALIZE_IMPORT_ESTIMATE';
export const ESTIMATE_IMPORT_UPLOADED_CTA = 'ESTIMATE_IMPORT_UPLOADED_CTA';
export const IMPORT_ESTIMATE_DOWNLOAD_CTA = 'IMPORT_ESTIMATE_DOWNLOAD_CTA';
export const IMPORT_ESTIMATE_HELP_CTA = 'IMPORT_ESTIMATE_HELP_CTA';
export const ONBOARDING_STEP1_CTA = 'ONBOARDING_STEP1_CTA';
export const ONBOARDING_STEP2_CTA = 'ONBOARDING_STEP2_CTA';
export const ONBOARDING_STEP3_CTA = 'ONBOARDING_STEP3_CTA';
export const ONBOARDING_STEP4_CTA = 'ONBOARDING_STEP4_CTA';
export const ONBOARDING_STEP2_INCLUDE_ALL = 'ONBOARDING_STEP2_INCLUDE_ALL';
export const ONBOARDING_STEP2_EXCLUDE_ALL = 'ONBOARDING_STEP2_EXCLUDE_ALL';
export const IMPORT_ESTIMATE_NAVIGATION_LINK = 'IMPORT_ESTIMATE_NAVIGATION_LINK';
export const IMPORT_ESTIMATE_SELECT_FILE_CTA = 'IMPORT_ESTIMATE_SELECT_FILE_CTA';
export const IMPORT_ESTIMATE_SELECT_FILE_INVALID = 'IMPORT_ESTIMATE_SELECT_FILE_INVALID';
export const IMPORT_ESTIMATE_MANUAL_CTA = 'IMPORT_ESTIMATE_MANUAL_CTA';
export const ONBOARDING_STEP1_SELECT_WBS = 'ONBOARDING_STEP1_SELECT_WBS';
export const ONBOARDING_STEP3_SELECT_CATEGORY = 'ONBOARDING_STEP3_SELECT_CATEGORY';
export const ONBOARDING_STEP4_REORDER_CATEGORY = 'ONBOARDING_STEP4_REORDER_CATEGORY';
export const ONBOARDING_STEP2_INCLUDE = 'ONBOARDING_STEP2_INCLUDE';
export const ONBOARDING_STEP2_EXCLUDE = 'ONBOARDING_STEP2_EXCLUDE';

// PROJECT
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPLOAD_PROJECT_THUMBNAIL = 'projectSettings_uploadProjectThumbnail';
export const UPDATE_EMAIL_NOTIFICATION_SETTING = 'UPDATE_EMAIL_NOTIFICATION_SETTING';
export const UPDATE_IN_APP_NOTIFICATION_SETTING = 'UPDATE_IN_APP_NOTIFICATION_SETTING';

export const UPDATE_PROJECT_STATUS = 'project_updateStatus';
export const UPDATE_PROJECT_DELIVERY_TYPE = 'project_updateDeliveryType';
export const UPDATE_PROJECT_NAME = 'project_updateName';
export const UPDATE_PROJECT_DESCRIPTION = 'project_updateDescription';
export const UPDATE_PROJECT_SHORT_NAME = 'project_updateShortName';

// DASHBOARD
export const SET_COST_TREND = 'SET_COST_TREND';
export const SET_ITEM_CHART_VIEW_BY = 'SET_ITEM_CHART_VIEW_BY'; // TO TS
export const SET_ESTIMATE_CHART_VIEW_BY = 'SET_ESTIMATE_CHART_VIEW_BY'; // TO TS
export const REPORT_CHART = 'REPORT_CHART';
export const CATEGORY_FILTER_APPLIED = 'CATEGORY_FILTER_APPLIED';
export const PROJECT_DASHBOARD_RUN_ESTIMATE_CHART = 'projectDashboard_RunEstimateChart';
export const PROJECT_DASHBOARD_RUN_CATEGORY_TRENDS = 'projectDashboard_RunCategory Trends';
export const PROJECT_DASHBOARD_PRINT = 'projectDashboard_Print';
export const PROJECT_DASHBOARD_PRINT_WITH_COST_SUMMARY = 'projectDashboard_PrintWithCostSummary';
export const PRINT_CONTINGENCY_REPORT = 'print_contingencyReport';

// COLLABORATORS
export const UPDATE_COLLABORATOR_ALL_TRADES = 'UPDATE_COLLABORATOR_ALL_TRADES'; // TO TS
export const UPDATE_COLLABORATOR_ROLE = 'UPDATE_COLLABORATOR_ROLE'; // TO TS
export const UPDATE_COLLABORATOR_RESPONSIBILITY = 'UPDATE_COLLABORATOR_RESPONSIBILITY'; // TO TS
export const DELETE_COLLABORATOR = 'DELETE_COLLABORATOR'; // TO TS
export const CREATE_COLLABORATOR = 'CREATE_COLLABORATOR'; // TO TS

// MILESTONES
export const MILESTONES_CREATE = 'milestones_CreateMilestoneCTA';
export const DELETE_MILESTONE = 'DELETE_MILESTONE';
export const MILESTONES_CREATE_DRAFT = 'milestones_createDraftMilestoneCTA';
export const PUBLISH_DRAFT_MILESTONE = 'milestones_publishDraftMilestoneCTA';

export const SET_ACTIVE_MILESTONE = 'SET_ACTIVE_MILESTONE';

export const CREATE_OR_UPDATE_BUCKET = 'CREATE_OR_UPDATE_BUCKET';
export const DELETE_BUCKET = 'DELETE_BUCKET';

export const EXPORT_MILESTONE_ESTIMATE = 'EXPORT_MILESTONE_ESTIMATE';
export const EXPORT_ALL_MILESTONES_CONTINGENCY_REPORT = 'EXPORT_ALL_MILESTONES_CONTINGENCY_REPORT';
export const EXPORT_PROJECT_COST_BREAKDOWN_REPORT = 'EXPORT_PROJECT_COST_BREAKDOWN_REPORT';
export const EXPORT_ACTIVE_MILESTONE_CONTINGENCY_REPORT =
  'EXPORT_ACTIVE_MILESTONE_CONTINGENCY_REPORT';
export const EXPORT_MILESTONE_SUMMARY_REPORT = 'EXPORT_MILESTONE_SUMMARY_REPORT';

export const UPDATE_MILESTONE_NAME = 'UPDATE_MILESTONE_NAME';
export const UPDATE_MILESTONE_DATE = 'UPDATE_MILESTONE_DATE';
export const UPDATE_MILESTONE_DESIGN_PHASE = 'UPDATE_MILESTONE_DESIGN_PHASE';

// UNITS
export const CREATE_OR_UPDATE_UNIT = 'CREATE_OR_UPDATE_UNIT';
export const TOGGLE_UNIT = 'TOGGLE_UNIT';
export const REMOVE_UNIT = 'REMOVE_UNIT';

// ITEMS
export const CREATE_ITEM = 'CREATE_ITEM';
export const DEPRECATE_ITEM = 'DEPRECATE_ITEM';
export const UPDATE_ITEM_NAME = 'UPDATE_ITEM_NAME';
export const UPDATE_ITEM_DESCRIPTION = 'UPDATE_ITEM_DESCRIPTION';
export const UPDATE_ITEM_VISIBILITY = 'UPDATE_ITEM_VISIBILITY';
export const UPDATE_ITEM_ASSIGNEE = 'UPDATE_ITEM_ASSIGNEE';
export const DIALOG_ITEM_MILESTONE_TRANSITION_CTA = 'DIALOG_ITEM_MILESTONE_TRANSITION_CTA';
export const UPDATE_ITEM_MILESTONE_ANALYTICS = 'UPDATE_ITEM_MILESTONE_ANALYTICS';
export const UPDATE_ITEM_DUE_DATE = 'UPDATE_ITEM_DUE_DATE';
export const UPDATE_ITEM_NUMBER = 'UPDATE_ITEM_NUMBER';
export const ITEMS_STATUS_CHANGE = 'items_StatusChange';
export const ITEMS_STATUS_CHANGE_ACCEPTED = 'items_StatusChangeAccepted'; // we need this event for appcues, because appcues does not show event properties

export const REPORT_ITEM_NAVIGATION = 'REPORT_ITEM_NAVIGATION';

export const COLLAPSE_NODE = 'COLLAPSE_NODE';

export const SET_ITEM_ESTIMATE_EXPANDED = 'SET_ITEM_ESTIMATE_EXPANDED';
export const SET_ITEM_CONTINGENCY_EXPANDED = 'SET_ITEM_CONTINGENCY_EXPANDED';
export const SET_ITEM_SCHEDULE_IMPACT_EXPANDED = 'SET_ITEM_SCHEDULE_IMPACT_EXPANDED';
export const SET_ITEM_ASSETS_EXPANDED = 'SET_ITEM_ASSETS_EXPANDED';
export const SET_MILESTONE_ESTIMATE_EXPANDED = 'SET_MILESTONE_ESTIMATE_EXPANDED';
export const SET_MILESTONE_BUDGET_EXPANDED = 'SET_MILESTONE_BUDGET_EXPANDED';

export const REMOVE_MILESTONE_HISTORY = 'REMOVE_MILESTONE_HISTORY';

export const ADD_COMMENT = 'ADD_COMMENT';

// OPTIONS
export const CREATE_OPTION = 'CREATE_OPTION';
export const OPTIONS_DIALOG_TAB = 'OPTIONS_DIALOG_TAB';
export const SEARCH_OPTIONS_DIALOG = 'SEARCH_OPTIONS_DIALOG';
export const DETACH_OPTION = 'DETACH_OPTION';

// COST
export const SET_COST_MODE = 'SET_COST_MODE';
export const SET_ITEM_ESTIMATE = 'SET_ITEM_ESTIMATE';

// ITEMS LIST PAGE - TOO: FOR ANALYTICS?
export const OPEN_ITEM_NEW_WINDOW = 'ItemDetailsNewWindow_View';
export const SET_ITEMS_LIST_NEW_ITEM_DIALOG_OPEN = 'SET_ITEMS_LIST_NEW_ITEM_DIALOG_OPEN';
export const REPORT_ITEMS_LIST = 'REPORT_ITEMS_LIST';
export const REPORT_PRINT = 'REPORT_PRINT';
export const ITEMS_BULK_EDIT = 'ITEMS_BULK_EDIT';
export const ITEMS_GROUP_BY = 'itemslist_GroupBy';
export const ITEMS_SORT_BY = 'itemslist_SortBy';

// PERMISSIONS
export const SET_EDIT_ROLE = 'SET_EDIT_ROLE';

// CATEGORIES
export const SET_EDITING_CATEGORIZATION = 'EDIT_CATEGORIZATION';

// THESE ARE USED FOR ANALYTICS (Move these out if we decide we like apollo)
export const CREATE_CATEGORIZATION_SCHEME = 'CREATE_CATEGORIZATION_SCHEME';
export const EDIT_CATEGORIZATION_SCHEME_NAME = 'EDIT_CATEGORIZATION_SCHEME_NAME';

export const SELECT_ITEM_COLUMN_CATEGORIZATION = 'SELECT_ITEM_COLUMN_CATEGORIZATION';
export const SELECT_MILESTONE_COLUMN_CATEGORIZATION = 'SELECT_MILESTONE_COLUMN_CATEGORIZATION';
export const OPEN_ITEM_ESTIMATE_COLUMN_MENU = 'OPEN_ITEM_ESTIMATE_COLUMN_MENU';
export const OPEN_MILESTONE_ESTIMATE_COLUMN_MENU = 'OPEN_MILESTONE_ESTIMATE_COLUMN_MENU';

export const MILESTONE_COSTREPORT_COLUMNS = 'MILESTONE_COSTREPORT_COLUMNS';
export const MILESTONE_COSTREPORT_EXPAND_COLLAPSE = 'MILESTONE_COSTREPORT_EXPAND_COLLAPSE';
export const MILESTONE_COSTREPORT_GROUPBY = 'MILESTONE_COSTREPORT_GROUPBY';
export const MILESTONE_COSTREPORT_ITEMS = 'MILESTONE_COSTREPORT_ITEMS';
export const MILESTONE_COSTREPORT_VIEWMODE = 'MILESTONE_COSTREPORT_VIEWMODE';
export const MILESTONE_COSTREPORT_ESTIMATE_LINES = 'MILESTONE_COSTREPORT_ESTIMATE_LINES';

export const EXPORT_ITEMS = 'EXPORT_ITEMS';
export const PRINT_ITEMS_LIST = 'PRINT_ITEMS_LIST';
export const PRINT_ITEM_DETAIL = 'PRINT_ITEM_DETAIL';
export const PRINT_ITEMS_LIST_AND_DETAILS = 'PRINT_ITEMS_LIST_AND_DETAILS';

// SIGN UP
export const SIGN_UP_VERIFY_EMAIL_VIEW = 'signup_verifyEmailView';
export const SIGN_UP_RESEND_EMAIL_CTA = 'signup_resendEmailCTA';
export const SIGN_UP_ABOUT_YOU_VIEW = 'signup_aboutYouView';
export const SIGN_UP_ABOUT_YOU_CTA = 'signup_aboutYouCTA';

export const SIGN_UP_CREATE_COMPANY_VIEW = 'signup_createCompanyView';
export const SIGN_UP_CREATE_COMPANY_CTA = 'signup_createCompanyCTA';

export const USER_PROFILE_EDIT_VIEW = 'userProfile_editView';
export const USER_PROFILE_EDIT_CTA = 'userProfile_editCTA';

// BANNER / PRINT HEADER
export const SETTINGS_PRINT_HEADER_SELECTOR = 'settings_printHeaderSelector';
export const SETTINGS_SELECT_BANNER = 'settings_selectBanner';
export const SETTINGS_REMOVE_BANNER_ACTION = 'settings_removeBanner';
export const SETTINGS_UPLOAD_NEW_HEADER = 'settings_uploadNewHeaderCTA';
export const SETTINGS_ADD_CUSTOM_BANNER_VIEW = 'settings_addCustomBannerView';
export const SETTINGS_ADD_CUSTOM_BANNER_CTA = 'settings_addCustomBannerCTA';
export const SETTINGS_ADD_CUSTOM_BANNER_CANCEL = 'settings_addCustomBannerCancel';
export const COMPANY_LOGO = 'company_logo';
export const CUSTOM_BANNER = 'custom_banner';
export const NO_LOGO = 'no_logo';
export const TEAM_LOGOS = 'team_logos';

// PROJECT SETTINGS
export const SETTINGS_SET_DISABLED_CATEGORIZATION = 'projectSettings_setDisabledCategorization';
export const SETTINGS_UPDATE_PROJECT_TERMINOLOGY = 'projectSettings_updateTerminology';
export const SETTINGS_RESET_PROJECT_TERMINOLOGY = 'projectSettings_resetTerminology';
export const SETTINGS_CURRENCY = 'projectSettings_currency';
export const SETTINGS_ROUNDING = 'projectSettings_rounding';

// METRICS
export const CREATE_QUANTITY = 'milestoneQuantities_createQuantity';
export const ADD_QUANTITY_BREAKDOWN = 'milestoneQuantities_addQuantityBreakdown';
export const REMOVE_QUANTITY_BREAKDOWN = 'milestoneQuantities_removeQuantityBreakdown';
export const AUTO_POPULATE_QUANTITY_ROWS = 'milestoneQuantities_autoPopulateQuantityRows';
export const COSTREPORT_METRIC_COLUMNS = 'costReport_metricColumns'; // which keys selected

// CONTINGENCIES
export const CREATE_CONTINTENCY = 'itemContingencies_createContingencyDraw';
export const UPDATE_CONTINTENCY = 'itemContingencies_updateContingencyDraw';
export const REMOVE_CONTINTENCY = 'itemContingencies_removeContingencyDraw';

// PROJECT COMPS

// ITEM ACTIVITY
export const ITEM_ACTIVITY_ALL_ACTIVITY = 'itemActivity_allActivity';
export const ITEM_ACTIVITY_MY_ACTIVITY = 'itemActivity_myActivity';

// ITEM INTEGRATIONS
export const ITEM_ACTIVITY_CREATE_PROCORE_CHANGE_EVENT = 'itemActivity_createProcoreChangeEvent';
export const ITEM_ACTIVITY_UNLINK_PROCORE_CHANGE_EVENT = 'itemActivity_unlinkProcoreChangeEvent';
export const ITEM_ACTIVITY_PROCORE_CHANGE_EVENT_LINK_CTA = 'itemActivity_procoreChangeEventLinkCTA';
export const ITEM_ACTIVITY_CREATE_AUTODESK_POTENTIAL_CHANGE_ORDER =
  'itemActivity_createAutodeskPotentialChangeOrder';
export const ITEM_ACTIVITY_UNLINK_AUTODESK_POTENTIAL_CHANGE_ORDER =
  'itemActivity_unlinkAutodeskPotentialChangeOrder';
export const ITEM_ACTIVITY_AUTODESK_POTENTIAL_CHANGE_ORDER_LINK_CTA =
  'itemActivity_autodeskPotentialChangeOrderLinkCTA';
